import { useCallback } from "react";
import { Filter, ComplexFilter } from "../../../../common/hooks/useLocalTable";
import useEntityFormViewTable from "../../../../common/hooks/useEntityFormViewTable";
import { createFiltersFromFilterData } from "../../../../../utils/formViews";

const getFilters = (searchString, filterData) => {
    const filters = [];

    if (searchString) {
        filters.push(new ComplexFilter([new Filter("description", Filter.CO, searchString), new Filter("systemId", Filter.CO, searchString)], ComplexFilter.OR));
    }

    if (filterData) {
        const createdFilters = createFiltersFromFilterData(filterData);
        filters.push(...createdFilters);
    }

    return filters;
};



const useSystemsTable = (systems) => {
    const getDialogFilters = useCallback(
        async ({ field, search = "" }) => {
            const lowerSearch = search.toLowerCase();
            let keys;

            switch (field) {
                case "systemId": {
                    keys = systems.filter(({ systemId }) => systemId?.toLowerCase().includes(lowerSearch)).map(({ systemId }) => systemId);
                    break;
                }
                case "landscape": {
                    keys = systems.filter(({ landscape }) => landscape?.toLowerCase().includes(lowerSearch)).map(({ landscape }) => landscape);
                    break;
                }
            }

            const uniqueKeys = [...new Set(keys)];
            const rows = uniqueKeys.map((key) => ({ key, text: key }));
            return { rows, total: rows.length };
        },
        [systems]
    );

    const viewTable = useEntityFormViewTable(systems, getFilters);

    return {
        ...viewTable,
        getDialogFilters,
    };
};

export default useSystemsTable;
