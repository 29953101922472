import React from "react";
import { Typography, Box, List, ListItem as MuiListItem, IconButton, CircularProgress } from "@mui/material";
import PropTypes from 'prop-types';

import { PlusIcon } from "../icons";

import "./styles.less"

const SearchHelpTable = ({
    rows,
    columns,
    getRowId = (row) => row.key,
    onSelect,
    onRowClick,
    onScroll,
    listContainerRef,
    showTableHeader=true,
    selectOneRow=false,
    busy=false,
    disabled=false
}) => {
    const gridColumns = columns.map(column => column.width || "1fr");

    if (!selectOneRow) gridColumns.push("auto");

    const gridTemplateColumns = gridColumns.join(" ");

    const handleSelectClick = (event, row) => {
        event.stopPropagation();
        onSelect(row);
    };

    const busySize = 32;
    const busyPosition = `calc(50% - ${busySize / 2}px)`;

    return (
        <Box className="search-help-table-container">
            {showTableHeader && (
                <ListHeader
                    columns={columns}
                    gridTemplateColumns={gridTemplateColumns}
                />
            )}

            {busy && (
                <CircularProgress
                    sx={{
                        color: "var(--font-4)",
                        position: "absolute",
                        top: busyPosition,
                        left: busyPosition,
                        zIndex: 1
                    }}
                    size={`${busySize}px`}
                    disableShrink
                />
            )}

            <List
                className="search-help-table-list"
                ref={listContainerRef}
                onScroll={onScroll}
            >
                {rows?.map(row => (
                    <ListItem
                        key={getRowId(row)}
                        row={row}
                        columns={columns}
                        onSelect={(event) => handleSelectClick(event, row)}
                        gridTemplateColumns={gridTemplateColumns}
                        showAddButton={!selectOneRow && !disabled}
                        onRowClick={() => onRowClick(row)}
                    />
                ))}
            </List>
        </Box>
    )
}

const ListHeader = ({ columns, gridTemplateColumns }) => {
    return (
        <Box
            className="search-help-table-header"
            sx={{
                gridTemplateColumns: gridTemplateColumns,
            }}
        >
            {columns && columns.map(column => (
                <Typography
                    className="search-help-table-header-text"
                    key={column.id}
                >
                    {column.title}
                </Typography>
            ))}
        </Box>
    );
};

const ListItem = ({row, columns, onSelect, onRowClick, gridTemplateColumns, showAddButton }) => {
    return (
        <MuiListItem
            className="search-help-table-list-item"
            sx={{
                gridTemplateColumns: gridTemplateColumns,
            }}
            onClick={onRowClick}
        >
            {columns.map(column => (
                <Typography
                    key={column.id}
                    className="search-help-table-list-item-text"
                >
                    {row[column.id]}
                </Typography>
            ))}

            {showAddButton && (
                <IconButton
                    className="search-help-table-list-item-add"
                    onClick={onSelect}
                >
                    <PlusIcon />
                </IconButton>
            )}
        </MuiListItem>
    )
};

SearchHelpTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        text: PropTypes.string,
    })),
    columns: PropTypes.arrayOf(PropTypes.object),
    getRowId: PropTypes.func,
    onSelect: PropTypes.func,
    onRowClick: PropTypes.func,
    onScroll: PropTypes.func,
    listContainerRef: PropTypes.any,
    showTableHeader: PropTypes.bool,
    selectOneRow: PropTypes.bool,
    busy: PropTypes.bool,
    disabled: PropTypes.bool,
};

ListHeader.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object),
    gridTemplateColumns: PropTypes.string
};

ListItem.propTypes = {
    row: PropTypes.object,
    columns: PropTypes.arrayOf(PropTypes.object),
    onSelect: PropTypes.func,
    onRowClick: PropTypes.func, 
    gridTemplateColumns: PropTypes.string, 
    showAddButton: PropTypes.bool,
};

export default SearchHelpTable;
