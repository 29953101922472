// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@mui/material";
import { SearchHelpInput, TextInput, SelectInput, DropdownSearchHelpInput } from "../../../common/form-controls";
import { MenuItem } from "../../../common/menu";
import SearchHelpDialog from "../../../common/search-helps";
import { getSystemGroups } from "../../../../utils/searchHelps";
import { reportsService } from "../../../../services/reports-service";
import "./styles.less";
const RoleNameInput = ({ value, setValue, error }) => {
    const intl = useIntl();
    return (React.createElement(TextInput, { label: intl.formatMessage({ id: "create-role-for-request-form.input-role-name" }), value: value, onChange: (event) => setValue(event.target.value), error: (error === null || error === void 0 ? void 0 : error.value) === value, errorMessage: error === null || error === void 0 ? void 0 : error.message }));
};
const SystemGroupInput = ({ value, setValue, error }) => {
    const intl = useIntl();
    const title = useMemo(() => intl.formatMessage({ id: "create-role-for-request-form.input-system" }), [intl]);
    const columns = useMemo(() => [
        { id: "id", title: intl.formatMessage({ id: "search-help.columns.system" }) },
        { id: "description", title: intl.formatMessage({ id: "search-help.columns.description" }) },
    ], [intl]);
    const [open, setOpen] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(SearchHelpInput, { label: title, value: value, error: (error === null || error === void 0 ? void 0 : error.value) === value, errorMessage: error === null || error === void 0 ? void 0 : error.message, onChange: (event) => setValue(event.target.value), onOpenSearchHelp: () => setOpen(true) }),
        React.createElement(SearchHelpDialog, { defaultSearchString: value, title: title, 
            //@ts-expect-error: fix
            columns: columns, open: open, readRows: getSystemGroups, 
            //@ts-expect-error: fix 
            onSubmit: (row) => {
                setValue(row.id);
                setOpen(false);
            }, onCancel: () => setOpen(false), selectOneRow: true })));
};
const RoleDescriptionInput = ({ value, setValue, error }) => {
    const intl = useIntl();
    return (React.createElement(TextInput, { multiline: true, rows: 3, label: intl.formatMessage({ id: "create-role-for-request-form.input-role-description" }), value: value, onChange: (event) => setValue(event.target.value), error: (error === null || error === void 0 ? void 0 : error.value) === value, errorMessage: error === null || error === void 0 ? void 0 : error.message }));
};
const RoleTypeInput = ({ value, setValue }) => {
    const intl = useIntl();
    const types = [
        { isComplex: false, text: React.createElement(FormattedMessage, { id: "create-role-for-request-form.input-role-type.single" }) },
        { isComplex: true, text: React.createElement(FormattedMessage, { id: "create-role-for-request-form.input-role-type.group" }) },
    ];
    return (React.createElement(SelectInput, { label: intl.formatMessage({ id: "create-role-for-request-form.input-role-type.label" }), value: value, onChange: (event) => setValue(event.target.value) }, types.map((item) => (React.createElement(MenuItem, { key: item.isComplex, value: item.isComplex, sx: { minHeight: "initial", height: "36px" } }, item.text)))));
};
//@ts-expect-error: fix
const getEmployees = (params, signal) => {
    return reportsService.getEmployeesForSimulationSearchHelp(params, signal);
};
const RoleOwnerInput = ({ ids, setIds, error, getUsers, disabledMultiple = false }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "create-role-for-request-form.input-role-owner" });
    return (React.createElement(DropdownSearchHelpInput, { ids: ids, setIds: setIds, getData: getUsers, error: Boolean(error), errorMessage: error === null || error === void 0 ? void 0 : error.message, label: label, disableMultiple: disabledMultiple }));
};
const DeputyRoleOwnerInput = ({ ids, setIds, error, getUsers, disabledMultiple = false }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "create-role-for-request-form.input-deputy-role-owner" });
    return (React.createElement(DropdownSearchHelpInput, { ids: ids, setIds: setIds, getData: getUsers, error: Boolean(error), errorMessage: error === null || error === void 0 ? void 0 : error.message, label: label, disableMultiple: disabledMultiple }));
};
const CreateRoleForRequestForm = ({ form, setForm, validationErrors }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { className: "create-role-for-request-form" },
            React.createElement(RoleNameInput, { value: form.id, setValue: (value) => setForm({ id: value }), error: validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.id }),
            React.createElement(RoleDescriptionInput, { value: form.description, setValue: (value) => setForm({ description: value }), error: validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.description }),
            React.createElement(SystemGroupInput, { value: form.groupId, setValue: (value) => setForm({ groupId: value }), error: validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.groupId }),
            React.createElement(RoleTypeInput, { value: form.isComplex, setValue: (value) => setForm({ isComplex: value }) }),
            React.createElement(RoleOwnerInput, { ids: form.roleOwner, setIds: (value) => setForm({ roleOwner: value }), error: validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.roleOwner, disabledMultiple: true, getUsers: getEmployees }),
            React.createElement(DeputyRoleOwnerInput, { ids: form.deputyRoleOwner, setIds: (value) => setForm({ deputyRoleOwner: value }), error: validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.deputyRoleOwner, disabledMultiple: true, getUsers: getEmployees }))));
};
export default CreateRoleForRequestForm;
