var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import RequestDetailedRolesTab from "./request-detailed-roles-tab";
import { fetchRequestDetailed, getRequestStages, moveToApproveStep, moveToRejectStep, saveRequest, saveRequestComment, setError, } from "../../../../reducers/administration/requestSlice";
import RequestDetailedActions from "./request-detailed-actions";
import RequestDetailedHeader from "./request-detailed-header";
import { CommonDialog, CommonDialogContent, CommonDialogTitle, ErrorsDialog, ProgressDialog } from "../../../common/dialogs";
import { TextInput } from "../../../common/form-controls";
import { FormattedMessage, useIntl } from "react-intl";
import { Box } from "@mui/material";
import { Button } from "../../../common/buttons";
import { ScrollableTabs } from "../../../common/tabs";
import RequestDetailedHistoryTab from "./request-detailed-history-tab";
import RequestDetailedCommentsTab from "./request-detailed-comments-tab";
import { getVisibleEntities } from "../../../../utils/formViews";
import useRequestDetailedHistoryTable from "./useRequestDetailedHistoryTable";
import ApproveRequestWithConflictsDialog from "./approve-request-with-conflicts-dialog";
import RequestDetailedConflictsTab from "./request-detailed-conflicts-tab";
import { requestDetailedTabs } from "../../../../utils/brm-utils";
import "./styles.less";
const ActionCommentDialog = ({ open, onClose, comment, setComment, onSubmit, requestId }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "request-detailed.action-comment-dialog.title" }, { requestId: requestId });
    return (React.createElement(CommonDialog, { open: open, onClose: onClose },
        React.createElement(CommonDialogTitle, { title: title, onClose: onClose, withoutCloseIcon: false }),
        React.createElement(CommonDialogContent, null,
            React.createElement(TextInput, { multiline: true, rows: 5, value: comment, onChange: (event) => setComment(event.target.value) }),
            React.createElement(Box, { gap: 2, sx: { marginTop: "15px" }, justifyContent: "flex-end", display: "flex" },
                React.createElement(Button, { disabled: false, variant: "outlined", onClick: onClose, sx: {
                        color: "var(--font-4)",
                        borderColor: "var(--font-4)",
                    } },
                    React.createElement(FormattedMessage, { id: "common.btn-cancel" })),
                React.createElement(Button, { disabled: !(comment.length > 0), variant: "contained", sx: {
                        color: "var(--bg-2)",
                        backgroundColor: "var(--font-2)",
                        "&:hover": {
                            backgroundColor: "var(--font-2)",
                        },
                    }, onClick: onSubmit },
                    React.createElement(FormattedMessage, { id: "common.btn-save" }))))));
};
const RequestDetailedPage = () => {
    var _a, _b;
    const intl = useIntl();
    const navigate = useNavigate();
    const { requestId } = useParams();
    const [actionCommentDialogOpen, setActionCommentDialogOpen] = useState(false);
    const [approveWithConflictsMessage, setApproveWithConflictsMessage] = useState("");
    const [actionComment, setActionComment] = useState("");
    const [newRequestComment, setNewRequestComment] = useState("");
    const dispatch = useDispatch();
    const currentRequest = useSelector((state) => state.administration.requests.currentRequest);
    const visibleHistory = getVisibleEntities((_a = currentRequest === null || currentRequest === void 0 ? void 0 : currentRequest.history) !== null && _a !== void 0 ? _a : []);
    const requestHistoryTable = useRequestDetailedHistoryTable(visibleHistory);
    const error = useSelector((state) => state.administration.requests.error);
    const requestStages = useSelector((state) => state.administration.requests.requestStages);
    const busy = useSelector((state) => state.administration.requests.busy);
    const busyType = useSelector((state) => state.administration.requests.busyType);
    const [rolesTabResetCounter, setRolesTabResetCounter] = useState(0);
    const handleBackClick = () => {
        navigate("..");
    };
    useEffect(() => {
        dispatch(getRequestStages());
        if (requestId && (!currentRequest || currentRequest.requestId != requestId)) {
            dispatch(fetchRequestDetailed(requestId));
        }
    }, [requestId, currentRequest, dispatch]);
    const handleSaveAndContinue = () => __awaiter(void 0, void 0, void 0, function* () {
        const resultAction = yield dispatch(saveRequest());
        if (saveRequest.fulfilled.match(resultAction) && requestId) {
            navigate("testing-preparation");
            dispatch(fetchRequestDetailed(requestId));
        }
    });
    const handleSave = () => __awaiter(void 0, void 0, void 0, function* () {
        const resultAction = yield dispatch(saveRequest());
        if (saveRequest.fulfilled.match(resultAction) && requestId) {
            dispatch(fetchRequestDetailed(requestId));
            setActiveTabId(requestDetailedTabs.ROLES);
            setRolesTabResetCounter((prev) => prev + 1);
        }
    });
    const handleApprove = () => __awaiter(void 0, void 0, void 0, function* () {
        if (requestId) {
            setActiveTabId(requestDetailedTabs.ROLES);
            yield dispatch(moveToApproveStep({ requestId, actionComment: "", riskCheck: true })).unwrap();
            dispatch(fetchRequestDetailed(requestId));
        }
    });
    const handleApproveWithConflicts = () => __awaiter(void 0, void 0, void 0, function* () {
        if (requestId) {
            yield dispatch(moveToApproveStep({ requestId, riskCheck: false, actionComment: approveWithConflictsMessage })).unwrap();
            dispatch(setError(null));
            dispatch(fetchRequestDetailed(requestId));
        }
    });
    const handleReject = () => {
        setActionCommentDialogOpen(true);
    };
    const handleRejectSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!requestId)
            return;
        yield dispatch(moveToRejectStep({ requestId, actionComment }))
            .unwrap()
            .then(() => dispatch(fetchRequestDetailed(requestId)))
            .finally(() => setActionCommentDialogOpen(false));
    });
    const handleSaveComment = () => {
        dispatch(saveRequestComment({ comment: newRequestComment }));
        setNewRequestComment("");
    };
    const tabs = [
        { id: requestDetailedTabs.ROLES, label: intl.formatMessage({ id: "request-detailed-tabs.roles-tab" }) },
        { id: requestDetailedTabs.COMMENTS, label: intl.formatMessage({ id: "request-detailed-tabs.comments-tab" }) },
        { id: requestDetailedTabs.HISTORY, label: intl.formatMessage({ id: "request-detailed-tabs.history-tab" }) },
    ];
    if ((currentRequest === null || currentRequest === void 0 ? void 0 : currentRequest.hasRisks) || (currentRequest === null || currentRequest === void 0 ? void 0 : currentRequest.reportReady)) {
        tabs.push({ id: requestDetailedTabs.CONFLICTS, label: intl.formatMessage({ id: "request-detailed-tabs.conflicts-tab" }) });
    }
    const [activeTabId, setActiveTabId] = useState(requestDetailedTabs.ROLES);
    const renderTabContent = () => {
        switch (activeTabId) {
            case requestDetailedTabs.ROLES:
                return React.createElement(RequestDetailedRolesTab, { resetTabSignal: rolesTabResetCounter });
            case requestDetailedTabs.COMMENTS:
                return (currentRequest === null || currentRequest === void 0 ? void 0 : currentRequest.comments) ? (React.createElement(RequestDetailedCommentsTab, { onSaveCommentClick: handleSaveComment, newRequestComment: newRequestComment, setNewRequestComment: setNewRequestComment, comments: currentRequest.comments })) : null;
            case requestDetailedTabs.HISTORY:
                return (currentRequest === null || currentRequest === void 0 ? void 0 : currentRequest.history) ? (React.createElement(RequestDetailedHistoryTab, { filterData: requestHistoryTable.filterData, getDialogFilters: requestHistoryTable.getDialogFilters, setFilterData: requestHistoryTable.setFilterData, requestHistory: requestHistoryTable.rows, total: requestHistoryTable.pagination.total, limitNumber: requestHistoryTable.pagination.rowsPerPage, setPageNumber: requestHistoryTable.pagination.onSetPage, pageNumber: requestHistoryTable.pagination.page, setLimitNumber: requestHistoryTable.pagination.onSetRowsPerPage })) : null;
            case requestDetailedTabs.CONFLICTS:
                return React.createElement(RequestDetailedConflictsTab, null);
            default:
                return null;
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(RequestDetailedHeader, { requestStages: requestStages, onBackClick: handleBackClick }),
        React.createElement(ScrollableTabs, { className: "request-detailed-tabs", tabs: tabs, value: activeTabId, onChange: setActiveTabId }),
        renderTabContent(),
        React.createElement(RequestDetailedActions, { currentStage: (_b = currentRequest === null || currentRequest === void 0 ? void 0 : currentRequest.currentStage) !== null && _b !== void 0 ? _b : "", onApproveClick: handleApprove, onCancelProcessClick: () => { }, onProcessClick: () => { }, onRejectClick: handleReject, onSaveAndContinueClick: handleSaveAndContinue, onSaveClick: handleSave }),
        React.createElement(ActionCommentDialog, { requestId: requestId !== null && requestId !== void 0 ? requestId : "", open: actionCommentDialogOpen, onClose: () => setActionCommentDialogOpen(false), comment: actionComment, setComment: setActionComment, onSubmit: handleRejectSubmit }),
        React.createElement(ApproveRequestWithConflictsDialog, { onSave: handleApproveWithConflicts, approveMessage: approveWithConflictsMessage, setApproveMessage: setApproveWithConflictsMessage, open: (error === null || error === void 0 ? void 0 : error.code) === "HAS RISKS", onClose: () => dispatch(setError(null)) }),
        React.createElement(ProgressDialog, { open: busy, busyType: busyType, title: "", progress: "" }),
        React.createElement(ErrorsDialog, { error: error, open: (error === null || error === void 0 ? void 0 : error.type) === "error" && error.code != "HAS RISKS", onClose: () => dispatch(setError(null)) })));
};
export default RequestDetailedPage;
