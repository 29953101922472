import React from "react";
import { Box, Typography, Divider, Stack, Link } from "@mui/material";
import isValid from "date-fns/isValid";
import { FormattedMessage, useIntl } from "react-intl";
import { EntityFormHeaderTitle } from "../../../common/entity-form";
import { requestStatusTexts } from "../../../../utils/brm-utils";
const LabelValue = ({ label, value }) => (React.createElement(Typography, { variant: "body2" },
    React.createElement(Typography, { sx: { fontWeight: "400", fontSize: "12px", color: "#686868" }, component: "span" },
        label,
        " "),
    " ",
    React.createElement(Typography, { sx: { fontSize: "12px", fontWeight: "400", color: "#363636" }, component: "span" }, value)));
const RequestDetailedHeaderTitle = ({ requestData, onBackClick }) => {
    var _a, _b, _c, _d, _e, _f;
    const intl = useIntl();
    const dateFormater = (value) => {
        const date = new Date(value);
        return isValid(date)
            ? intl.formatDate(date, {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
            })
            : "";
    };
    return (React.createElement(Box, { className: "request-detailed-header-title", display: "flex", alignItems: "center", justifyContent: "space-between" },
        React.createElement(EntityFormHeaderTitle, { title: intl.formatMessage({ id: "request-detailed-header-title.title" }, { requestId: requestData === null || requestData === void 0 ? void 0 : requestData.requestId }), onBackClick: onBackClick }),
        React.createElement(Stack, { className: "request-detailed-header-request-info", direction: "row", divider: React.createElement(Divider, { orientation: "vertical", flexItem: true, sx: { margin: "0" } }) },
            React.createElement(LabelValue, { label: React.createElement(FormattedMessage, { id: "request-detailed-header-title.outer-id" }), value: (_a = requestData === null || requestData === void 0 ? void 0 : requestData.requestId) !== null && _a !== void 0 ? _a : "" }),
            React.createElement(Link, { href: requestData === null || requestData === void 0 ? void 0 : requestData.url, variant: "body2", sx: { color: "#363636", textDecorationColor: "#363636" } },
                React.createElement(FormattedMessage, { id: "request-detailed-header-title.request-url" })),
            React.createElement(LabelValue, { label: React.createElement(FormattedMessage, { id: "request-detailed-header-title.request-source" }), value: (_b = requestData === null || requestData === void 0 ? void 0 : requestData.requestType) !== null && _b !== void 0 ? _b : "" }),
            React.createElement(LabelValue, { label: React.createElement(FormattedMessage, { id: "request-detailed-header-title.request-priority" }), value: (_c = requestData === null || requestData === void 0 ? void 0 : requestData.priority) !== null && _c !== void 0 ? _c : "" }),
            React.createElement(LabelValue, { label: React.createElement(FormattedMessage, { id: "request-detailed-header-title.created-by" }), value: (_d = requestData === null || requestData === void 0 ? void 0 : requestData.createdBy) !== null && _d !== void 0 ? _d : "" }),
            React.createElement(LabelValue, { label: React.createElement(FormattedMessage, { id: "request-detailed-header-title.request-status" }), value: (requestData === null || requestData === void 0 ? void 0 : requestData.status) ? ((_e = requestStatusTexts[requestData === null || requestData === void 0 ? void 0 : requestData.status]) !== null && _e !== void 0 ? _e : "") : "" }),
            React.createElement(LabelValue, { label: React.createElement(FormattedMessage, { id: "request-detailed-header-title.request-creation-date" }), value: dateFormater((_f = requestData === null || requestData === void 0 ? void 0 : requestData.createdAt) !== null && _f !== void 0 ? _f : "") }))));
};
export default RequestDetailedHeaderTitle;
