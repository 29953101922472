import { criticalRuleService } from "../services/critical-rule-service";
import { integrationService } from "../services/integration-service";
import { risksService } from "../services/risks-service";
import { systemsService } from "../services/systems-service";
import { busynessProcessesService } from "../services/busyness-processes-service";
import { systemGroupsService } from "../services/system-groups-service";

const addFilterToParams = (params, filter) => {
    if (!filter) {
        return params;
    }

    let newFilters;

    if (Array.isArray(filter)) {
        newFilters = params?.filters ? [...params.filters, ...filter] : filter;
    } else {
        newFilters = params?.filters ? [...params.filters, filter] : [filter];
    }

    return {
        ...params,
        filters: newFilters,
    };
};

export const getTransactionsSearchHelp = (params, signal, systemId, isGroup) => {
    const systemFilter = systemId ? { field: isGroup ? "groupId" : "systemId", sign: "EQ", value: systemId } : null;

    const paramsInner = addFilterToParams(params, systemFilter);

    return integrationService.getTransactions(paramsInner, signal);
};

export const getObjectTypesSearchHelp = (params, signal, systemId, isGroup) => {
    const systemFilter = systemId ? { field: isGroup ? "groupId" : "systemId", sign: "EQ", value: systemId } : null;

    const paramsInner = addFilterToParams(params, systemFilter);

    return integrationService.getObjectTypes(paramsInner, signal);
};

export const getObjectNamesSearchHelp = (params, signal, systemId, isGroup, objectType) => {
    const filters = [];

    if (systemId) {
        filters.push({
            field: isGroup ? "groupId" : "systemId",
            sign: "EQ",
            value: systemId,
        });
    }

    if (objectType) {
        filters.push({ field: "typeName", sign: "EQ", value: objectType });
    }

    const paramsInner = addFilterToParams(params, filters);

    return integrationService.getObjectNames(paramsInner, signal);
};

export const getFields1CSearchHelp = (params, signal, systemId, isGroup) => {
    const systemFilter = systemId ? { field: isGroup ? "groupId" : "systemId", sign: "EQ", value: systemId } : null;

    const paramsInner = addFilterToParams(params, systemFilter);

    return integrationService.getFields1C(paramsInner, signal);
};

export const getValues1CSearchHelp = (params, signal, systemId, field1C, objectType, isGroup) => {
    if (field1C !== "Право") {
        return {
            rows: [],
            total: 0,
        };
    }

    let filters = [];

    if (systemId) {
        filters.push({
            field: isGroup ? "groupId" : "systemId",
            sign: "EQ",
            value: systemId,
        });
    }

    if (objectType) {
        filters.push({ field: "typeName", sign: "EQ", value: objectType });
    }

    const paramsInner = addFilterToParams(params, filters);

    return integrationService.getValues1C(paramsInner, signal);
};

export const getPermissionsSearchHelp = (params, signal, systemId, isGroup) => {
    const systemFilter = systemId ? { field: isGroup ? "groupId" : "systemId", sign: "EQ", value: systemId } : null;

    const paramsInner = addFilterToParams(params, systemFilter);

    return integrationService.getPermissions(paramsInner, signal);
};

export const getManualSelectPermissionsSearchHelp = (params, signal, systemId) => {
    const systemFilter = systemId ? { field: "systemId", sign: "EQ", value: systemId } : null;

    const paramsInner = addFilterToParams(params, systemFilter);

    return integrationService.getManualSelectPermissions(paramsInner, signal);
};

export const getPermissionFieldsSearchHelp = (params, signal, systemId, permission, isGroup) => {
    const filters = [];

    if (systemId) {
        filters.push({
            field: isGroup ? "groupId" : "systemId",
            sign: "EQ",
            value: systemId,
        });
    }

    if (permission) {
        filters.push({ field: "permission", sign: "EQ", value: permission });
    }

    const paramsInner = addFilterToParams(params, filters);

    return integrationService.getPermissionFields(paramsInner, signal);
};

export const getPermissionFieldValuesSearchHelp = (params, signal, systemId, field, isGroup) => {
    const filters = [];

    if (systemId) {
        filters.push({
            field: isGroup ? "groupId" : "systemId",
            sign: "EQ",
            value: systemId,
        });
    }

    if (field) {
        filters.push({ field: "fieldname", sign: "EQ", value: field });
    }

    const paramsInner = addFilterToParams(params, filters);

    return integrationService.getPermissionFieldValues(paramsInner, signal);
};

export const getPermissionFieldValuesNarrowedSearchHelp = (params, signal, systemId, field, isGroup) => {
    const filters = [];

    if (systemId) {
        filters.push({
            field: isGroup ? "groupId" : "systemId",
            sign: "EQ",
            value: systemId,
        });
    }

    if (field) {
        filters.push({ field: "fieldname", sign: "EQ", value: field });
    }

    const paramsInner = addFilterToParams(params, filters);

    return integrationService.getPermissionFieldNarrowedValues(paramsInner, signal);
};

export const getPermissionActvtSearchHelp = (params, signal, systemId, permission, isGroup) => {
    const filters = [];

    if (systemId) {
        filters.push({
            field: isGroup ? "groupId" : "systemId",
            sign: "EQ",
            value: systemId,
        });
    }

    if (permission) {
        filters.push({ field: "permission", sign: "EQ", value: permission });
    }

    const paramsInner = addFilterToParams(params, filters);

    return integrationService.getPermissionActvtDefaultValues(paramsInner, signal);
};

export const getOrgLevelValuesSearchHelp = (params, signal, systemId, orgLevel) => {
    const filters = [];

    if (systemId) {
        filters.push({
            field: "systemId",
            sign: "EQ",
            value: systemId,
        });
    }

    if (orgLevel) {
        filters.push({ field: "orgLevel", sign: "EQ", value: orgLevel });
    }

    const paramsInner = addFilterToParams(params, filters);

    return integrationService.getOrgLevelValues(paramsInner, signal);
};

export const getSystemsSearchHelp = async (params, signal) => {
    const { rows, total } = await systemsService.getSystemFilters(
        {
            ...params,
            field: "systemId",
        },
        signal
    );

    return { rows, total };
};

export const getSystemGroups = async(params, signal) => {
    const { groups, total } = await systemGroupsService.getGroups(params, signal);
    const rows = groups;
    return { rows, total };
}

export const getSystemsList = async (params, signal) => {
    const { systems, total } = await systemsService.getSystems(
        {
            ...params,
            field: "systemId",
        },
        signal
    );
    const rows = systems;
    return { rows, total };
};

export const getSystemsExtended = async (params, signal) => {
    const { rows, total } = await systemsService.getSystemsExtended(
        {
            ...params,
            field: "systemId",
        },
        signal
    );

    return { rows, total };
};

export const getBusynessProcessesSearchHelp = async (params, signal) => {
    const { rows, total } = await busynessProcessesService.getBusynessProcessesSearchHelp(
        {
            ...params,
            field: "id",
        },
        signal
    );

    return { rows, total };
};

export const getCriticalProfilesSearchHelp = async (params, signal, systemId) => {
    const systemFilter = systemId ? { field: "systemId", sign: "EQ", value: systemId } : null;

    const paramsInner = addFilterToParams(params, systemFilter);

    const { profiles, total } = await criticalRuleService.getCriticalProfiles(paramsInner, signal);

    const rows = profiles.map((item) => ({
        key: item.name,
        text: item.description,
    }));

    return { rows, total };
};

export const getCriticalRolesSearchHelp = async (params, signal, systemId) => {
    const systemFilter = systemId ? { field: "systemId", sign: "EQ", value: systemId } : null;

    const paramsInner = addFilterToParams(params, systemFilter);

    const { roles, total } = await criticalRuleService.getCriticalRoles(paramsInner, signal);

    const rows = roles.map((item) => ({
        key: item.name,
        text: item.description,
    }));

    return { rows, total };
};

export const getRisksSearchHelp = async (params, signal) => {
    const { risks, total } = await risksService.getRisks(params, signal);

    const rows = risks.map((item) => ({
        key: item.id,
        text: item.description,
    }));

    return { rows, total };
};

export const getProfilesSearchHelp = async (params, signal, systemId) => {
    const systemFilter = systemId ? { field: "systemId", sign: "EQ", value: systemId } : null;

    const paramsInner = addFilterToParams(params, systemFilter);
    const { rows, total } = await integrationService.getProfiles(paramsInner, signal);

    return {
        rows: rows.map((row) => ({
            key: row.profile,
            text: row.description,
        })),
        total: total,
    };
};

export const getRolesSearchHelp = async (params, signal, systemId) => {
    const systemFilter = systemId ? { field: "systemId", sign: "EQ", value: systemId } : null;

    const paramsInner = addFilterToParams(params, systemFilter);
    const { rows, total } = await integrationService.getRoles(paramsInner, signal);
    return {
        rows: rows.map((row) => ({
            key: row.role,
            text: row.description,
            systemId: row.systemId,
            profile: row.profile,
        })),
        total: total,
    };
};

export const getEmployeesSearchHelp = async (params, signal) => {
    const { rows, total } = await integrationService.getEmployees(params, signal);

    return {
        rows: rows.map((row) => ({
            key: row.employee,
            text: row.fullName,
            systemId: row.systemId,
        })),
        total: total,
    };
};
