var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { CircularProgress } from "@mui/material";
import { CommonDialog, CommonDialogContent, CommonDialogTitle } from "../../../common/dialogs";
import CreateRequestForm from "./create-request-form";
import "./styles.less";
import { useDispatch } from "react-redux";
import { createNewRequest } from "../../../../reducers/administration/requestSlice";
import { useSelector } from "react-redux";
const CreateRequestDialog = ({ open, onClose }) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const validationErrors = useSelector((state) => state.administration.requests.validationErrors);
    const title = intl.formatMessage({ id: "create-request-dialog.title" });
    const [form, setForm] = useState({
        requestUrl: "",
        requestPriority: "",
        requestSource: "",
        requestId: "",
    });
    const [busy, setBusy] = useState(false);
    const handleSubmitForm = () => __awaiter(void 0, void 0, void 0, function* () {
        setBusy(true);
        try {
            const resultAction = yield dispatch(createNewRequest(form));
            if (!createNewRequest.rejected.match(resultAction)) {
                setForm({
                    requestUrl: "",
                    requestPriority: "",
                    requestSource: "",
                    requestId: "",
                });
                onClose();
            }
        }
        catch (e) {
            console.error(e);
        }
        finally {
            setBusy(false);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(CommonDialog, { className: "create-request-dialog", open: open },
            React.createElement(CommonDialogTitle, { title: title, onClose: onClose, withoutCloseIcon: false }),
            React.createElement(CommonDialogContent, null,
                busy && React.createElement(CircularProgress, { className: "report-columns-dialog-circular-progress", size: 32 }),
                React.createElement(CreateRequestForm, { form: form, disabled: false, setForm: setForm, onSubmit: handleSubmitForm, validationErrors: validationErrors, onCancel: () => {
                        onClose();
                        setForm({
                            requestUrl: "",
                            requestPriority: "",
                            requestSource: "",
                            requestId: "",
                        });
                    } })))));
};
export default CreateRequestDialog;
