// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from "react";
import CommonTable, { createRowCellContentInner } from "../../../common/table";
import './styles.less';
const columns = [
    {
        id: "select",
        type: "select",
    },
    {
        id: "role",
        title: "Имя роли",
        type: "string",
    },
    // {
    //     id: "systemId",
    //     title: "Система",
    //     type: "string",
    // },
    {
        id: "description",
        title: "Описание",
        type: "string",
    },
];
const getCellValue = (row, column) => {
    var _a;
    switch (column.id) {
        default:
            return `${(_a = row[column.id]) !== null && _a !== void 0 ? _a : ""}`;
    }
};
const AddRoleToRequestDialogTable = ({ roles, currentSystem, setCurrentSystem, getGroupSystems, selectedRoles, setSelectedRoles, searchString, setSearchString, isSelectGroupDisabled }) => {
    // const intl = useIntl();
    // const options = getGroupSystems().map((system) => {
    //     return { value: system.group_id, title: system.group_id };
    // });
    const [options, setOptions] = useState([]);
    useEffect(() => {
        getGroupSystems().then((systems) => {
            const formattedOptions = systems.map((system) => ({ value: system.id, title: system.id }));
            setOptions(formattedOptions);
            // if (formattedOptions.length > 0) {
            //     setCurrentSystem(formattedOptions[0].value);
            // }
        });
    }, []);
    const toolbarActions = [
        {
            id: "select",
            type: "select",
            position: "begin",
            options: options,
            value: currentSystem,
            disabled: isSelectGroupDisabled,
            callback: setCurrentSystem,
        },
        {
            id: "search",
            type: "search",
            position: "begin",
            value: searchString,
            callback: setSearchString,
        },
    ];
    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
    };
    const handleSelectRow = (row, value) => {
        if (value) {
            setSelectedRoles([...selectedRoles, row.id]);
            return;
        }
        setSelectedRoles(selectedRoles.filter((id) => id !== row.id));
    };
    const handleSelectAll = (value) => {
        if (!value) {
            setSelectedRoles([]);
            return;
        }
        setSelectedRoles(roles.map((role) => role.id));
    };
    return (React.createElement(CommonTable, { className: "add-role-to-request-dialog-table", rows: roles, columns: columns, toolbarActions: toolbarActions, showPagination: false, showFiltersActions: false, getCellValue: getCellValue, createRowCellContent: createRowCellContent, onRowClick: () => { }, onSelectAll: handleSelectAll, onSelectRow: handleSelectRow, selectedIds: selectedRoles, setFilterData: () => { }, useKeyboardNavigation: false, toolbar: [] }));
};
export default AddRoleToRequestDialogTable;
