import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import LockManager from "../../utils/lockManager";
import { customRolesService } from "../../services/custom-roles-service"
import { BUSY_TYPES } from "../../utils/busy"
import { reportsModelingService } from "../../services/reports-modeling-service"

const lockManager = new LockManager("custom-role");

const initialState = {
    customRoles: [],
    columns: [],
    customRolesTotal: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    currentCustomRole: null,
    currentCustomRoleEditable: false,
    currentCustomRoleDeleted: false,
    validationErrors: null,
}

export const fetchCustomRoles = createAsyncThunk(
    "reportsModeling/customRoles/fetchCustomRoles",
    async ({ params }) => {
        const response = await customRolesService.getCustomRoles(params);
        return response;
    }
);

export const fetchCustomRolesColumns = createAsyncThunk(
    "reportsModeling/customRoles/fetchCustomRolesColumns",
    async () => {
        const response = await customRolesService.getCustomRolesColumns();
        return response;
    }
);

export const fetchCustomRoleDetailed = createAsyncThunk(
    "reportModeling/customRoles/fetchRoleDetailed",
    async ({ roleId, systemId, version }) => {
        const roleResponse = await customRolesService.getCustomRoleDetailed(roleId, systemId, version);
        //const lockResponse = await lockManager.lockObject({ role: roleId, systemId: systemId });

        return {
            role: roleResponse,
            lock: {result: true}
        };
    }
);

export const createCustomRole = createAsyncThunk(
    "reportsModeling/customRoles/createRole",
    async ({ newRole, warningCheck = true }, { rejectWithValue }) => {
        let response

        try {
            response = await customRolesService.createCustomRole(newRole, warningCheck);
        } catch (messages) {
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const editCustomRole = createAsyncThunk(
    "reposrtModeling/customRoles/editRole",
    async ({ editedRole, warningCheck = true, version }, { rejectWithValue }) => {
        let response

        try {
            response = await customRolesService.editCustomRole(editedRole, warningCheck, version);
        } catch (messages) {
            return rejectWithValue(messages)
        }

        return response;
    }
);

export const clearCurrentCustomRole = createAsyncThunk(
    "reportModeling/customRoles/clearCurrentCustomRole",
    async (_, thunkAPI) => {
        const state = thunkAPI.getState();
        const currentCustomRole = state.reportsModeling.customRoles.currentCustomRole;
        const currentCustomRoleEditable = state.reportsModeling.customRoles.currentCustomRoleEditable;
        const currentCustomRoleDeleted = state.reportsModeling.customRoles.currentCustomRoleDeleted;

        if (!currentCustomRole) return thunkAPI.rejectWithValue(["Current role is not initialized"]);

        if (currentCustomRoleDeleted || !currentCustomRoleEditable || !currentCustomRole.role) return;

        const lockResponse = await lockManager.unlockObject({role: currentCustomRole.role, systemId: currentCustomRole.systemId });

        if (!lockResponse.result) {
            return thunkAPI.rejectWithValue(lockResponse.messages);
        }
    }
);

export const deleteCustomRole = createAsyncThunk(
    "reportModeling/customRoles/deleteCustomRole",
    async ({role, version}, { rejectWithValue }) => {
        try {
            await customRolesService.deleteCustomRole(role, version);
        } catch (messages) {
            return rejectWithValue(messages)
        }

        return role;
    }
);

const customRolesSlice = createSlice({
    name: "customRoles",
    initialState: initialState,
    reducers: {
        setSubmit(state, action) {
            state.submit = action.payload;
        },

        setBusy(state, action) {
            state.busy = action.payload;
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },
        setInitialCurrentCustomRole(state) {
            state.currentCustomRole = {
                id: "",
                description: "",
                systemId: "",
                isComplex: false,
                children: [],
                menu: reportsModelingService.parseRoleMenu([]),
                permissions: [],
                orgLevels: {},
                tree: [],
                profile: "",
                delta: [],
                orgLevelsDelta: [],
            };

            state.error = null;
            state.validationErrors = null;
            state.submit = false;
            state.currentCustomRoleEditable = true;
            state.currentCustomRoleDeleted = false;
        },

    },
    extraReducers: {
        //get all roles
        [fetchCustomRoles.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchCustomRoles.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },

        [fetchCustomRoles.fulfilled]: (state, action) => {
            state.busy = false;
            state.customRoles = action.payload.rows;
            state.customRolesTotal = action.payload.total;
            state.error = null;
            state.validationErrors = null;
        },
        //get columns
        [fetchCustomRolesColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchCustomRolesColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        },

        [fetchCustomRolesColumns.rejected]: (state, action) => {
            state.busy = false;
            state.errors = action.error;
        },
        // create role
        [createCustomRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [createCustomRole.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["no_integr_objects", "validation"])

            if (error.code && validationCodes.has(error.code)) {
                state.validationErrors = error.errors;
            }

            state.error = error
        },
        [createCustomRole.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.currentCustomRole = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        // fetch role detailed
        [fetchCustomRoleDetailed.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.currentCustomRoleDeleted = false;
            state.submit = false;
        },

        [fetchCustomRoleDetailed.rejected]: (state, action) => {
            state.currentCustomRole = null;
            state.error = action.payload;
            state.busy = false;
            state.currentCustomRoleEditable = false;
        },

        [fetchCustomRoleDetailed.fulfilled]: (state, action) => {
            const { role: role, lock } = action.payload;

            state.currentCustomRole = role;
            state.busy = false;
            state.currentCustomRoleEditable = lock.result;
            state.error = lock.result ? null : lock.messages;
        },

        // edit role
        [editCustomRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
            state.submit = false;
        },

        [editCustomRole.rejected]: (state, action) => {
            state.busy = false;
            const error = action.payload;

            if (!error) return;

            const validationCodes = new Set(["no_integr_objects", "validation"])

            if (error.code && validationCodes.has(error.code)) {
                state.validationErrors = error.errors;
            }

            state.error = error
        },

        [editCustomRole.fulfilled]: (state, action) => {
            state.busy = false;
            state.submit = true;
            state.currentCustomRole = action.payload;
            state.error = null;
            state.validationErrors = null;
        },

        // clear current role
        [clearCurrentCustomRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [clearCurrentCustomRole.fulfilled]: (state) => {
            state.busy = false;
            state.currentCustomRole = null;
            state.currentCustomRoleEditable = false;
            state.currentCustomRoleDeleted = false;
            state.error = null;
            state.validationErrors = null;
            state.submit = false;
        },

        [clearCurrentCustomRole.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },
        //delete custom role
        [deleteCustomRole.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteCustomRole.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [deleteCustomRole.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;

            const customRole = action.payload;
            state.customRoles = state.customRoles.filter(item => !(item.role === customRole.role && item.system === customRole.system));

            if (state.currentCustomRole?.role === customRole.role && state.currentCustomRole?.system === customRole.system) {
                state.submit = true;
                state.currentCustomRoleDeleted = true;
            }
        },

    }

})

export const {
    setSelectedIds, setSearchString, setPageNumber, setLimitNumber, setInitialCurrentCustomRole,
    setSortData, setFilterData, setSubmit, setError, setValidationErrors, setBusy
} = customRolesSlice.actions;

export default customRolesSlice.reducer;
