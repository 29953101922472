export const REQUESTS_TYPE = {
    ALL: "all",
    USERS: "users",
};
export const REQUEST_PRIORITIES = {
    LOW: "low",
    MEDIUM: "medium",
    HIGH: "high",
};
export const REQUEST_SOURCES = {
    APPLICATION: "application",
    JIRA_TASK: "jira_task",
};
export const requestStageTexts = {
    ROLE_DEFINITION: "Определение ролей",
    TEST_APPROVEMENT: "Подтверждение тестирования",
    ROLE_OWNER_APPROVEMENT: "Согласование владельцами ролей",
};
export const requestStatusTexts = {
    onProcess: "На согласовании",
    approved: "Утверждено",
    completed: "Завершено",
    total: "Всего",
};
export const requestStatuses = {
    ROLE_DEFINITION: "onProcess",
    TEST_APPROVEMENT: "onProcess",
    ROLE_OWNER_APPROVEMENT: "onProcess",
};
export const requestStages = {
    ROLE_DEFINITION: "ROLE_DEFINITION",
    TEST_APPROVEMENT: "TEST_APPROVEMENT",
    ROLE_OWNER_APPROVEMENT: "ROLE_OWNER_APPROVEMENT",
};
export const requestDetailedTabs = {
    ROLES: "ROLES",
    COMMENTS: "COMMENTS",
    HISTORY: "HISTORY",
    CONFLICTS: "CONFLICTS",
};
