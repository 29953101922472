import React from "react";
import { useIntl } from "react-intl";
import { Box } from "@mui/material";
import { SelectInput, TextInput } from "../../../common/form-controls";
import { MenuItem } from "../../../common/menu";
import { Button } from "../../../common/buttons";
import { REQUEST_PRIORITIES, REQUEST_SOURCES } from "../../../../utils/brm-utils";
import "./styles.less";
const RequestUrlInput = ({ requestUrl, setRequestUrl, disabled, error }) => {
    const intl = useIntl();
    return (React.createElement(TextInput, { label: intl.formatMessage({
            id: "create-request-dialog.input-request-url",
        }), value: requestUrl, onChange: (event) => setRequestUrl(event.target.value), disabled: disabled, error: (error === null || error === void 0 ? void 0 : error.value) === requestUrl, errorMessage: error === null || error === void 0 ? void 0 : error.message }));
};
const RequestPriorityInput = ({ disabled, value, onChange, error }) => {
    const intl = useIntl();
    const requestPriorityList = [
        {
            key: REQUEST_PRIORITIES.LOW,
            text: "Низкий",
        },
        {
            key: REQUEST_PRIORITIES.MEDIUM,
            text: "Средний",
        },
        {
            key: REQUEST_PRIORITIES.HIGH,
            text: "Высокий",
        },
    ];
    const label = intl.formatMessage({
        id: "create-request-dialog.input-request-priority",
    });
    return (React.createElement(SelectInput, { error: (error === null || error === void 0 ? void 0 : error.value) === value, errorMessage: error === null || error === void 0 ? void 0 : error.message, label: label, value: value, disabled: disabled, onChange: (event) => onChange(event.target.value) }, requestPriorityList.map(({ key, text }) => (React.createElement(MenuItem, { key: key, value: key }, text)))));
};
const RequestSourceInput = ({ disabled, value, onChange, error }) => {
    const intl = useIntl();
    const requestSourceList = [
        {
            key: REQUEST_SOURCES.JIRA_TASK,
            text: "Задача Jira",
        },
        {
            key: REQUEST_SOURCES.APPLICATION,
            text: "Заявка",
        },
    ];
    const label = intl.formatMessage({
        id: "create-request-dialog.input-request-source",
    });
    return (React.createElement(SelectInput, { error: (error === null || error === void 0 ? void 0 : error.value) === value, errorMessage: error === null || error === void 0 ? void 0 : error.message, label: label, value: value, disabled: disabled, onChange: (event) => onChange(event.target.value) }, requestSourceList.map(({ key, text }) => (React.createElement(MenuItem, { key: key, value: key }, text)))));
};
const RequestIdInput = ({ requestUrl: requestId, setRequestUrl: setRequestId, disabled, error }) => {
    const intl = useIntl();
    return (React.createElement(TextInput, { label: intl.formatMessage({
            id: "create-request-dialog.input-request-id",
        }), value: requestId, onChange: (event) => setRequestId(event.target.value), disabled: disabled, error: (error === null || error === void 0 ? void 0 : error.value) === requestId, errorMessage: error === null || error === void 0 ? void 0 : error.message }));
};
const SubmitButton = ({ disabled, onClick }) => {
    const intl = useIntl();
    const text = intl.formatMessage({ id: "create-request-dialog.submit.text" });
    return (React.createElement(Button, { disabled: disabled, variant: "contained", className: "create-request-dialog-form-submit", onClick: onClick }, text));
};
const CancelButton = ({ disabled, onClick }) => {
    const intl = useIntl();
    const text = intl.formatMessage({ id: "create-request-dialog.cancel.text" });
    return (React.createElement(Button, { disabled: disabled, variant: "outlined", className: "create-request-dialog-form-cancel", onClick: onClick }, text));
};
const CreateRequestForm = ({ form, setForm, disabled, onSubmit, onCancel, validationErrors }) => {
    return (React.createElement(Box, { className: "create-request-dialog-form" },
        React.createElement(RequestUrlInput, { error: (validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.find((error) => error.field === "request_url")) || undefined, requestUrl: form.requestUrl, setRequestUrl: (value) => setForm(Object.assign(Object.assign({}, form), { requestUrl: value })), disabled: disabled }),
        React.createElement(RequestPriorityInput, { error: (validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.find((error) => error.field === "request_priority")) || undefined, disabled: disabled, value: form.requestPriority, onChange: (value) => setForm(Object.assign(Object.assign({}, form), { requestPriority: value })) }),
        React.createElement(RequestSourceInput, { error: (validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.find((error) => error.field === "request_source")) || undefined, disabled: disabled, value: form.requestSource, onChange: (value) => setForm(Object.assign(Object.assign({}, form), { requestSource: value })) }),
        React.createElement(RequestIdInput, { error: (validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.find((error) => error.field === "request_id")) || undefined, requestUrl: form.requestId, setRequestUrl: (value) => setForm(Object.assign(Object.assign({}, form), { requestId: value })), disabled: disabled }),
        React.createElement(Box, { className: "create-request-dialog-form-buttons" },
            React.createElement(SubmitButton, { disabled: disabled, onClick: onSubmit }),
            React.createElement(CancelButton, { disabled: disabled, onClick: onCancel }))));
};
export default CreateRequestForm;
