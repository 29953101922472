import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Tabs, Tab } from "@mui/material";
import { useDispatch } from "react-redux";
import { setModelingSelectedRole, selectModelingSelectedRole, selectChangeRequestStage } from "../../../../reducers/administration/requestSlice";
import RequestSingleRoleModelingBox from "./request-single-role-modeling-box";
import RequestGroupRoleModelingBox from "./request-group-role-modeling-role";
import RequestDetailedRolesTable from "./request-detailed-roles-table";
import { ProgressDialog } from "../../../common/dialogs";
import "./styles.less";
import { requestStages } from "../../../../utils/brm-utils";
const RequestDetailedRolesTab = ({ resetTabSignal = 0 }) => {
    const dispatch = useDispatch();
    const roles = useSelector((state) => state.administration.requests.rolesForView.roles);
    const busy = useSelector((state) => state.administration.requests.busy);
    const busyType = useSelector((state) => state.administration.requests.busyType);
    const modelingRole = useSelector(selectModelingSelectedRole);
    const currentRequestStatus = useSelector(selectChangeRequestStage);
    const [currentTab, setCurrentTab] = useState(0);
    useEffect(() => {
        setCurrentTab(0);
    }, [resetTabSignal]);
    const roleForEditDisabled = currentRequestStatus !== requestStages.ROLE_DEFINITION;
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };
    useEffect(() => {
        if (currentTab !== 0) {
            const selectedRole = roles[currentTab - 1];
            dispatch(setModelingSelectedRole(selectedRole));
        }
    }, [currentTab]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, null,
            React.createElement(Box, { sx: {
                    backgroundColor: "white",
                    borderRadius: "12px 12px 0 0",
                } },
                React.createElement(Tabs, { className: "request-roles-table-tabs", value: currentTab, onChange: handleChange, variant: "scrollable", scrollButtons: "auto" }, ["Таблица ролей", ...roles.map((item) => item.role)].map((label, index) => (React.createElement(Tab, { className: "request-roles-table-tab", key: index, label: label, sx: {
                        backgroundColor: currentTab === index ? "white" : "transparent",
                        "&:hover": {
                            backgroundColor: currentTab === index ? "white" : "#E0E0E0",
                        },
                    } }))))),
            React.createElement(Box, null,
                currentTab === 0 && React.createElement(RequestDetailedRolesTable, null),
                currentTab !== 0 &&
                    ((modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.isComplex) ? (React.createElement(RequestGroupRoleModelingBox, { disabled: roleForEditDisabled })) : (React.createElement(RequestSingleRoleModelingBox, { disabled: roleForEditDisabled }))))),
        React.createElement(ProgressDialog, { open: busy, busyType: busyType, title: "", progress: "" })));
};
export default RequestDetailedRolesTab;
