import React from "react";
import { Box, IconButton, Popover, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
const ThreeDotsButton = ({ menuItems }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (React.createElement(Box, { position: "relative" },
        React.createElement(IconButton, { onClick: handleClick },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: Boolean(anchorEl), anchorEl: anchorEl, onClose: handleClose, anchorOrigin: { vertical: "bottom", horizontal: "left" } }, menuItems.map((item, index) => (React.createElement(MenuItem, { key: index, onClick: item.onClick }, item.label))))));
};
export default ThreeDotsButton;
