import SodService from "./sod-service";

class UsersService extends SodService {
    async signIn(username, password) {
        const data = await this.post('/auth/token', { username, password }, {}, false);
        return data;
    }
    
    async getCurrentUser() {
        const data = await this.get('/core/auth/user');
        data.actions.push({
            "action_id": "administration",
            "action_type": "brm",
            "title": "Администрирование",
            "button_type": "menu_button"
        })
        return this.parseCurrentUser(data);
    }

    async getUserRoles() {
        const data = await this.get("/core/auth/user-groups");
        const userRoles = data.map(userRole => this.parseUserRole(userRole));
        return userRoles;
    }

    async getUsers(params) {
        const config = this.generateConfigForGetEntities(params);

        const data = await this.get("/core/auth/users", config);

        const users = data?.rows.map(user => this.parseUser(user)) || [];
        const total = data?.total;

        return { users, total };
    }

    async getUsersFilters(params) {
        return this.getFilters("/core/auth/users/filters", params);
    }

    async getUserDetailed(username) {
        const data = await this.get(`/core/auth/user-detailed/${username}`);
        return this.praseUserDetailed(data);
    }

    async createUser(user) {
        const data = await this.post(`/core/auth/user-detailed/${user.username}`, this.parseUserToServer(user));
        return this.praseUserDetailed(data);
    }

    async editUser(user) {
        const data = await this.put(`/core/auth/user-detailed/${user.username}`, this.parseUserToServer(user));
        return this.praseUserDetailed(data);
    }

    async changeUserPassword(username, password) {
        const data = await this.post(`/core/auth/user/change-password`, { username, password });
        return data;
    }

    async changeUserPasswordInProfile(password){
        const data = await this.post('/core/auth/user/change-password-in-profile', {password})
        return data
    }

    async getUserAgreementText(){
        const data = await this.get('/core/auth/user-agreement')
        return data
    }

    async acceptUserAgreement(){
        const data = await this.post('/core/auth/user-agreement')
        return data
    }

    async lockUser(username, lock=false) {
        const data = await this.post(`/core/auth/user-detailed/lock`, { username, action: lock ? "lock" : "unlock" });
        return data;
    }

    async deleteUser(username) {
        return this.delete(`/core/auth/user-detailed/${username}`)
    }

    async getUsersColumns() {
        return await this.getColumns("/core/auth/users/columns");
    }

    async getUserChangeHistory(key) {
        const data = await this.get(`/core/auth/user-detailed/change-history/${key}`)
        return data && data.map(item => this.parseUserActionLog(item))
    }

    parseUser(user) {
        return {
            username: user.username,
            firstName: user.first_name,
            lastName: user.last_name,
            middleName: user.middle_name,
            role: user.role,
            phone: user.phone,
            email: user.email,
            dateJoined: user.date_joined,
        };
    }

    praseUserDetailed(user) {
        return {
            username: user.username,
            firstName: user.first_name,
            lastName: user.last_name,
            middleName: user.middle_name,
            role: user.role,
            phone: user.phone,
            email: user.email,
            dateJoined: user.date_joined,
            password: user.password,
            locked: user.is_locked,
            approvalGroup: user.approval_group,
        };
    }

    parseUserToServer(user) {
        return {
            username: user.username,
            first_name: user.firstName,
            last_name: user.lastName,
            middle_name: user.middleName,
            role: user.role,
            phone: user.phone,
            email: user.email,
            date_joined: user.dateJoined,
            password: user.password,
            approval_group: user.approvalGroup,
        };
    }

    parseUserRole(userRole) {
        return {
            id: userRole.group_id,
            name: userRole.name
        }
    }

    parseCurrentUser(user) {
        return {
            username: user.username,
            firstName: user.first_name,
            lastName: user.last_name,
            middleName: user.middle_name,
            email:user.email,
            phone:user.phone,
            role:user.role,
            canResetIntegrationStatus: user.can_reset_integration_status,
            actions: user.actions.map(this.parseAction),
            screenButtons: user.screen_buttons?.map(this.parseAction),
            shouldChangePassword: user.should_change_password,
            acceptedUserAgreement: user.accepted_user_agreement,
        }
    }

    parseAction(action) {
        return {
            id: action.action_id,
            type: action.action_type,
            title: action.title
        }
    }
}

const usersService = new UsersService();

export { usersService };
export default UsersService;
