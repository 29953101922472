import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import {
    setUsers as setUsersAction,
    //setSystems as setSystemsAction,
    setIntegrIds as setIntegrIdsAction,
    setSystemsExtended as setSystemsExtendedAction,
    setMatrixHeaders as setMatrixHeadersAction,
    setRisks as setRisksAction,
    setRiskLevels as setRiskLevelsAction,
    setParametersOpen as setParametersOpenAction,
    setOnlineSync as setOnlineSyncAction,
    setReportLevelMode as setReportLevelModeAction,
    setReportLevel,
    setCanFetchResults,
    setEmptyResultTable,
    fetchReportColumns, 
    setReportTypeWithColumns
} from "../../../reducers/reports/userLevelSlice";

import { getFieldValidationError } from "../../../utils/sodReports";

import useReduxValue from "../../common/hooks/useReduxValue";

import ReportParameters, {
    MatrixIdsInput, RiskIdsInput, RiskLevelInput,
    UsersInput, SystemIdsInput, OnlineSyncCheckbox, CriticalObjectsCheckbox
} from "../report-parameters";

import { REPORT_LEVEL_MODE, REPORT_LEVELS, REPORT_TYPES } from "../../../utils/sodReports";
import { reportsService } from "../../../services/reports-service";

function openNewTab(url) {
    const finalUrl = `${window.location.origin}/${url}`
    window.open(finalUrl, '_blank').focus();
}


const UserLevelReportParameters = ({ onShowVariantsClick }) => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const currentVariant = useSelector(state => state.reports.userLevel.currentVariant);
    const validationErrors = useSelector(state => state.reports.userLevel.validationErrors);

    const [users, setUsers] = useReduxValue(state => state.reports.userLevel.users, setUsersAction);
    const [integrIds, setIntegrIds] = useReduxValue(state => state.reports.userLevel.integrIds, setIntegrIdsAction);
    //const [systems, setSystems] = useReduxValue(state => state.reports.userLevel.systems, setSystemsAction);
    const [systemsExtended, setSystemsExtended] = useReduxValue(state => state.reports.userLevel.extendedSystems, setSystemsExtendedAction);
    const [matrixHeaders, setMatrixHeaders] = useReduxValue(state => state.reports.userLevel.matrixHeaders, setMatrixHeadersAction);
    const [risks, setRisks] = useReduxValue(state => state.reports.userLevel.risks, setRisksAction);
    const [riskLevels, setRiskLevels] = useReduxValue(state => state.reports.userLevel.riskLevels, setRiskLevelsAction);
    const [onlineSync, setOnlineSync] = useReduxValue(state => state.reports.userLevel.onlineSync, setOnlineSyncAction);
    const [reportLevelMode, setReportLevelMode] = useReduxValue(state => state.reports.userLevel.reportLevelMode, setReportLevelModeAction);
    const [open, setOpen] = useReduxValue(state => state.reports.userLevel.parametersOpen, setParametersOpenAction);

    const titles = [intl.formatMessage({ id: "user-level-report-page.title" })];

    if (currentVariant) {
        titles.push(intl.formatMessage(
            { id: "user-level-report-page.variant-selected.title" },
            { name: currentVariant.variantName }
        ));
    }

    const handleOpenClick = (event) => {
        event.stopPropagation();
        setOpen(!open);
    };

    const getUsersByLogin = useCallback((params, signal) => reportsService.getUsersUnifiedByLoginSearchHelp(params, signal), []);
    const getUsersByIntegrId = useCallback((params, signal) => reportsService.getUsersUnifiedByIntegrIdSearchHelp(params, signal), []);

    return (
        <ReportParameters
            title={titles.join(" ")}
            open={open}
            onOpenClick={handleOpenClick}
            onShowVariantsClick={onShowVariantsClick}
        >
            <UsersInput
                ids={users}
                setIds={setUsers}
                error={getFieldValidationError(validationErrors, "usernames")}
                getUsers={getUsersByLogin}
                label={intl.formatMessage({ id: "report-parameters.users-label-by-employee" })}
            />

            <UsersInput
                ids={integrIds}
                setIds={setIntegrIds}
                error={getFieldValidationError(validationErrors, "integration_id")}
                getUsers={getUsersByIntegrId}
                label={intl.formatMessage({ id: "report-parameters.users-label-by-integr-id" })}
            />

            <SystemIdsInput
                tags={systemsExtended}
                setTags={setSystemsExtended}
            />

            <MatrixIdsInput
                tags={matrixHeaders}
                setTags={setMatrixHeaders}
                onTagClick={tag => {
                    openNewTab(`sod-maintenance/access-rules/matrix-headers/form/${tag}`)
                }}

            />

            <RiskIdsInput
                tags={risks}
                setTags={setRisks}
                disabled={reportLevelMode == REPORT_LEVEL_MODE.CRITICAL_OBJECTS}
                onTagClick={tag => {
                    openNewTab(`sod-maintenance/access-rules/risks/form/${tag}`)
                }}
            />

            <RiskLevelInput
                tags={riskLevels}
                setTags={setRiskLevels}
            />

            <OnlineSyncCheckbox
                checked={onlineSync}
                onChange={(e) => setOnlineSync(e.target.checked)}
            />
            <CriticalObjectsCheckbox
                checked={reportLevelMode == REPORT_LEVEL_MODE.CRITICAL_OBJECTS}
                onChange={
                    (e) => {
                        dispatch(setCanFetchResults(false));
                        dispatch(setEmptyResultTable());
                        if (e.target.checked) {
                            setReportLevelMode(REPORT_LEVEL_MODE.CRITICAL_OBJECTS);
                            dispatch(setReportLevel(REPORT_LEVELS.CRITICAL_OBJECTS))
                            dispatch(setReportTypeWithColumns(REPORT_TYPES.FINAL))
                            setRisks([])
                        } else {
                            setReportLevelMode(REPORT_LEVEL_MODE.ACTION_PERMISSION)
                            dispatch(setReportLevel(REPORT_LEVELS.OPERATIONS))
                            dispatch(fetchReportColumns())
                        }
                    }
                }
            />

        </ReportParameters>
    );
};

UserLevelReportParameters.propTypes = {
    onShowVariantsClick: PropTypes.func
};

export default UserLevelReportParameters;
