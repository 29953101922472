import React from "react";
import { Box, Typography } from "@mui/material";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { TextInput } from "../../common/form-controls";

import { permissionObjectPropTypes, fieldPropTypes } from "./prop-types";

import "./styles.less";

const ReportModelingEditDialogHeaderInput = ({ label, value, description, disabled }) => {
    return (
        <Box className="report-modeling-edit-dialog-header-input-wrapper">
            <TextInput
                className="report-modeling-edit-dialog-header-input"
                label={label}
                value={value}
                shrink
                disabled={disabled}
            />
            <Typography className="report-modeling-edit-dialog-header-input-description">
                {description}
            </Typography>
        </Box>
    )
};

const ReportModelingEditDialogHeader = ({ permissionObject, field, disabled }) => {
    const intl = useIntl();
    const permissionObjectLabel = intl.formatMessage({ id: "report-modeling-edit-dialog.permission-object.label" });
    const fieldLabel = intl.formatMessage({ id: "report-modeling-edit-dialog.field.label" });

    return (
        <Box className="report-modeling-edit-dialog-header">
            <ReportModelingEditDialogHeaderInput
                label={permissionObjectLabel}
                value={permissionObject.id}
                description={permissionObject.description}
                disabled={disabled}
            />
            <ReportModelingEditDialogHeaderInput
                label={fieldLabel}
                value={field.id}
                description={field.description}
                disabled={disabled}
            />
        </Box>
    );
};

ReportModelingEditDialogHeaderInput.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    description: PropTypes.string,
    disabled: PropTypes.bool
};

ReportModelingEditDialogHeader.propTypes = {
    permissionObject: permissionObjectPropTypes,
    field: fieldPropTypes,
    disabled: PropTypes.bool
};

export default ReportModelingEditDialogHeader;