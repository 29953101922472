import React, { useContext } from "react";
import { TableHead, TableBody } from "@mui/material";
import PropTypes from 'prop-types';

import { PageContentBox } from "../page-content-box";

import ToolbarActions from "./action-toolbar.jsx";
import PaginationBar from "./pagination-bar.jsx";
import FiltersActions from "./filters-actions.jsx";
import FiltersProvider, { FiltersContext } from "./filters-provider.jsx";
import { KeyboardNavigationWrapper } from "./keyboard-navigation-provider.jsx";
import { getCellClassNameInner, CommonTableRow, createBodyRowContent, createRowCellContentInner, CommonTableContainer, CommonTableComponent, CommonTableColumnRow, Column } from "./table-components.jsx";
import VirtualisedTable from "./virtualized-table.jsx";
import "./styles.less";

const createBodyRow = ({
    rowSx,
    row,
    rowIndex,
    columns,
    selectedIds,
    getRowId,
    onSelectRow,
    onRowClick,
    getCellValue,
    createRowCellContent=createRowCellContentInner,
    getCellClassName=getCellClassNameInner
}) => {
    const id = getRowId(row);

    return (
        <CommonTableRow
            key={id}
            sx={rowSx}
            onClick={() => onRowClick(row)}
        >
            {createBodyRowContent({
                rowIndex,
                row,
                getRowId,
                selectedIds,
                columns,
                onSelectRow,
                getCellValue,
                createRowCellContent,
                getCellClassName
            })}
        </CommonTableRow>
    );
};

const InnerTable = ({
    rows,
    columns,
    filterData,
    sortData,
    selectedIds,
    selectedAll,
    getRowId,
    getCellValue,
    createRowCellContent,
    onRowClick,
    onSelectRow,
    onSelectAll,
    onFilterClick,
    getCellClassName
}) => {
    return (
        <CommonTableContainer>
            <CommonTableComponent>
                <TableHead>
                    <CommonTableColumnRow>
                        {columns && columns.map((column, columnIndex) => (
                            <Column
                                key={column.id}
                                columnIndex={columnIndex}
                                column={column}
                                filterData={filterData}
                                sortData={sortData}
                                onSelect={onSelectAll}
                                checked={selectedAll}
                                onFilterClick={onFilterClick}
                            />
                        ))}
                    </CommonTableColumnRow>
                </TableHead>
                <TableBody >
                    {rows?.map((row, rowIndex) => createBodyRow({
                        row,
                        rowIndex,
                        columns,
                        selectedIds,
                        getRowId,
                        onSelectRow,
                        onRowClick,
                        getCellValue,
                        createRowCellContent,
                        getCellClassName
                    }))}
                </TableBody>
            </CommonTableComponent>
        </CommonTableContainer>
    );
};

const CommonTableContent = ({
    className="",
    rows,
    columns,
    toolbarActions,
    toolbar,
    selectedIds,
    onSelectRow,
    onSelectAll,
    getRowId,
    showPagination,
    pageNumber,
    limitNumber,
    setPageNumber,
    setLimitNumber,
    total,
    createRowCellContent,
    onRowClick,
    filterData,
    showFiltersActions,
    searchString,
    setSearchString,
    getCellValue,
    sortData,
    getCellClassName,
    virtualize,
    virtualizationProps
}) => {
    const filtersContext = useContext(FiltersContext);

    const handleSearch = (newSearchString) => {
        setSearchString(newSearchString);
    };

    let selectedAll = rows.length > 0;

    for (const row of rows) {
        selectedAll = selectedAll && selectedIds.includes(getRowId(row));
        if (!selectedAll) break;
    }

    const TableComponent = virtualize ? VirtualisedTable : InnerTable;

    const toolbarActionsComponent = (toolbarActions && toolbarActions.length > 0) ? <ToolbarActions toolbarActions={toolbarActions}/> : null

    const toolbarPropComponent = toolbar ? toolbar : null

    return (
        <PageContentBox className={`table-content-box ${className}`}>
            {toolbarActionsComponent}

            {toolbarPropComponent}

            {showFiltersActions && (
                <FiltersActions
                    searchString={searchString}
                    onSearch={handleSearch}
                    onReset={filtersContext?.handleResetAllFilters}
                    filtersNumber={filtersContext?.filtersNumber}
                />
            )}

            <TableComponent
                rows={rows}
                columns={columns}
                filterData={filterData}
                sortData={sortData}
                selectedIds={selectedIds}
                selectedAll={selectedAll}
                getRowId={getRowId}
                getCellValue={getCellValue}
                createRowCellContent={createRowCellContent}
                onRowClick={onRowClick}
                onSelectRow={onSelectRow}
                onSelectAll={onSelectAll}
                onFilterClick={filtersContext?.handleFilterClick}
                getCellClassName={getCellClassName}
                virtualizationProps={virtualizationProps}
            />

            {showPagination && (
                <PaginationBar
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    limitNumber={limitNumber}
                    setLimitNumber={setLimitNumber}
                    total={total}
                />
            )}
        </PageContentBox>
    )
};

const CommonTable = ({
    className="",
    rows,
    columns,
    toolbarActions,
    selectedIds,
    onSelectRow,
    onSelectAll,
    getRowId,
    showPagination,
    pageNumber,
    limitNumber,
    setPageNumber,
    setLimitNumber,
    total,
    getFilters,
    createRowCellContent,
    onRowClick,
    filterData,
    setFilterData,
    showFiltersActions,
    searchString,
    setSearchString,
    getCellValue,
    sortData,
    setSortData,
    virtualize,
    virtualizationProps,
    useKeyboardNavigation,
    getCellClassName,
    toolbar, 
    filtersCallback = () => {}
}) => {
    let classNameInner = className;

    if (useKeyboardNavigation) {
        classNameInner += "table-with-keyboard-nav";
    }

    return (
        <FiltersProvider
            getFilters={getFilters}
            filterData={filterData}
            setFilterData={setFilterData}
            sortData={sortData}
            setSortData={setSortData}
            filtersCallback={filtersCallback}
        >
            <KeyboardNavigationWrapper
                useKeyboardNavigation={useKeyboardNavigation}
                rows={rows}
                columns={columns}
            >
                <CommonTableContent
                    className={classNameInner}
                    rows={rows}
                    columns={columns}
                    toolbarActions={toolbarActions}
                    toolbar={toolbar}
                    selectedIds={selectedIds}
                    onSelectRow={onSelectRow}
                    onSelectAll={onSelectAll}
                    getRowId={getRowId}
                    showPagination={showPagination}
                    pageNumber={pageNumber}
                    limitNumber={limitNumber}
                    setPageNumber={setPageNumber}
                    setLimitNumber={setLimitNumber}
                    total={total}
                    createRowCellContent={createRowCellContent}
                    onRowClick={onRowClick}
                    filterData={filterData}
                    showFiltersActions={showFiltersActions}
                    searchString={searchString}
                    setSearchString={setSearchString}
                    getCellValue={getCellValue}
                    sortData={sortData}
                    getCellClassName={getCellClassName}
                    virtualize={virtualize}
                    virtualizationProps={virtualizationProps}
            />
            </KeyboardNavigationWrapper>
        </FiltersProvider>
    );
};

InnerTable.propTypes = {
    rows: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.object),
    filterData: PropTypes.arrayOf(PropTypes.object),
    sortData: PropTypes.arrayOf(PropTypes.object),
    selectedIds: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ])
    ),
    selectedAll: PropTypes.bool,
    getRowId: PropTypes.func,
    getCellValue: PropTypes.func,
    createRowCellContent: PropTypes.func,
    onRowClick: PropTypes.func,
    onSelectRow: PropTypes.func,
    onSelectAll: PropTypes.func,
    onFilterClick: PropTypes.func,
    getCellClassName: PropTypes.func
};

CommonTableContent.propTypes = {
    className: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.object),
    columns: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        width: PropTypes.string,
    })),
    toolbarActions: PropTypes.arrayOf(PropTypes.object),
    selectedIds: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string
        ])
    ),
    onSelectRow: PropTypes.func,
    onSelectAll: PropTypes.func,
    getRowId: PropTypes.func,
    showPagination: PropTypes.bool,
    pageNumber: PropTypes.number,
    limitNumber: PropTypes.number,
    setPageNumber: PropTypes.func,
    setLimitNumber: PropTypes.func,
    total: PropTypes.number,
    createRowCellContent: PropTypes.func,
    onRowClick: PropTypes.func,
    filterData: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        sign: PropTypes.oneOf(['EQ', "GE", "LE", "CO", "NE"]),
        value: PropTypes.string
    })),
    showFiltersActions: PropTypes.bool,
    searchString: PropTypes.string,
    setSearchString: PropTypes.func,
    getCellValue: PropTypes.func,
    sortData: PropTypes.arrayOf(PropTypes.shape({
        field: PropTypes.string,
        sign: PropTypes.oneOf(['asc', "desc"]),
    })),
    getCellClassName: PropTypes.func,
    virtualize: PropTypes.bool,
    virtualizationProps: PropTypes.object
};

CommonTable.propTypes = {
    ...CommonTableContent.propTypes,
    getFilters: PropTypes.func,
    setSortData: PropTypes.func,
    rowHeight: PropTypes.number,
    headerHeight: PropTypes.number
};

CommonTable.defaultProps = {
    selectedIds: [],
    getRowId: (row) => row.id,
    showPagination: true,
    limitNumber: 10,
    getFilters: () => { },
    createRowCellContent: createRowCellContentInner,
    onRowClick: () => { },
    showFiltersActions: true,
    searchString: "",
    getCellValue: (row, column) => row[column.id],
    rowHeight: 56,
    virtualize: false,
    getCellClassName: getCellClassNameInner
};

export default CommonTable;