import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import PropTypes from "prop-types";

import { CloseIcon } from "../icons";

export const CommonDialog = ({ className = "", ...props }) => {
  return <Dialog className={`common-dialog ${className}`} {...props} />;
};

export const CommonDialogTitle = ({
  title,
  className = "",
  onClose,
  withoutCloseIcon,
  ...props
}) => {
  return (
    <DialogTitle className={`common-dialog-title ${className}`} {...props}>
      {title}
      {!withoutCloseIcon && (
        <IconButton onClick={onClose} sx={{ marginLeft: "auto" }}>
          <CloseIcon color="#B7B7B7" />
        </IconButton>
      )}
    </DialogTitle>
  );
};

export const CommonDialogContent = ({ className = "", ...props }) => {
  return (
    <DialogContent
      className={`common-dialog-content ${className}`}
      {...props}
    />
  );
};

export const CommonDialogActions = ({ className = "", ...props }) => {
  return (
    <DialogActions
      className={`common-dialog-actions ${className}`}
      {...props}
    />
  );
};

CommonDialog.propTypes = {
  className: PropTypes.string,
};

CommonDialogTitle.propTypes = {
  title: PropTypes.node,
  className: PropTypes.string,
  onClose: PropTypes.func,
  withoutCloseIcon: PropTypes.bool,
};

CommonDialogContent.propTypes = {
  className: PropTypes.string,
};

CommonDialogActions.propTypes = {
  className: PropTypes.string,
};
