import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { isValid } from "date-fns";
import { Box, Typography } from "@mui/material";
import { TextInput } from "../../../common/form-controls";
import { Button } from "../../../common/buttons";
const RequestCommentComponent = ({ comment }) => {
    const intl = useIntl();
    const date = new Date(comment.createdAt);
    const dateString = isValid(date) &&
        intl.formatDate(date, {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
        });
    return (React.createElement(Box, { gap: 1, display: "flex", flexDirection: "column" },
        React.createElement(Typography, { sx: { fontSize: "18px", fontWeight: "500", fontFamily: "Mulish" } }, comment.author),
        React.createElement(Typography, { sx: { fontFamily: "Mulish", fontWeight: "300", fontSize: "18px" } }, comment.comment),
        React.createElement(Typography, { sx: { fontFamily: "Mulish", fontWeight: "300", fontSize: "18px", color: "#828282" } }, dateString)));
};
const RequestDetailedCommentsTab = ({ onSaveCommentClick, newRequestComment, setNewRequestComment, comments }) => {
    const intl = useIntl();
    return (React.createElement(Box, { gap: 3, display: "flex", flexDirection: "column", sx: { width: "45%" } },
        React.createElement(Box, { gap: 3, display: "flex", flexDirection: "column", sx: { border: "1px solid #ACACAC", borderRadius: "4px", padding: "10px", height: "300px", overflowY: "auto" } }, comments.length > 0 ? comments.map((comment, index) => React.createElement(RequestCommentComponent, { key: index, comment: comment })) : null),
        React.createElement(Box, null,
            React.createElement(TextInput, { label: intl.formatMessage({ id: "request-detailed-comments-tab.input-send-new-comment.label" }), value: newRequestComment, onChange: (event) => setNewRequestComment(event.target.value), multiline: true, rows: 4 }),
            React.createElement(Button, { variant: "outlined", onClick: onSaveCommentClick, disabled: !((newRequestComment === null || newRequestComment === void 0 ? void 0 : newRequestComment.length) > 0), sx: {
                    color: "var(--font-4)",
                    borderColor: "var(--font-4)",
                    marginTop: "12px",
                } },
                React.createElement(FormattedMessage, { id: "request-detailed-comments-tab.btn-send-new-comment" })))));
};
export default RequestDetailedCommentsTab;
