import React, { useCallback, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { EntityFormViewTable } from "../../../../common/entity-form";
import { createRowCellContentInner } from "../../../../common/table";
import { FormattedMessage } from "react-intl";
import { SearchHelpInput, SelectInput } from "../../../../common/form-controls";
import { MenuItem, Checkbox, Tooltip } from "@mui/material";
import { getSystemsList } from "../../../../../utils/searchHelps";
import SearchHelpDialog from "../../../../common/search-helps";
import { TrashIcon } from "../../../../common/icons";

const getErrorFromValidationErrors = (row, columnId) => {
    return row && row[`error_${columnId}`];
};

const landscapesSelectorOptions = [
    {
        id: "Dev",
    },
    {
        id: "Test",
    },
    {
        id: "Prod",
    },
];

const searchHelpColumns = {
    systemId: [
        { id: "id", title: "Система" },
        { id: "description", title: "Описание системы" },
    ],
};

const GroupFormSystemsTable = ({
    systems,
    addSystem,
    deleteSystem,
    deleteSystemMass,
    editSystem,
    disabled,
    filterData,
    setFilterData,
    searchString,
    setSearchString,
    getDialogFilters,
    errorIndex,
    setErrorScroll,
}) => {
    const [selectedIds, setSelectedIds] = useState([]);
    const [rowAction, setRowAction] = useState(null);

    const onChangeCell = useCallback(
        (row, column) => (e) => {
            const value = e.target.value;
            editSystem(row, column.id, value);
        },
        [editSystem]
    );

    const getSearchHelpData = useCallback(
        (params, signal) => {
            return getSystemsList(params, signal);
        },
        [rowAction]
    );

    const onOpenSearchHelp = useCallback(
        (row, column) => () => {
            setRowAction({
                type: "search-help",
                row: row,
                field: column.id,
            });
        },
        []
    );
    const columns = useMemo(
        () => [
            {
                id: "select",
                type: "select",
            },
            {
                id: "systemId",
                title: "Система",
                type: "search-help",
                filterable: true,
            },
            {
                id: "description",
                title: "Описание",
                type: "text",
                width: "40%",
            },
            {
                id: "landscape",
                title: "Ландшафт",
                type: "selectInput",
                filterable: true,
            },
            {
                id: "isDefault",
                title: "Система по умолчанию",
                type: "boolean",
            },
            {
                id: "actions",
                type: "actions",
                actions: [
                    {
                        id: "delete",
                        disabled,
                        IconComponent: TrashIcon,
                        onClick: (_, row) => deleteSystem(row.id),
                    },
                ],
            },
        ],
        []
    );
    const createRowCellContent = useCallback(
        (row, column, selected, onSelect) => {
            const error = getErrorFromValidationErrors(row, column.id);
            let hasError = error ? String(row[column.id])?.toLowerCase() === error.value?.toLowerCase() && error["type"] === "validation" : false;

            if (error && error.code === "duplicate") {
                hasError = true;
            }

            switch (column.type) {
                case "search-help": {
                    return (
                        <SearchHelpInput
                            disabled={disabled}
                            error={hasError}
                            errorMessage={error && error.message}
                            warning={error ? row[column.id]?.toLowerCase() === error.value?.toLowerCase() && error["type"] === "integration_warning" : false}
                            warningMessage={error && error.message}
                            showFormHelpers={false}
                            showInnerHelpers
                            value={row[column.id]}
                            fullWidth
                            onOpenSearchHelp={onOpenSearchHelp(row, column)}
                            onChange={onChangeCell(row, column)}
                        />
                    );
                }

                case "selectInput": {
                    return (
                        <SelectInput
                            disabled={false}
                            error={hasError}
                            errorMessage={error && error.message}
                            value={row[column.id]}
                            onChange={(e) => editSystem(row, column.id, e.target.value)}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            sx={{
                                width: "90px",
                                height: "39px",
                                "&  .MuiOutlinedInput-notchedOutline": {
                                    border: "0px",
                                },
                                "&.Mui-error": {
                                    border: "1px solid red",
                                },
                            }}
                        >
                            {landscapesSelectorOptions.map((item) => (
                                <MenuItem
                                    value={item.id}
                                    key={item.id}
                                    sx={{
                                        minHeight: "initial",
                                        height: "36px",
                                    }}
                                >
                                    {item.id}
                                </MenuItem>
                            ))}
                        </SelectInput>
                    );
                }

                case "boolean":
                    return (
                        <Tooltip title={hasError ? error.message : ""} arrow>
                            <Checkbox
                                checked={row[column.id]}
                                onChange={() => editSystem(row, column.id, !row[column.id])}
                                sx={{
                                    "&.Mui-checked": {
                                        color: hasError ? "red" : "gray",
                                    },
                                    "& .MuiSvgIcon-root": {
                                        fill: hasError ? "red" : "inherit",
                                    },
                                }}
                            />
                        </Tooltip>
                    );

                default:
                    return createRowCellContentInner(row, column, selected, onSelect);
            }
        },
        [disabled, editSystem, onChangeCell, onOpenSearchHelp]
    );

    const toolbarActions = useMemo(() => {
        return [
            {
                id: "mass-delete",
                title: <FormattedMessage id="common.btn-delete" />,
                callback: () => {
                    deleteSystemMass(selectedIds);
                    setSelectedIds([]);
                },
                position: "end",
                variant: "contained",
            },
            {
                id: "search",
                type: "search",
                position: "end",
                value: searchString,
                callback: setSearchString,
            },
            {
                id: "add",
                title: <FormattedMessage id="group-form-page-systems-table" />,
                callback: addSystem,
                position: "begin",
            },
        ];
    }, [addSystem, disabled, searchString, setSearchString, selectedIds, deleteSystemMass]);

    const handleSelectRow = useCallback(
        (row, value) => {
            if (value) {
                setSelectedIds([...selectedIds, row.id]);
                return;
            }

            setSelectedIds(selectedIds.filter((id) => id !== row.id));
        },
        [selectedIds]
    );

    const handleSelectAll = useCallback(
        (value) => {
            if (!value) {
                setSelectedIds([]);
                return;
            }

            setSelectedIds(systems.map((system) => system.id));
        },
        [systems]
    );

    return (
        <>
            <EntityFormViewTable
                className=" "
                virtualize={false}
                rows={systems}
                columns={columns}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                onSelectRow={handleSelectRow}
                onSelectAll={handleSelectAll}
                createRowCellContent={createRowCellContent}
                filterData={filterData}
                setFilterData={setFilterData}
                getFilters={getDialogFilters}
                errorIndex={errorIndex}
                setErrorScroll={setErrorScroll}
            />
            {rowAction?.type === "search-help" && (
                <SearchHelpDialog
                    defaultSearchString={rowAction.row[rowAction.field] || ""}
                    open={rowAction && rowAction.type === "search-help"}
                    readRows={getSearchHelpData}
                    columns={searchHelpColumns[rowAction.field]}
                    selectOneRow={true}
                    onSubmit={(selectedRow) => {
                        editSystem(rowAction.row, rowAction.field, selectedRow.id);
                        setRowAction(null);
                    }}
                    onCancel={() => {
                        setRowAction(null);
                    }}
                />
            )}
        </>
    );
};
GroupFormSystemsTable.propTypes = {
    systems: PropTypes.arrayOf(PropTypes.object),
    addSystem: PropTypes.func,
    deleteSystem: PropTypes.func,
    deleteSystemMass: PropTypes.func,
    editSystem: PropTypes.func,
    disabled: PropTypes.bool,
    validationErrors: PropTypes.object,
    filterData: PropTypes.arrayOf(PropTypes.object),
    setFilterData: PropTypes.func,
    searchString: PropTypes.string,
    setSearchString: PropTypes.func,
    getDialogFilters: PropTypes.func,
    errorIndex: PropTypes.number,
    setErrorScroll: PropTypes.func,
};
export default GroupFormSystemsTable;
