import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import store from "./store.ts";
import App from "./components/app";
import SODIntlProvider from "./components/common/sod-intl-provider";
import LocalizationProvider from "./components/common/localization-provider";

const root = ReactDOMClient.createRoot(document.getElementById("root"));

root.render((
    <Provider store={store}>
        <SODIntlProvider>
            <LocalizationProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </LocalizationProvider>
        </SODIntlProvider>
    </Provider>
))
