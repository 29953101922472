import React from "react"
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";


import {
    fetchCustomRoles,
    fetchCustomRolesColumns,
    setSelectedIds as setSelectedIdsAction,
    setSearchString as setSearchStringAction,
    setPageNumber as setPageNumberAction,
    setLimitNumber as setLimitNumberAction,
    setSortData as setSortDataAction,
    setFilterData as setFilterDataAction,
    setError as setErrorAction,
} from "../../../../reducers/reports-modeling/customRolesSlice";

import useFilters from "../../../common/hooks/useFilters";
import CommonTable from "../../../common/table";
import useReduxTable from "../../../common/hooks/useReduxTable";
import { ProgressDialog, ErrorsDialog } from "../../../common/dialogs";



const getCellValue = (row, column) => {
    switch (column.id) {
        case "roleName":
            return row.role;

        case "customRoleId":
            return row.id;

        case "updTime":
            return row.updatedTime;

        case "updUserName":
            return row.user;

        case "isComplexRole":
            return row.isComplex ? "Групповая" : "Одиночная";

        default:
            return row[column.id];
    }
};

const CustomRoleListPage = () => {

    const intl = useIntl();
    const navigate = useNavigate();
    const {
        rows, columns,
        total, busy,
        selectedIds,
        searchString, setSearchString,
        pageNumber, setPageNumber,
        limitNumber, setLimitNumber,
        filterData, setFilterData,
        sortData, setSortData,
        error, setError
    } = useReduxTable(
        (state) => state.reportsModeling.customRoles.customRoles, fetchCustomRoles,
        (state) => state.reportsModeling.customRoles.columns, fetchCustomRolesColumns,
        (state) => state.reportsModeling.customRoles.selectedIds, setSelectedIdsAction,
        (state) => state.reportsModeling.customRoles.searchString, setSearchStringAction,
        (state) => state.reportsModeling.customRoles.pageNumber, setPageNumberAction,
        (state) => state.reportsModeling.customRoles.limitNumber, setLimitNumberAction,
        (state) => state.reportsModeling.customRoles.sortData, setSortDataAction,
        (state) => state.reportsModeling.customRoles.filterData, setFilterDataAction,
        (state) => state.reportsModeling.customRoles.error, setErrorAction,
        (state) => state.reportsModeling.customRoles.customRolesTotal,
        (state) => state.reportsModeling.customRoles.busy,
    );

    const busyType = useSelector((state) => state.reportsModeling.customRoles.busyType);

    const getFilters = useFilters("custom-roles");

    const handleAddRoleClick = () => {
        navigate("form")
    };

    const handleRowClick = (role) => {

        const roleName = encodeURIComponent(role.role);

        if (!role.version) {
            navigate(`form/${roleName}/${role.systemId}`);

            return
        }

        navigate(`form/${roleName}/${role.systemId}/${role.version}`);
    };

    const getTableRowId = (row) => row.id;

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "custom-role-list-page.toolbar.text.title" }),
            type: "text",
            position: "begin"
        },
        {
            id: "add",
            title: intl.formatMessage({ id: "custom-role-list-page.toolbar.btn-create-role" }),
            position: "end",
            callback:handleAddRoleClick
        }
    ];

    return (
        <>
        <CommonTable
                rows={rows}
                columns={columns}
                toolbarActions={toolbarActions}
                selectedIds={selectedIds}
                getRowId={getTableRowId}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
                limitNumber={limitNumber}
                setLimitNumber={setLimitNumber}
                total={total}
                onRowClick={handleRowClick}
                filterData={filterData}
                setFilterData={setFilterData}
                sortData={sortData}
                setSortData={setSortData}
                searchString={searchString}
                setSearchString={setSearchString}
                getCellValue={getCellValue}
                getFilters={getFilters}
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => setError(null)}
            />
        </>
    )
}

export default CustomRoleListPage;