var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { CircularProgress } from "@mui/material";
import { CommonDialog, CommonDialogContent, CommonDialogTitle } from "../../../common/dialogs";
import AddRoleToRequestDialogTable from "./add-role-to-request-dialog-table";
import AddRoleToRequestDialogActions from "./add-role-to-request-dialog-actions";
import { systemGroupsService } from "../../../../services/system-groups-service";
import { useDispatch } from "react-redux";
import { addRolesToRequest } from "../../../../reducers/administration/requestSlice";
import { requestsService } from "../../../../services/requests-service";
import useScrollPagination from "../../../common/hooks/useScrollPagination";
import "./styles.less";
const getGroupSystems = () => __awaiter(void 0, void 0, void 0, function* () {
    const { groups } = yield systemGroupsService.getGroups();
    return groups;
});
const AddRoleToRequestDialog = ({ open, onClose, groupId }) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "add-role-to-request-dialog.title" });
    const [currentSystem, setCurrentSystem] = useState("");
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [reloadTrigger, setReloadTrigger] = useState(0);
    useEffect(() => {
        if (groupId.length > 0) {
            setCurrentSystem(groupId);
        }
    }, [groupId]);
    const dispatch = useDispatch();
    const getData = useCallback((page, signal) => {
        if (currentSystem.length > 0) {
            const params = {
                page,
                limit: 10,
                search: searchString,
            };
            return requestsService.getGroupSystemRoles(params, signal, currentSystem);
        }
    }, [searchString, currentSystem, reloadTrigger]);
    const { items, setItems, busy, handleScroll } = useScrollPagination({ getData });
    useEffect(() => {
        if (open) {
            setItems([]);
            setReloadTrigger((prev) => prev + 1);
        }
    }, [open]);
    const handleSaveClick = () => {
        const rolesForAdd = items.filter((role) => selectedRoles.includes(role === null || role === void 0 ? void 0 : role.id));
        dispatch(addRolesToRequest({ roles: rolesForAdd, groupId: currentSystem }));
        onClose();
    };
    return (React.createElement(CommonDialog, { className: "add-role-to-request-dialog", open: open, maxWidth: "lg", fullWidth: true, sx: { height: "80vh" } },
        React.createElement(CommonDialogTitle, { title: title, onClose: () => {
                onClose();
                setSelectedRoles([]);
            } }),
        React.createElement(CommonDialogContent, { onScrollCapture: handleScroll },
            busy && React.createElement(CircularProgress, { size: 32 }),
            React.createElement(AddRoleToRequestDialogTable, { roles: items, currentSystem: currentSystem, setCurrentSystem: setCurrentSystem, isSelectGroupDisabled: groupId.length > 0, getGroupSystems: getGroupSystems, selectedRoles: selectedRoles, setSelectedRoles: setSelectedRoles, searchString: searchString, setSearchString: setSearchString })),
        React.createElement(AddRoleToRequestDialogActions, { onSave: handleSaveClick, onCancel: () => {
                onClose();
                setSelectedRoles([]);
            } })));
};
export default AddRoleToRequestDialog;
