import React, { useCallback, useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { getPermissionFieldValuesNarrowedSearchHelp } from "../../../utils/searchHelps";

import { createRowCellContentInner } from "../../common/table";
import { SearchHelpInput } from "../../common/form-controls";
import { TrashIcon } from "../../common/icons";
import SearchHelpDialog from "../../common/search-helps";

import ReportModelingEditDialogTable from "./report-modeling-edit-dialog-table.jsx";
import { fieldPropTypes, valuesPropTypes } from "./prop-types";

import "./styles.less";

const getRowId = row => row.rowId;

const ReportModelingEditDialogTableRange = ({
    systemId,
    field,
    rows,
    onAddRow,
    onDeleteRow,
    onChangeCell,
    disabled=false
}) => {
    const [rowAction, setRowAction] = useState(null);
    const intl = useIntl();

    const columns = [
        {
            id: "from",
            title: intl.formatMessage({ id: "report-modeling-edit-dialog.range-table.columns.from" }),
            type: "search-help",
        },
        {
            id: "to",
            title: intl.formatMessage({ id: "report-modeling-edit-dialog.range-table.columns.to" }),
            type: "search-help",
        },
        {
            id: "actions",
            type: "actions",
            actions: [
                {
                    id: "delete",
                    IconComponent: TrashIcon,
                    onClick: (_, row) => onDeleteRow(row.rowId),
                    disabled: disabled
                }
            ]
        }
    ];

    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        switch (column.type) {
            case "search-help": {
                let shDelay = 0;

                if (field.id === "TCD") {
                    shDelay = 900
                }
                return (
                    <SearchHelpInput
                        fullWidth
                        value={row[column.id]}
                        disabled={disabled}
                        onChange={(e) => onChangeCell(row.rowId, column.id, 
                            column.id === "from" ? {valueFrom: e.target.value, valueTo: row.to} : {valueFrom: row.from, valueTo: e.target.value})
                        }
                        delay={shDelay}
                        onOpenSearchHelp={() => setRowAction({
                            type: "search-help",
                            row: row,
                            field: column.id
                        })}
                    />
                )
            }
            default:
                return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
        }
    };

    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "report-modeling-edit-dialog.range-table.toolbar.title" }),
            type: "text",
            position: "begin"
        },
        {
            id: "add",
            title: intl.formatMessage({ id: "report-modeling-edit-dialog.range-table.toolbar.add" }),
            callback: onAddRow,
            position: "end",
            disabled: disabled
        }
    ];

    const searchHelpColumns = {
        "from": [
            {
                id: "valueFrom",
                title: intl.formatMessage({ id: "report-modeling-edit-dialog.range-table.columns.from" })
            },
            {
                id: "valueTo",
                title: intl.formatMessage({ id: "report-modeling-edit-dialog.range-table.columns.to" })
            },
            {
                id: "text",
                title: intl.formatMessage({ id: "report-modeling-edit-dialog.range-table.columns.text" })
            }
        ],
        "to": [
            {
                id: "valueFrom",
                title: intl.formatMessage({ id: "report-modeling-edit-dialog.range-table.columns.from" })
            },
            {
                id: "valueTo",
                title: intl.formatMessage({ id: "report-modeling-edit-dialog.range-table.columns.to" })
            },
            {
                id: "text",
                title: intl.formatMessage({ id: "report-modeling-edit-dialog.range-table.columns.text" })
            }
        ],
    };

    const getSearchHelpData = useCallback((params, signal) => {
        return getPermissionFieldValuesNarrowedSearchHelp(params, signal, systemId, field.id);
    }, [systemId, field]);

    const handleSubmitSearchHelp = (selectedRow) => {
        onChangeCell(rowAction.row.rowId, rowAction.field, selectedRow);
        setRowAction(null);
    };

    const searchHelpDialogOpen = rowAction?.type === "search-help";

    return (
        <>
            <ReportModelingEditDialogTable
                className="report-modeling-edit-dialog-table-range"
                columns={columns}
                rows={rows}
                createRowCellContent={createRowCellContent}
                toolbarActions={toolbarActions}
                getRowId={getRowId}
            />

            {searchHelpDialogOpen && (
                <SearchHelpDialog
                    defaultSearchString={rowAction.row[rowAction.field] || ""}
                    open={searchHelpDialogOpen}
                    readRows={getSearchHelpData}
                    columns={searchHelpColumns[rowAction.field]}
                    selectOneRow={true}
                    onSubmit={handleSubmitSearchHelp}
                    onCancel={() => setRowAction(null)}
                />
            )}
        </>
    );
};

ReportModelingEditDialogTableRange.propTypes = {
    systemId: PropTypes.string,
    field: fieldPropTypes,
    rows: PropTypes.arrayOf(valuesPropTypes),
    onAddRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
    onChangeCell: PropTypes.func,
    disabled: PropTypes.bool
};

export default ReportModelingEditDialogTableRange;