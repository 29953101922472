var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { selectModelingSelectedRole, removeGroupRoleChildFromModeling, addGroupRoleChildToModeling, selectChangeRequestGroupId, } from "../../../../reducers/administration/requestSlice";
import GroupRoleModelingBox from "../../../reports-modeling/group-role-modeling-box";
import { useDispatch } from "react-redux";
import { integrationService } from "../../../../services/integration-service";
//TODO: добавить пропс disabled
const RequestGroupRoleModelingBox = ({ disabled = false }) => {
    const dispatch = useDispatch();
    const modelingRole = useSelector(selectModelingSelectedRole);
    const groupId = useSelector(selectChangeRequestGroupId);
    const [selectedRole, setSelectedRole] = useState("");
    const isDeleteDisabled = disabled ? true : !selectedRole.length;
    const handleRowClick = (row, setActiveRow) => {
        setActiveRow(row.id);
        setSelectedRole(row.role);
    };
    const handleAddChildRole = (newChildRole) => {
        dispatch(addGroupRoleChildToModeling({
            newChildRole,
        }));
    };
    const handleRemoveChildRoles = () => {
        dispatch(removeGroupRoleChildFromModeling({
            roleIdToRemove: selectedRole,
        }));
    };
    const getRoles = (params, signal) => __awaiter(void 0, void 0, void 0, function* () {
        if (params && params["filters"]) {
            params["filters"].push({ field: "groupId", sign: "EQ", value: groupId });
        }
        return yield integrationService.getRoles(params, signal);
    });
    return (React.createElement(GroupRoleModelingBox, { roles: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.children, selectedRole: modelingRole, onAddChildRole: handleAddChildRole, onRemoveChildRoles: handleRemoveChildRoles, handleRowClick: handleRowClick, handleExpandChildrenRoles: () => { }, isAddDisabled: disabled, isDeleteDisabled: isDeleteDisabled, getRoles: getRoles }));
};
export default RequestGroupRoleModelingBox;
