import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";


import { createCustomRole, editCustomRole, deleteCustomRole, clearCurrentCustomRole } from "../../../../reducers/reports-modeling/customRolesSlice";
import { fetchCustomRoleDetailed, setInitialCurrentCustomRole, setSubmit } from "../../../../reducers/reports-modeling/customRolesSlice";
import { setError, setBusy } from "../../../../reducers/reports-modeling/customRolesSlice";


import { DeleteDialog, ProgressDialog } from "../../../common/dialogs";
import { ErrorsDialog, SubmitDialog } from "../../../common/dialogs";
import { ADD_MODE, EDIT_MODE, EntityFormPageContent, getCurrentMode } from "../../../common/entity-form";

import CustomRoleFormPageHeader from "./custom-role-form-page-header.jsx";
import CustomRoleFormPageRole from "./custom-role-form-page-role.jsx";

import "./style.less";


const CustomRoleFormPage = () => {

    const { customRole: roleId, systemId, version } = useParams();

    const dispatch = useDispatch();
    const intl = useIntl();
    const navigate = useNavigate();

    const busy = useSelector(state => state.reportsModeling.customRoles.busy);
    const busyType = useSelector((state) => state.reportsModeling.customRoles.busyType);
    const error = useSelector(state => state.reportsModeling.customRoles.error);
    const submit = useSelector(state => state.reportsModeling.customRoles.submit);
    const role = useSelector(state => state.reportsModeling.customRoles.currentCustomRole);
    const roleEditable = useSelector(state => state.reportsModeling.customRoles.currentCustomRoleEditable);
    const roleDeleted = useSelector(state => state.reportsModeling.customRoles.currentCustomRoleDeleted);
    const validationErrors = useSelector(state => state.reportsModeling.customRoles.validationErrors);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [form, setForm] = useState(null);

    const [formErrors, setFormErrors] = useState(null)

    const mode = getCurrentMode(!roleId, roleEditable);

    const [errorScroll, setErrorScroll] = useState()

    useEffect(() => {
        if (!validationErrors) return;

        const formErrosTmp = validationErrors.reduce((accumulator, error) => {
            accumulator[error.field] = error;

            return accumulator
        }, {});

        setFormErrors(formErrosTmp)

    }, [validationErrors, form])


    useEffect(() => {
        if (roleId) {
            dispatch(fetchCustomRoleDetailed({ roleId, systemId, version }));
        } else {
            dispatch(setInitialCurrentCustomRole());
        }

        return (() => {
            dispatch(clearCurrentCustomRole());
        });
    }, [roleId, systemId, dispatch]);

    useEffect(() => {
        if (role) {
            setForm({
                id: role?.role || "",
                description: role?.description || "",
                systemId: role?.systemId || "",
                isComplex: role?.isComplex === undefined ? false : role?.isComplex,
                children: role?.children || [],
                orgLevels: role?.orgLevels || {},
                menu: role?.menu || [],
                permissions: role?.permissions || [],
                tree: role?.tree || [],
                profile: role?.profile || role.role || "",
                orgLevelsDelta: role.orgLevelsDelta || [],
                delta: role.delta || [],
            });
        }
    }, [role]);

    useEffect(() => {
        if (!submit) return;

        dispatch(setSubmit(false));

        if (mode === ADD_MODE || roleDeleted) {
            handleBackClick();
        }
    }, [submit, dispatch, roleDeleted, handleBackClick, mode]);


    const handleSaveRole = (checkWarnings = true) => async () => {
        const formRole = {
            id: form.id?.trim(),
            systemId: form.systemId,
            systemType: form.systemType,
            description: form.description,
            isComplex: form.isComplex,
            children: form.children,
            orgLevels: form.orgLevels,
            menu: form.menu,
            permissions: form.permissions,
            tree: form.tree,
            profile: form.profile,
            delta: form.delta,
            orgLevelsDelta: form.orgLevelsDelta,
        };

        setForm({
            ...form,
            id: formRole.id,
        });

        if (mode === EDIT_MODE) {
            dispatch(editCustomRole({ editedRole: formRole, warningCheck: checkWarnings, version: version }));
        } else {
            dispatch(createCustomRole({ newRole: formRole, warningCheck: checkWarnings }));
        }
    };

    const backPath = useMemo(() => (mode === ADD_MODE
        ? ".."
        : "../../.."
    ), [mode]);

    const handleBackClick = useCallback(() => {
        navigate(backPath, { relative: "path" });
    }, [navigate, backPath]);

    const handleDeleteClick = () => {
        setShowDeleteDialog(true);
    };

    const handleDeleteCancelClick = () => {
        setShowDeleteDialog(false);
    };

    const handleDeleteConfirmClick = () => {
        dispatch(deleteCustomRole({role, version}));
    };

    const handleEditClick = () => {
        dispatch(fetchCustomRoleDetailed({ roleId, systemId, version }));
    };

    const handleSetBusy = (busy) => {
        dispatch(setBusy(busy))
    }

    if (!role && error) return <Navigate to={backPath} relative="path" />;

    return (
        <>
            {form && (
                <EntityFormPageContent>
                    <CustomRoleFormPageHeader
                        mode={mode}
                        customRoleId={roleId}
                        onBackClick={handleBackClick}
                        onDeleteClick={handleDeleteClick}
                        onSaveClick={handleSaveRole(true)}
                        onEditClick={handleEditClick}
                    />
                    <CustomRoleFormPageRole
                        mode={mode}
                        form={form}
                        setForm={setForm}
                        validationErrors={formErrors}
                        setValidationErrors={setFormErrors}
                        errorScroll={errorScroll}
                        setErrorScroll={setErrorScroll}
                        setBusy={handleSetBusy}
                    />
                </EntityFormPageContent>
            )}
            <DeleteDialog
                open={showDeleteDialog}
                title={intl.formatMessage({ id: "custom-role-form-page.delete-dialog.title" })}
                text={intl.formatMessage(
                    { id: "custom-role-form-page.delete-custom-role.text" },
                    { name: roleId }
                )}
                onCancelClick={handleDeleteCancelClick}
                onDeleteClick={handleDeleteConfirmClick}
            />
            <SubmitDialog
                open={error && error.type === "warning" && error.code === "no_integr_objects"}
                title={error && error.title}
                message={error && error.detail}
                onCancelClick={() => { dispatch(setError(null)) }}
                buttons={[{
                    text: intl.formatMessage({ id: "common.submit-dialog.btn-close" }),
                    type: "close",
                    onClick: () => { dispatch(setError(null)) }
                }, {
                    text: intl.formatMessage({ id: "common.submit-dialog.btn-apply" }),
                    type: "apply",
                    onClick: () => {
                        handleSaveRole(false)();
                        dispatch(setError(null))
                    }
                }]}
                disableRestoreFocus
            />

            <ErrorsDialog
                error={error}
                open={error?.type === "error"}
                onClose={() => { dispatch(setError(null)) }}
                disableRestoreFocus
            />

            <ProgressDialog
                open={busy}
                busyType={busyType}
            />
        </>
    )
}

export default CustomRoleFormPage;