import { nanoid } from "@reduxjs/toolkit";
import { calcRoleTreeItemStatus, calcRoleTreeItemTrafficLightStatus, sortTreeChildren } from "../utils/reports-modeling-utils";
import { createTreeNode } from "../utils/tree-table";

export function parseRolePermissionsTree(tree) {
    if (tree) {
        const sortedTree = sortTreeChildren(tree);

        return sortedTree.map(item => createTreeNode(
            item,
            0,
            null,
            parseRolePermissionsTreeNode,
            parseRolePermissionsTreeItem
        ));
    }
}

function parseRolePermissionsTreeItem(item) {
    let parsedItem = {
        type: item.type,
        id: item.id,
        description: item.description,
        active: item.active
    };

    switch (item.type) {
        case "field":
            parsedItem.values = item.values?.map(value => ({
                ...value,
                rowId: nanoid()
            }));
            parsedItem.orgLevel = item.orglevel;
            parsedItem.status = item.status;
            parsedItem.valueType = item.valueType;
            break;

        case "class":
            parsedItem.children = sortTreeChildren(item.children);
            break;

        case "permission":
            parsedItem.children = item.children?.map(child => ({
                ...child,
                active: item.active
            }));
            break;

        default:
            parsedItem.children = item.children;
            break;
    }

    return parsedItem;
}

function parseRolePermissionsTreeNode(node, orgLevels) {
    const parsedNode = {
        ...node,
        trafficLightStatus: calcRoleTreeItemTrafficLightStatus(node, orgLevels)
    };

    if (node.type !== "field") {
        parsedNode.status = calcRoleTreeItemStatus(node);
    }

    return parsedNode;
}

export function parseRoleMenu(roleMenu) {
    const root = {
        nodeText: "Меню роли",
        isRoot: true,
        isFolder: true,
        children: roleMenu
    };

    const rootNode = createTreeNode(
        root,
        0,
        null,
        null,
        (item) => parseRoleMenuItem(item)
    );

    return [rootNode];
}

function parseRoleMenuItem(roleMenuItem) {
    const parsedItem = roleMenuItem.isRoot
        ? roleMenuItem
        : {
            reportName: roleMenuItem.report_name,
            reportType: roleMenuItem.report_type,
            isFolder: roleMenuItem.is_folder,
            nodeText: roleMenuItem.node_text,
            description: roleMenuItem.description,
        };

    if (parsedItem.isFolder) {
        parsedItem.children = roleMenuItem.children ?? [];
    }

    return parsedItem;
}


export function parseRoleOrgLevels(orgLevels) {
    if (orgLevels) {
        const parsedOrgLevels = orgLevels.reduce((acc, orgLevel) => {
            acc[orgLevel.id] = parseRoleOrgLevel(orgLevel);
            return acc;
        }, {});

        return parsedOrgLevels;
    }
}

function parseRoleOrgLevel(orgLevel) {
    const values = orgLevel.values.map(value => ({
        ...value,
        rowId: nanoid()
    }));

    return {
        ...orgLevel,
        values
    };
}