import React from "react";
import { CommonDialog, CommonDialogContent, CommonDialogTitle } from "../../../common/dialogs";
import { useIntl, FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import { TextInput } from "../../../common/form-controls";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
const ApproveRequestWithConflictsDialog = ({ onClose, open, approveMessage, setApproveMessage, onSave }) => {
    const intl = useIntl();
    return (React.createElement(CommonDialog, { open: open },
        React.createElement(CommonDialogTitle, { withoutCloseIcon: false, onClose: onClose, title: intl.formatMessage({ id: "approve-request-with-conflicts-dialog.title" }) }),
        React.createElement(CommonDialogContent, null,
            React.createElement(Typography, { sx: { textAlign: "center", marginTop: "10px", marginBottom: "10px" } },
                React.createElement(FormattedMessage, { id: "approve-request-with-conflicts-dialog.warning-text" })),
            React.createElement(TextInput, { multiline: true, rows: 3, value: approveMessage, onChange: (event) => setApproveMessage(event.target.value) }),
            React.createElement(Box, { gap: 2, sx: { marginTop: "15px" }, justifyContent: "flex-end", display: "flex" },
                React.createElement(Button, { disabled: false, variant: "outlined", onClick: onClose, sx: {
                        color: "var(--font-4)",
                        borderColor: "var(--font-4)",
                    } },
                    React.createElement(FormattedMessage, { id: "common.btn-cancel" })),
                React.createElement(Button, { disabled: !(approveMessage.length > 0), variant: "contained", sx: {
                        color: "var(--bg-2)",
                        backgroundColor: "var(--font-2)",
                        "&:hover": {
                            backgroundColor: "var(--font-2)",
                        },
                    }, onClick: onSave },
                    React.createElement(FormattedMessage, { id: "approve-request-with-conflicts-dialog.btn-continue" }))))));
};
export default ApproveRequestWithConflictsDialog;
