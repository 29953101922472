import { integrationEntityMap, integrationReverseEntityMap } from "../utils/integration-utils";
import { matrixService } from "./matrix-service";

import SodService from "./sod-service";

class IntegrationService extends SodService {

    async downloadIntegrationData() {
        return await matrixService.downloadFileReq("/core/integration/download-integr-data")
    }

    async runIntegration1CDataUpload(item) {
        if (!item.authorityDetail && !item.metadataObject && !item.role1C && !item.employee1C) {
            return;
        }

        const parsedItem = this.parse1CIntegrationDataForServer(item)

        const data = await this.post('/core/integration/upload', parsedItem);

        return data;
    }

    async runIntegrationSAPDataUpload(item) {
        if (!item.permissionDetail && !item.employee && !item.role && !item.profile) {
            return;
        }

        const parsedItem = this.parseSAPIntegrationDataForServer(item)

        const data = await this.post('/core/integration/upload', parsedItem);

        return data;
    }

    async getEmployees(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/employee", config);

        const rows = data && data.rows.map(item => this.parseEmployee(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getIntegrationItems(params) {
        const config = this.generateConfigForGetEntities(params);

        const data = await this.get("/core/integration/items", config);
        
        const items = data && data.rows.map(item => this.parseIntegrationItem(item));
        const total = data && data.total;

        return { items, total }
    }

    async refreshIntegrationItem(systemId){
        const config = this.generateConfigForGetEntities({
            page: 1,
            limit: 1,
            search: systemId,
            filters: [],
            sorters: []
        });

        const data = await this.get("/core/integration/items", config);
        const items = data && data.rows.map(item => this.parseIntegrationItem(item));

        const currentSystem = items[0]

        return { currentSystem }
    }

    async getProfiles(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/profile-for-crit", config);

        const rows = data && data.rows.map(item => this.parseProfile(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getProfileDetailed(profileId, systemId, signal) {
        const config = this.generateConfigForGetEntities({
            profile: profileId,
            system_id: systemId
        }, signal);

        const profile = await this.get("/core/integration/profile/detailed", config);
        return profile && this.parseProfileDetailed(profile);
    }

    async getRoles(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/role", config);

        const rows = data && data.rows.map(item => this.parseRole(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getModelingRoles(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        // TODO: добавить profile и is_complex
        const data = await this.get("/core/integration/simulation-role", config);

        const rows = data && data.rows.map(item => this.parseRole(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getRoleDetailed(roleId, systemId, signal) {
        const config = this.generateConfigForGetEntities({
            role: roleId,
            system_id: systemId
        }, signal);

        const role = await this.get("/core/integration/role/detailed", config);
        return role && this.parseRoleDetailed(role);
    }

    async getTransactions(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/transaction", config);

        const rows = data && data.rows.map(item => this.parseTransaction(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getObjectTypes(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration-1c/object-type", config);

        const rows = data && data.rows.map(item => this.parseObjectType1C(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getObjectNames(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration-1c/metadata-object", config);

        const rows = data && data.rows.map(item => this.parseObjectName1C(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getFields1C(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration-1c/auth-type", config);

        const rows = data && data.rows.map(item => this.parseField1C(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getValues1C(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration-1c/object-type-right", config);

        const rows = data && data.rows.map(item => this.parseValue1C(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getTransactionDetailed(transactionId, systemId, signal, isGroup) {
        const config = this.generateConfigForGetEntities({
            transaction: transactionId,
            system_id: systemId,
            is_group: isGroup
        }, signal);

        const transaction = await this.get("/core/integration/transaction/detailed", config);
        return transaction && this.parseTransactionDetailed(transaction);
    }

    async getObjectTypeDetailed(type, systemId, signal, isGroup) {
        const config = this.generateConfigForGetEntities({
            type: type,
            system_id: systemId,
            is_group: isGroup
        }, signal);

        const objectType = await this.get("/core/integration-1c/object-type/detailed", config);
        return objectType && this.parseObjectTypeDetailed(objectType);
    }

    async getObjectNameDetailed(name, systemId, signal, isGroup) {
        const config = this.generateConfigForGetEntities({
            name: name,
            system_id: systemId,
            is_group: isGroup
        }, signal);

        const objectName = await this.get("/core/integration-1c/metadata-object/detailed", config);
        return objectName && this.parseObjectNameDetailed(objectName);
    }

    async getField1CDetailed(field1C, systemId, signal, isGroup) {
        const config = this.generateConfigForGetEntities({
            auth_type_name: field1C,
            system_id: systemId,
            is_group: isGroup
        }, signal);

        const field = await this.get("/core/integration-1c/auth-type/detailed", config);
        return field && this.parseField1CDetailed(field);
    }

    async getValue1CDetailed(value1C, systemId, signal, isGroup, objectType) {
        const config = this.generateConfigForGetEntities({
            right_name: value1C,
            object_type: objectType,
            system_id: systemId,
            is_group: isGroup
        }, signal);

        const value = await this.get("/core/integration-1c/object-type-right/detailed", config);
        return value && this.parseValue1CDetailed(value);
    }

    async getTransactionsTextsMass(system, transactions, isGroup) {
        const params = {
            filters: []
        };
    
        const result = {};
    
        if (!system || transactions.length === 0) return result;

        if (isGroup){
            params.filters.push({ field: "groupId", sign: "EQ", value: system });
        } else {
            params.filters.push({ field: "systemId", sign: "EQ", value: system });
        }

        params.filters.push({ field: "transaction", sign: "IN", value: transactions });
    
        const { rows } = await this.getTransactions(params);
    
        rows.forEach(row => {
            result[row.key] = row.text;
        });
    
        return result;
    }

    async getPermissions(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/permission", config);

        const rows = data && data.rows.map(item => this.parsePermission(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getManualSelectPermissions(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/manual-select-permission", config);

        const rows = data && data.rows.map(item => this.parsePermission(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getPermissionDetailed(permissionId, systemId, signal, isGroup) {
        const config = this.generateConfigForGetEntities({
            permission: permissionId,
            system_id: systemId,
            is_group: isGroup
        }, signal);

        const permission = await this.get("/core/integration/permission/detailed", config);
        return permission && this.parsePermissionDetailed(permission);
    }

    async getPermissionFields(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/permission-field", config);

        const rows = data && data.rows.map(item => this.parsePermissionField(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getPermissionFieldDetailed(fieldId, permissionId, systemId, signal, isGroup) {
        const config = this.generateConfigForGetEntities({
            fieldname: fieldId,
            permission: permissionId,
            system_id: systemId,
            is_group: isGroup
        }, signal);

        const permission = await this.get("/core/integration/permission-field/detailed", config);
        return permission && this.parsePermissionFieldDetailed(permission);
    }

    async getPermissionFieldValues(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/permission-value", config);

        const rows = data && data.rows.map(item => this.parsePermissionFieldValue(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getPermissionFieldNarrowedValues(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/permission-field-value", config);

        const rows = data && data.rows.map(item => this.parsePermissionFieldValueNarrowed(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getPermissionActvtDefaultValues(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/permission-actvt", config);

        const rows = data && data.rows.map(item => ({
            key: item.value,
            text: item.description || "",
            systemId: item.system_id,
        }));
        const total = data && data.total;

        return { 
            rows, total 
        }
    }

    async getPermissionFieldValueDetailed(value, fieldId, systemId, signal, isGroup) {
        const config = this.generateConfigForGetEntities({
            value: value,
            fieldname: fieldId,
            system_id: systemId,
            is_group: isGroup
        }, signal);

        const permission = await this.get("/core/integration/permission-value/detailed", config);
        return permission && this.parsePermissionFieldValueDetailed(permission);
    }

    async getOrgLevelValues(params, signal) {
        const config = this.generateConfigForGetEntities(params, signal);

        const data = await this.get("/core/integration/org-level", config);

        const rows = data && data.rows.map(item => this.parseOrgLevelValue(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getFunctionDefaultPermissions(params) {
        const config = this.generateConfigForGetEntities(params);

        const data = await this.get("/core/integration/permission-default-value", config);

        const rows = data && data.rows.map(item => this.parseFunctionDefaultPermission(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getPermissionDefaultValuesForSystem(params) {
        const config = this.generateConfigForGetEntities(params);

        const data = await this.get("/core/integration/permission-default-value-system", config);

        const rows = data && data.rows.map(item => this.parseFunctionDefaultPermission(item));
        const total = data && data.total;

        return {
            rows, total
        }
    }

    async getPfcgTreeForTcode(params){
        const config = this.generateConfigForGetEntities(params);

        const data = await this.get("/sod/reports/tcode-permissions", config);

        return data
    }

    async getScheduleItems(systemId, type, search = "") {
        const params = { system_id: systemId}

        if (type !== "all"){
            params["schedule_type"] = type
        }

        if (search){
            params["search"] = search
        }

        const rows = await this.get(`/core/integration/sync-schedules`, {params})

        return rows.map(row => this.parseIntegrationScheduleItem(row))
    }

    async getScheduleItemDetaield(taskId, type) {
        const data = await this.get(`/core/integration/sync-schedule/${taskId}`, {
            params:{
                schedule_type: type
            }
        })

        return this.parseIntegrationScheduleItemDetailed(data)
    }

    async deleteScheduleItem(taskId){
        await this.delete(`/core/integration/sync-schedule/${taskId}`)
        return taskId
    }

    async editScheduleItem(item) {
        const params = {
            schedule_type: item.type
        };

        const data = await this.put(
            `/core/integration/sync-schedule/${item.id}`,
            this.parseIntegrationScheduleItemToServer(item),
            { params }
        );

        return this.parseIntegrationScheduleItemDetailed(data)
    }

    async createScheduleItem(item) {
        const params = {
            schedule_type: item.type
        };

        const data = await this.post(
            "/core/integration/sync-schedule/0",
            this.parseIntegrationScheduleItemToServer(item),
            { params }
        );

        return this.parseIntegrationScheduleItemDetailed(data)
    }

    async uploadIntegrationMatrix(systemId, formData){
        const params = {
            params: {system_id: systemId}, 
            headers: { "Content-Type": "multipart/form-data" }
        }

        const data = await this.post('/sod/manual-integration/upload', formData, params)

        return data
    }

    async getIntegrationHistoryItems(systemId, type, systemType, search="") {
        const filters = [
            {field: "system_id", sign: "EQ", value: systemId},
        ]

        const filters1C = [
            "authority_detail",
            "metadata_object",
            "role_1c",
            "employee_1c"
        ]

        const filtersSAP = [
            "permission_details",
            "role",
            "profile",
            "employee"
        ]

        if (type === "common") {
            filters.push({
                field: "entity",
                sign: "IN",
                value: systemType === "SAP" ? filtersSAP : filters1C
            });
        } else if (type !== "all"){
            filters.push({
                field: "entity",
                sign: "EQ",
                value: type
            });
        }

        const params = {
            filters,
            search: search
        }

        const config = this.generateConfigForGetEntities(params);

        const { rows } = await this.get(`/core/integration/integration-log`, config)

        return rows.map(row => this.parseIntegrationHistoryItem(row))
    }

    async downloadIntegrationHistoryItem(row){
        let response;
        const currentInstance = this.getCurrentInstance(true);

        try {
            response = await currentInstance.get(`/core/integration/integration-log-upload`, {params: {
                entity: integrationReverseEntityMap[row.entity], 
                system: row.system, 
                startDate: row.startDate
            }, responseType: "blob"})
        } catch (error) {
            this.handleError(error)
        }

        const filename = response.headers.get("content-disposition").split('filename=')[1].split(';')[0];

        const data = response.data || null;

        return { data, filename };
    }

    async resetIntegrationStatus(systemId) {
        return this.post("core/systems/reset-integration-statuses", {
            system_id: systemId
        });
    }

    async getEmployeeRoles(employee, systemId, params, signal) {
        const paramsInner = params
            ? {
                ...params,
                filters: params.filters
                    ? [...params.filters]
                    : []
            }
            : {
                filters: []
            };

        if (systemId) {
            paramsInner.filters.push({
                field: "systemId", sign: "EQ", value: systemId
            })
        }

        if (!paramsInner.limit) {
            paramsInner.limit = 100;
        }

        if (!paramsInner.page) {
            paramsInner.page = 1;
        }

        return this.getRoles(paramsInner, signal);
    }

    parseEmployee(item) {
        return {
            employee: item.employee,
            firstName: item.firstname,
            fullName: item.fullname,
            lastName: item.lastname,
            systemId: item.system_id
        }
    }

    parseProfile(item) {
        return {
            ...item
        }
    }

    parseRole(item) {
        return {
            systemId: item.system_id,
            role: item.role,
            description: item.description,
            profile: item.profile ?? "",
            isComplex: item.is_complex_role ?? false,
            manual: item.manual
        }
    }

    parseIntegrationScheduleItemDetailed(item){
        const params = {}

        switch (item.schedule_type){
            case "repository_objects": {
                const employeeParams = item["params"]["employee"]
                params["employee"] = {
                    checked: employeeParams["checked"],
                    delta: employeeParams["delta"],
                }

                const roleParams = item["params"]["role"]
                params["role"] = {
                    checked: roleParams["checked"],
                    delta: roleParams["delta"],
                }

                const profileParams = item["params"]["profile"]
                params["profile"] = {
                    checked: profileParams["checked"],
                    delta: profileParams["delta"],
                }
                break;
            }
            case "permission_details": {
                const permissionDetailsParams = item["params"]["permission_details"]
                params["permissionDetails"] = {
                    delta: permissionDetailsParams["delta"]
                }
                break;
            }
        }

        const result = {
            id: item.id,
            name: item.task_name,
            type: item.schedule_type,
            firstLaunchDate: new Date(item.start_time),
            period: item.period_type,
            periodValue: item.period_value,
            customPeriod: null,
            params: params
        }

        if (["minutes", "hours", "weeks"].includes(item.period_type)){
            result["period"] = "custom"
            result["customPeriod"] = item.period_type
        }

        return result
    }

    parseIntegrationScheduleItem(item){
        return {
            id: item.id,
            name: item.task_name,
            startDate: item.start_time ? new Date(item.start_time) : null,
            periodType: item.period_type,
            periodValue: item.period_value,
            type: item.schedule_type,
        }
    }

    parseIntegrationScheduleItemToServer(item) {
        let params = {}

        if (item.type === "permission_details") {
            params = {
                permission_details: {
                    delta: item.params.permissionDetails.delta
                }
            }
        }

        if (item.type === "repository_objects") {
            params = {
                role: {
                    checked: item.params.role.checked,
                    delta: item.params.role.delta
                },
                profile: {
                    checked: item.params.profile.checked,
                    delta: item.params.profile.delta
                },
                employee: {
                    checked: item.params.employee.checked,
                    delta: item.params.employee.delta
                }
            }
        }

        return {
            task_name: item.name,
            type: item.type,
            params: params,
            start_time: item.firstLaunchDate,
            period_value: item.periodValue,
            system_id: item.system,
            system_type: item.systemType,
            period_type: item.period
        }
    }

    getDuration(date1, date2){
        if (!date1 || !date2) return null

        const dateBeg = new Date(date1)
        const dateEnd = new Date(date2)

        const delta = Math.abs(dateBeg - dateEnd)

        const secCommon = Math.floor(delta / 1000)
        const min = secCommon / (60)
        const sec = secCommon % 60

        return `${min} m. ${sec} sec.`
    }

    parseIntegrationHistoryItem(item){
        return {
            id: item.id,
            startDate: item.date_begin ? new Date(item.date_begin) : null,
            endDate: item.date_end ? new Date(item.date_end) : null,
            duration: this.getDuration(item.date_begin, item.date_end),
            entity: integrationEntityMap[item.entity],
            status: item.status,
            system: item.system
        }
    }

    parseIntegrationItem(item) {
        const parseDate = (date) => date ?? null;
        switch (item.system_type){
            case "SAP": {
                return {
                    id: item.system_id,
                    systemType: item.system_type,
                    description: item.description,
                    permissionDetail: {
                        status: item.permission_details.status,
                        checked: false,
                        loadDelta: false,
                        syncFuture: false,
                        lastSyncDate: parseDate(item.permission_details.last_update)
                    },
                    role: {
                        status: item.role.status,
                        checked: false,
                        loadDelta: false,
                        syncFuture: false,
                        lastSyncDate: parseDate(item.role.last_update)
                    },
                    employee: {
                        status: item.employee.status,
                        checked: false,
                        loadDelta: false,
                        syncFuture: false,
                        lastSyncDate: parseDate(item.employee.last_update)
                    },
                    profile: {
                        status: item.profile.status,
                        checked: false,
                        loadDelta: false,
                        syncFuture: false,
                        lastSyncDate: parseDate(item.profile.last_update)
                    },
                    manualIntegration: {
                        status: item.manual_integration.status,
                        lastSyncDate: parseDate(item.manual_integration.last_update)
                    },
                    common: {
                        status: item.common.status,
                        lastSyncDate: parseDate(item.common.last_update)
                    }
                };
            }

            case "1C": {
                return {
                    id: item.system_id,
                    systemType: item.system_type,
                    description: item.description,
                    authorityDetail: {
                        status: item.authority_detail.status,
                        checked: false,
                        loadDelta: false,
                        syncFuture: false,
                        lastSyncDate: parseDate(item.authority_detail.last_update)
                    },
                    role1C: {
                        status: item.role_1c.status,
                        checked: false,
                        loadDelta: false,
                        syncFuture: false,
                        lastSyncDate: parseDate(item.role_1c.last_update)
                    },
                    metadataObject: {
                        status: item.metadata_object.status,
                        checked: false,
                        loadDelta: false,
                        syncFuture: false,
                        lastSyncDate: parseDate(item.metadata_object.last_update)
                    },
                    employee1C: {
                        status: item.employee_1c.status,
                        checked: false,
                        loadDelta: false,
                        syncFuture: false,
                        lastSyncDate: parseDate(item.employee_1c.last_update)
                    },
                    manualIntegration: {
                        status: item.manual_integration.status,
                        lastSyncDate: parseDate(item.manual_integration.last_update)
                    },
                    common: {
                        status: item.common.status,
                        lastSyncDate: parseDate(item.common.last_update)
                    }
                };
            }
        }
    }

    parsePermission(item) {
        return {
            key: item.permission,
            text: item.description,
            systemId: item.system_id
        }
    }

    parsePermissionField(item) {
        return {
            key: item.fieldname,
            text: item.description,
            systemId: item.system_id,
            permission: item.permission
        }
    }

    parsePermissionFieldValue(item) {
        return {
            key: item.value,
            text: item.description,
            systemId: item.system_id,
            field: item.fieldname
        }
    }

    parsePermissionFieldValueNarrowed(item) {
        return {
            valueFrom: item.value_from,
            valueTo: item.value_to,
            text: item.description,
            systemId: item.system_id,
            field: item.fieldname
        }
    }

    parseOrgLevelValue(item) {
        return {
            key: item.value,
            text: item.description,
            systemId: item.system_id,
            orgLevel: item.orgLevel
        }
    }

    parseFunctionDefaultPermission(item) {
        return {
            id: item.id,
            system: item.system_id,
            permissionGroup: item.transaction,
            permission: item.permission,
            field: item.fieldname,
            valueFrom: item.value_from,
            valueTo: item.value_to,
        }
    }

    parseSAPIntegrationDataForServer(integrationData) {
        return {
            system_id: integrationData.systemId,
            system_type: "SAP",
            permission_details: integrationData.permissionDetail,
            role: integrationData.role,
            profile: integrationData.profile,
            employee: integrationData.employee
        }
    }

    parse1CIntegrationDataForServer(integrationData) {
        return {
            system_id: integrationData.systemId,
            system_type: "1C",
            authority_detail: integrationData.authorityDetail,
            metadata_object: integrationData.metadataObject,
            role_1c: integrationData.role1C,
            employee_1c: integrationData.employee1C
        }
    }

    parseTransaction(item) {
        return {
            key: item.transaction,
            systemId: item.system_id,
            text: item.description
        }
    }

    parseObjectType1C(item) {
        return {
            key: item.type_name,
            systemId: item.system_id
        }
    }

    parseObjectName1C(item) {
        return {
            key: item.name,
            systemId: item.system_id,
            text: item.description
        }
    }
    
    parseField1C(item) {
        return {
            key: item.auth_type_name,
            systemId: item.system_id,
            text: item.description
        }
    }

    parseValue1C(item) {
        return {
            key: item.right_name
        }
    }

    parseTransactionDetailed(transaction) {
        return {
            transaction: transaction.transaction,
            systemId: transaction.system_id,
            description: transaction.description,
        }
    }

    parseObjectTypeDetailed(objectType) {
        return {
            type: objectType.type,
            systemId: objectType.system_id
        }
    }

    parseObjectNameDetailed(objectName) {
        return {
            name: objectName.name,
            systemId: objectName.system_id,
            description: objectName.description
        }
    }
    
    parseField1CDetailed(field) {
        return {
            authTypeName: field.auth_type_name,
            systemId: field.system_id,
            description: field.description
        }
    }

    parseValue1CDetailed(value) {
        return {
            rightName: value.right_name,
            systemId: value.system_id
        }
    }

    parsePermissionDetailed(permission) {
        return {
            permission: permission.permission,
            systemId: permission.system_id,
            description: permission.description,
        }
    }

    parseProfileDetailed(profile) {
        return {
            profile: profile.profile,
            systemId: profile.system_id,
            description: profile.description,
        }
    }

    parseRoleDetailed(role) {
        return {
            role: role.role,
            systemId: role.system_id,
            description: role.description,
        }
    }

    parsePermissionFieldDetailed(item) {
        return {
            field: item.fieldname,
            description: item.description,
            systemId: item.system_id,
            permission: item.permission
        }
    }

    parsePermissionFieldValueDetailed(item) {
        return {
            value: item.value,
            description: item.description,
            systemId: item.system_id,
            field: item.fieldname
        }
    }
}

const integrationService = new IntegrationService();

export { integrationService };
export default IntegrationService;