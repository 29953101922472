import { useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { fetchUserRoles } from "../../../reducers/appSlice";

const useUserRoles = () => {
    const intl = useIntl();
    const dispatch = useDispatch();

    const userRoles = useSelector(state => state.app.userRoles);

    const userRolesText = {
        admin: intl.formatMessage({ id: "common.user-roles.admin"}),
        employee: intl.formatMessage({ id: "common.user-roles.employee"}),
        consultant: intl.formatMessage({ id: "common.user-roles.consultant"}),
    };

    useEffect(() => {
        if (userRoles.length === 0) {
            dispatch(fetchUserRoles());
        }
    }, [dispatch, userRoles])
    
    return { userRoles, userRolesText };
}

const ApprovalGroupKeys = {
    ifsDept: "InformationSecurityDept",
    rmDept: "RiskManagementDept",
}

export const useApprovalGroups = () => {
    const intl = useIntl();

    const approvalGroupText = {
        [ApprovalGroupKeys.ifsDept]: intl.formatMessage({ id: "common.approval-group.inf-security-dept"}),
        [ApprovalGroupKeys.rmDept]: intl.formatMessage({ id: "common.approval-group.risk-management-dept"}),
    };

    const approvalGroups = [
        { id: ApprovalGroupKeys.ifsDept, name: ApprovalGroupKeys.ifsDept },
        { id: ApprovalGroupKeys.rmDept, name: ApprovalGroupKeys.rmDept },
    ];

    return { approvalGroups, approvalGroupText };
}

export default useUserRoles;
