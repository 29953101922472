var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import SodService from "./sod-service";
import { nanoid } from "@reduxjs/toolkit";
import { parseRolePermissionsTree, parseRoleMenu, parseRoleOrgLevels } from "./utils";
import { reportsModelingService } from "./reports-modeling-service";
import { requestStatuses } from "../utils/brm-utils";
class RequestsService extends SodService {
    getRequests(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const config = this.generateConfigForGetEntities(params);
            const data = yield this.get("brm/change-request-header", config);
            // const total = data.total;
            // const rows = data && data.rows.map((item:GetRequestResponse) => this.parseRequest(item));
            const rows = data && data[0].map((item) => this.parseRequest(item));
            const total = data[1];
            return {
                rows,
                total,
            };
        });
    }
    createNewRequest(requestData) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield this.post("brm/change-request-header", this.parseNewRequestData(requestData));
            return data;
        });
    }
    saveRequestRoles(requestData, requestId) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield this.post(`brm/change-request-header/save-roles/${requestId}`, requestData);
            return data;
        });
    }
    getRequestsColumns() {
        return __awaiter(this, void 0, void 0, function* () {
            // const columns = await this.getColumns("/brm/requests/columns");
            const columns = [
                {
                    columnId: "requestId",
                    title: "Номер ЗиР",
                    type: "string",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "indicator",
                    title: "Индикатор",
                    type: "string",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "url",
                    title: "Ссылка",
                    type: "string",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "createdBy",
                    title: "ФИО автора",
                    type: "string",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "updatedBy",
                    title: "Обновил",
                    type: "string",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "currentStage",
                    title: "Текущий этап",
                    type: "string",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "requestType",
                    title: "Запрос",
                    type: "string",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "createdAt",
                    title: "Дата создания",
                    type: "datetime",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "updatedAt",
                    title: "Дата обновления",
                    type: "datetime",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "hasRisks",
                    title: "Выявлен риск",
                    type: "string",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "priority",
                    title: "Приоритет",
                    type: "string",
                    sortable: true,
                    filterable: true,
                },
                {
                    columnId: "status",
                    title: "Статус",
                    type: "string",
                    sortable: true,
                    filterable: true,
                },
            ];
            return columns.map((column) => this.parseColumn(column));
        });
    }
    getRequestsStatuses() {
        return __awaiter(this, void 0, void 0, function* () {
            //TODO: Написать запрос
            const data = {
                completed: 5,
                rejected: 8,
                approved: 10,
                inProgress: 12,
                total: 35,
            };
            return data;
        });
    }
    getDetaliedRequestRole(requestId, groupId, roleName) {
        return __awaiter(this, void 0, void 0, function* () {
            const roleNameParsed = encodeURIComponent(roleName);
            const data = yield this.get(`brm/change-request-header/detailed-role/${requestId}/${groupId}/${roleNameParsed}`);
            const tree = parseRolePermissionsTree(data.tree);
            const orgLevels = parseRoleOrgLevels(data.org_levels);
            const menu = parseRoleMenu(data.menu);
            const childrens = this.parseChildrenRole(data.children);
            const profile = data.profile || "";
            return { tree, orgLevels, menu, childrens, profile };
        });
    }
    //@ts-expect-error add type
    parseChildrenRole(childrens) {
        //@ts-expect-error add type
        return childrens.map((item) => ({
            id: nanoid(),
            role: item.role,
            systemId: item.system_id,
            description: item.description,
        }));
    }
    getRequestDetailed(requestId) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield this.get(`brm/change-request-header/detailed/${requestId}`);
            const requestData = this.parseRequestDetailed(data);
            return requestData;
        });
    }
    getRequestStages() {
        return __awaiter(this, void 0, void 0, function* () {
            const data = yield this.get("brm/change-request-workflow-meta");
            const stages = data.map((item) => {
                return {
                    stage: item.status_id,
                    text: item.description,
                };
            });
            return stages;
        });
    }
    getGroupSystemRoles(params, signal, groupSystemId) {
        return __awaiter(this, void 0, void 0, function* () {
            params.filters = [{ field: "groupId", sign: "EQ", value: groupSystemId }];
            const config = this.generateConfigForGetEntities(params, signal);
            const data = yield this.get("core/integration/role", config);
            const rows = data && data.rows.map((item) => this.parseSystemGroupRole(item, groupSystemId));
            const total = data.total;
            return {
                rows,
                total,
            };
        });
    }
    saveRequestComment(requestCommentData, requestId) {
        return __awaiter(this, void 0, void 0, function* () {
            yield this.post(`brm/change-request-comment/${requestId}`, requestCommentData);
        });
    }
    moveToRejectStep(requestId, actionComment) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = { comment: actionComment };
            yield this.post(`brm/reject-step/${requestId}`, data);
        });
    }
    moveToApproveStep(requestId, actionComment, riskCheck) {
        return __awaiter(this, void 0, void 0, function* () {
            const data = { comment: actionComment };
            yield this.post(`brm/approve-step/${requestId}`, data, { params: { check_sod_conflicts: riskCheck } });
        });
    }
    getPfcgTreeForTcodes(groupId, tcodesList) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                tcodes: tcodesList.join(","),
                group_id: groupId,
            };
            const rows = yield this.get(`brm/tcode-permissions-tree`, {
                params,
            });
            return reportsModelingService.parsePermissionsTree(rows, new Set());
        });
    }
    getPfcgTreeForPermissions(groupId, permissionList) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                permissions: permissionList.join(","),
                group_id: groupId,
            };
            const rows = yield this.get(`brm/tcode-permissions-tree`, {
                params,
            });
            return { rows: reportsModelingService.parsePermissionsTree(rows, new Set()) };
        });
    }
    getTcodePermissions(groupId, tcodesList) {
        return __awaiter(this, void 0, void 0, function* () {
            const params = {
                tcodes: tcodesList.join(","),
                group_id: groupId,
            };
            const { rows } = yield this.get(`core/integration/tcode-permissions`, {
                params,
            });
            return rows.map((row) => ({
                systemId: row.system_id,
                permission: row.permission,
                transaction: row.transaction,
            }));
        });
    }
    saveTestingData(form, requestId, riskCheck, approveWithConflictsMessage) {
        return __awaiter(this, void 0, void 0, function* () {
            const parsedTestingData = this.parseTestingData(form, requestId);
            if (approveWithConflictsMessage.length > 0)
                parsedTestingData.comment = approveWithConflictsMessage;
            const data = yield this.post(`brm/approve-step/${requestId}`, parsedTestingData, { params: { check_sod_conflicts: riskCheck } });
            return data;
        });
    }
    calcRequestReport(requestId) {
        return __awaiter(this, void 0, void 0, function* () {
            const response = this.post(`brm/sod-conflict-report/${requestId}`);
            return response;
        });
    }
    fetchRequestReportResult(requestId, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const config = this.generateConfigForGetEntities(params);
            const data = yield this.get(`brm/sod-conflict-report/${requestId}`, config);
            const rows = data && data[0];
            const total = data[1];
            return {
                rows,
                total,
            };
        });
    }
    fetchRequestReportResultColumns() {
        return __awaiter(this, void 0, void 0, function* () {
            const columns = reportsModelingService.getRoleLevelModelingColumns();
            return columns;
        });
    }
    //@ts-expect-error fix column
    parseColumn(column) {
        return {
            id: column.columnId,
            title: column.title,
            type: column.type,
            sortable: column.sortable,
            filterable: column.filterable,
        };
    }
    parseRequest(request) {
        //TODO:Убрать моки
        return {
            requestId: request.request_id,
            indicator: "ORG",
            priority: request.priority,
            url: request.outer_link,
            createdBy: request.created_by,
            updatedBy: request.updated_by,
            requestType: "Транспортный запрос",
            createdAt: request.created_at,
            updatedAt: request.updated_at,
            hasRisks: request.has_risks,
            status: request.status,
        };
    }
    parseRequestDetailed(request) {
        return {
            requestId: request.request_id,
            indicator: "ORG",
            priority: request.priority,
            url: request.outer_link,
            createdBy: request.created_by,
            requestType: "Транспортный запрос",
            createdAt: request.created_at,
            updatedBy: request.updated_by,
            updatedAt: request.updated_at,
            hasRisks: request.has_risks,
            currentStage: request.status,
            status: requestStatuses[request.status],
            requestSource: request.source_type,
            reportReady: false,
            history: request.history.map((item) => {
                return {
                    id: nanoid(),
                    createdAt: item.created_at,
                    newValue: item.new_value,
                    oldValue: item.old_value,
                    operation: item.operation,
                    author: item.author,
                    additionalInfo: item.additional_info,
                };
            }),
            comments: request.comments.map((comment) => {
                return {
                    commentId: comment.comment_id,
                    createdAt: comment.created_at,
                    author: comment.author,
                    comment: comment.comment,
                };
            }),
            roles: request.roles.map((role) => {
                return {
                    role: role.role,
                    groupId: role.group_id,
                    requestId: role.request_id,
                    description: role.description,
                    // roleType: role.is_complex ? "Групповая" : "Одиночная",
                    version: role.version,
                    isComplex: role.is_complex,
                    isNew: role.is_new,
                    profile: role.profile,
                    groupId: role.group_id,
                    updatedAt: role.updated_at,
                    updatedBy: role.updated_by
                };
            }),
        };
    }
    parseNewRequestData(requestData) {
        return {
            request_id: requestData.requestId,
            outer_link: requestData.requestUrl,
            // priority: requestData.requestPriority,
            priority: "low",
            source_type: requestData.requestSource,
        };
    }
    parseSystemGroupRole(role, groupSystemId) {
        return {
            id: nanoid(),
            role: role.role,
            isComplex: role.is_complex_role,
            description: role.description,
            groupId: groupSystemId,
        };
    }
    parseTestingData(form, requestId) {
        return {
            request_id: parseInt(requestId),
            // testing_duration: form.testingDuration,
            testing_duration: 2,
            testing_user_count: parseInt(form.testingUserCount) || 0,
            testing_responsible: form.testingResponsible,
            additional_roles: form.additionalRoles,
        };
    }
}
const requestsService = new RequestsService();
export { requestsService };
export default RequestsService;
