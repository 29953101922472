// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import RoleModelingBox from "../../../reports-modeling/role-modeling-box";
import { useDispatch } from "react-redux";
import { openRoleMenuNode, selectModelingSelectedRole, addRoleMenuFolder, addRoleMenuNodeWithDefaultValues, removeMenuItemFromTree, addModelingRoleTreeItems, setModelingRoleTreeField, setModelingRoleOrgLevelValues, copyRoleMenuWithDefaultValues, expandItemTree, removeModelingRoleTreeItem, findPermissionInTree, } from "../../../../reducers/administration/requestSlice";
import { getManualSelectPermissionsSearchHelp, getTransactionsSearchHelp } from "../../../../utils/searchHelps";
import { requestsService } from "../../../../services/requests-service";
const RequestSingleRoleModelingBox = ({ disabled = false }) => {
    const modelingRole = useSelector(selectModelingSelectedRole);
    const dispatch = useDispatch();
    const [searchString, setSearchString] = useState("");
    useEffect(() => {
        dispatch(findPermissionInTree({
            searchString,
        }));
    }, [searchString]);
    // useEffect(() => {
    //     setSearchString('')
    // }, [modelingRole])
    const handleOpenRoleMenuNode = (itemPath) => dispatch(
    //@ts-expect-error add type
    openRoleMenuNode({
        id: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.id,
        itemPath,
    }));
    const handleAddRoleMenuFolder = (activeNodePath, folderName) => dispatch(
    //@ts-expect-error add type
    addRoleMenuFolder({
        id: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.id,
        activeNodePath,
        folderName,
    }));
    //@ts-expect-error add type
    const handleAddRoleMenuNode = (activeNodePath, tcodes) => dispatch(addRoleMenuNodeWithDefaultValues({
        id: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.id,
        activeNodePath,
        tcodes,
    }));
    //@ts-expect-error add type
    const handleRemoveRoleMenuNode = (activeNodePath) => dispatch(
    //@ts-expect-error add type
    removeMenuItemFromTree({
        id: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.id,
        menuItemPath: activeNodePath,
    }));
    //@ts-expect-error add type
    const handleAddTreeItems = (items) => dispatch(
    //@ts-expect-error add type
    addModelingRoleTreeItems({
        id: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.id,
        items,
    }));
    //@ts-expect-error add type
    const handleRemoveTreeItem = (path) => dispatch(
    //@ts-expect-error add type
    removeModelingRoleTreeItem({
        id: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.id,
        path,
    }));
    //@ts-expect-error add type
    const handleTreeItemFieldChange = (itemPath, field, value) => dispatch(
    //@ts-expect-error add type
    setModelingRoleTreeField({
        id: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.id,
        itemPath,
        field,
        value,
    }));
    //@ts-expect-error add type
    const handleSetOrgLevelValues = (values) => dispatch(
    //@ts-expect-error add type
    setModelingRoleOrgLevelValues({
        id: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.id,
        values,
    }));
    //@ts-expect-error add type
    const handleCopyRoleMenu = (checkedMenuItems) => dispatch(copyRoleMenuWithDefaultValues({
        id: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.id,
        menuToCopy: checkedMenuItems,
    }));
    //@ts-expect-error add type
    const handleExpandItemTree = (path) => dispatch(expandItemTree({
        path: path,
    }));
    const readRowsForManualSelectPermissionsSearchHelp = (params, signal) => {
        params.filters.push({
            field: "groupId",
            sign: "EQ",
            value: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.groupId,
        });
        return getManualSelectPermissionsSearchHelp(params, signal);
    };
    const readTreeForManualSelectPermissionsSearchHelp = (params, signal, _, permissionIds) => {
        const groupId = modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.groupId;
        return requestsService.getPfcgTreeForPermissions(groupId, permissionIds);
    };
    const readRowdForTransactionsSearchHelp = (params, signal) => {
        params.filters.push({
            field: "groupId",
            sign: "EQ",
            value: modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.groupId,
        });
        return getTransactionsSearchHelp(params, signal);
    };
    return (React.createElement(RoleModelingBox, { role: modelingRole, onAddTreeItems: handleAddTreeItems, onSetOrgLevelValues: handleSetOrgLevelValues, onTreeItemFieldChange: handleTreeItemFieldChange, onOpenRoleMenuNode: handleOpenRoleMenuNode, onAddRoleMenuFolder: handleAddRoleMenuFolder, onAddRoleMenuNode: handleAddRoleMenuNode, onRemoveRoleMenuNode: handleRemoveRoleMenuNode, onCopyRoleMenu: handleCopyRoleMenu, onRemoveTreeItem: handleRemoveTreeItem, onExpandItemTree: handleExpandItemTree, searchString: searchString, setSearchString: setSearchString, readRowsForManualSelectPermissionsSearchHelp: readRowsForManualSelectPermissionsSearchHelp, readRowdForTransactionsSearchHelp: readRowdForTransactionsSearchHelp, readTreeForManualSelectPermissionsSearchHelp: readTreeForManualSelectPermissionsSearchHelp, disabled: disabled }));
};
export default RequestSingleRoleModelingBox;
