import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { getOrgLevelValuesSearchHelp } from "../../../utils/searchHelps";

import CommonTable, { createRowCellContentInner } from "../../common/table";
import { SearchHelpInput } from "../../common/form-controls";
import SearchHelpDialog from "../../common/search-helps";
import { TrashIcon } from "../../common/icons";

import AddOrgLevelButton from "./add-org-level-button.jsx";

import "./styles.less";

const getRowId = row => row.rowId;

const OrgLevelsDialogTable = ({
    systemId,
    rows,
    onChangeCell,
    onAddRow,
    onDeleteRow,
    disabled=false
}) => {
    const [rowAction, setRowAction] = useState(null);
    const intl = useIntl();
    
    const toolbarActions = [
        {
            id: "title",
            title: intl.formatMessage({ id: "org-levels-dialog.table.title" }),
            type: "text",
            position: "begin"
        }
    ];

    const columns = [
        {
            id: "description",
            title: intl.formatMessage({ id: "org-levels-dialog.table.columns.description" }),
            type: "text",
        },
        {
            id: "from",
            title: intl.formatMessage({ id: "org-levels-dialog.table.columns.from" }),
            type: "search-help",
        },
        {
            id: "to",
            title: intl.formatMessage({ id: "org-levels-dialog.table.columns.to" }),
            type: "search-help"
        },
        {
            id: "actions",
            type: "actions",
            actions: [
                {
                    id: "delete",
                    IconComponent: TrashIcon,
                    onClick: (_, row) => onDeleteRow(row.rowId),
                    disabled: disabled
                }
            ]
        }
    ];

    const searchHelpColumns = {
        "from": [
            {
                id: "key",
                title: intl.formatMessage({ id:  "org-levels-dialog.table.columns.from" })
            },
            {
                id: "text",
                title: intl.formatMessage({ id:  "search-help.columns.description" })
            }
        ],
        "to": [
            {
                id: "key",
                title: intl.formatMessage({ id:  "org-levels-dialog.table.columns.to" })
            },
            {
                id: "text",
                title: intl.formatMessage({ id:  "search-help.columns.description" })
            }
        ],
    };

    const handleSubmitSearchHelp = (selectedRows) => {
        onChangeCell(rowAction.row.rowId, rowAction.field, selectedRows);
        setRowAction(null);
    };

    const searchHelpDialogOpen = rowAction?.type === "search-help";

    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        switch (column.type) {
            case "search-help":
                return (
                    <>
                        <SearchHelpInput
                            fullWidth
                            disabled={disabled}
                            value={row[column.id]}
                            delay={0}
                            onChange={(e) => onChangeCell(row.rowId, column.id, e.target.value)}
                            onOpenSearchHelp={() => setRowAction({
                                type: "search-help",
                                row: row,
                                field: column.id,
                                readRows: (params, signal) => getOrgLevelValuesSearchHelp(params, signal, systemId, row.id)
                            })}
                        />

                        {column.id === "to" && !disabled && (
                            <AddOrgLevelButton onClick={() => onAddRow(row.rowId)} />
                        )}
                    </>
                    
                )

            case "add":
                return (
                    <AddOrgLevelButton>
                        Add
                    </AddOrgLevelButton>
                )

            default:
                if (column.type !== "actions" || row.deletable) {
                    return createRowCellContentInner(
                        row, column, selected, onSelect, getCellValue
                    );
                }
        }
    };

    return (
        <>
            <CommonTable
                className="org-levels-table"
                rows={rows}
                columns={columns}
                createRowCellContent={createRowCellContent}
                showPagination={false}
                showFiltersActions={false}
                toolbarActions={toolbarActions}
                getRowId={getRowId}
            />

            {searchHelpDialogOpen && (
                <SearchHelpDialog
                    defaultSearchString={rowAction.row[rowAction.field] || ""}
                    open={searchHelpDialogOpen}
                    readRows={rowAction.readRows}
                    columns={searchHelpColumns[rowAction.field]}
                    onSubmit={handleSubmitSearchHelp}
                    onCancel={() => setRowAction(null)}
                />
            )}
        </>
    );
};

OrgLevelsDialogTable.propTypes = {
    systemId: PropTypes.string,
    rows: PropTypes.arrayOf(PropTypes.object),
    onChangeCell: PropTypes.func,
    onAddRow: PropTypes.func,
    onDeleteRow: PropTypes.func,
    disabled: PropTypes.bool
};

export default OrgLevelsDialogTable;