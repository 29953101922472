// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import CreateRoleForRequestFormTitle from "./create-role-for-request-form-header";
import CreateRoleForRequestForm from "./create-role-for-request-form";
import { Box } from "@mui/material";
import { Button } from "../../../common/buttons";
import { FormattedMessage } from "react-intl";
import { useDispatch } from "react-redux";
import { addRolesToRequest } from "../../../../reducers/administration/requestSlice";
import { nanoid } from "@reduxjs/toolkit";
const CreateRoleForRequestFormPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [form, setForm] = useState({
        id: "",
        description: "",
        groupId: "",
        isComplex: false,
        profileName: "",
        profileDescription: "",
        roleOwner: [],
        deputyRoleOwner: []
    });
    const [validationErrors, setValidationErrors] = useState(null);
    const handleBackClick = useCallback(() => {
        navigate("..", { relative: "path" });
    }, [navigate]);
    const handleSubmit = () => {
        const crearedRole = {
            id: nanoid(),
            isNew: true,
            description: form.description,
            role: form.id,
            groupId: form.groupId,
            isComplex: form.isComplex,
            roleOwner: form.roleOwner[0],
            deputyRoleOwner: form.deputyRoleOwner[0],
        };
        dispatch(addRolesToRequest({
            roles: [crearedRole],
            groupId: form.groupId,
        }));
        handleBackClick();
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: { backgroundColor: "#f5f5f5", padding: "15px 20px 15px" } },
            React.createElement(CreateRoleForRequestFormTitle, { onBackClick: handleBackClick }),
            React.createElement(CreateRoleForRequestForm, { form: form, setForm: (newData) => setForm((prev) => (Object.assign(Object.assign({}, prev), newData))), validationErrors: validationErrors, setValidationErrors: setValidationErrors })),
        React.createElement(Box, { className: "create-role-for-request-form-buttons" },
            React.createElement(Button, { variant: "contained", className: "create-role-for-request-form-button-contained", onClick: handleSubmit },
                React.createElement(FormattedMessage, { id: "create-role-for-request-form.btn-save" })),
            React.createElement(Button, { variant: "outlined", className: "create-role-for-request-form-button-outlined", onClick: handleBackClick },
                React.createElement(FormattedMessage, { id: "create-role-for-request-form.btn-cancel" })))));
};
export default CreateRoleForRequestFormPage;
