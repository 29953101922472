import { Box, Typography } from "@mui/material";
import React from "react";
import { Button } from "../../../common/buttons";
import { FormattedMessage } from "react-intl";
import "./styles.less";
const commonApproveReject = {
    approve: "request-detailed-actions.btn-approve-stage",
    reject: "request-detailed-actions.btn-reject-stage",
};
const stageActions = {
    TEST_APPROVEMENT: {
        approve: "request-detailed-actions.btn-approve-testing-stage",
        reject: "request-detailed-actions.btn-reject-testing-stage",
    },
    ROLE_OWNER_APPROVEMENT: commonApproveReject,
    RISK_MANAGE_APPROVEMENT: commonApproveReject,
    INFSEC_APPROVEMENT: commonApproveReject,
    // ROLE_DEFINITION: {
    //     approve: "request-detailed-actions.btn-approve-role- definition-stage",
    //     reject: "request-detailed-actions.btn-reject-role-definition-stage",
    // },
};
const RequestDetailedActions = ({ currentStage, 
// onProcessClick,
// onCancelProcessClick,
onApproveClick, onRejectClick, onSaveAndContinueClick, onSaveClick, }) => {
    const actions = stageActions[currentStage] || null;
    return (React.createElement(Box, { className: "request-detailed-actions" },
        React.createElement(Box, { display: "flex" },
            React.createElement(Box, { display: "flex", gap: 2 }),
            actions && (React.createElement(Box, { display: "flex", gap: 2 },
                React.createElement(Button, { variant: "contained", className: "request-detailed-actions-button-contained", onClick: onApproveClick },
                    React.createElement(FormattedMessage, { id: actions.approve })),
                React.createElement(Button, { variant: "outlined", className: "request-detailed-actions-button-outlined", onClick: onRejectClick },
                    React.createElement(FormattedMessage, { id: actions.reject }))))),
        currentStage == "ROLE_DEFINITION" && (React.createElement(Box, { className: "request-detailed-actions-confirmation" },
            React.createElement(Typography, { className: "request-detailed-actions-confirmation-text" },
                React.createElement(FormattedMessage, { id: "request-detailed-actions.confirmation-testing" })),
            React.createElement(Box, { display: "flex", gap: 2 },
                React.createElement(Button, { className: "request-detailed-actions-button-contained", variant: "contained", onClick: onSaveAndContinueClick },
                    React.createElement(FormattedMessage, { id: "request-detailed-actions.btn-save-and-continue" })),
                React.createElement(Button, { className: "request-detailed-actions-button-outlined", variant: "outlined", onClick: onSaveClick },
                    React.createElement(FormattedMessage, { id: "request-detailed-actions.btn-save" })))))));
};
export default RequestDetailedActions;
