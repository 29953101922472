import React from "react";
import { Button } from "../../../common/buttons";
import { Box } from "@mui/material";
import { FormattedMessage } from "react-intl";
import "./styles.less";
const AddRoleToRequestDialogActions = ({ onSave, onCancel }) => {
    return (React.createElement(Box, { sx: { marginRight: "25px", marginTop: "15px", marginBottom: "25px" }, display: "flex", justifyContent: "flex-end", gap: 2 },
        React.createElement(Button, { variant: "outlined", className: "add-role-to-request-dialog-actions-button-outlined", onClick: onCancel },
            React.createElement(FormattedMessage, { id: "add-role-to-request-dialog-actions.btn-cancel" })),
        React.createElement(Button, { variant: "contained", className: "add-role-to-request-dialog-actions-button-contained", onClick: onSave },
            React.createElement(FormattedMessage, { id: "add-role-to-request-dialog-actions.btn-add" }))));
};
export default AddRoleToRequestDialogActions;
