import React, { useState } from "react";
import { Box,  } from "@mui/material";
import PropTypes from "prop-types";

import GroupRoleTable from "./group-role-table.jsx";
import GroupRoleActions from "./group-role-actions.jsx";
import GroupRoleAddRolesDialog from "./group-role-add-roles-dialog.jsx";

import "./styles.less";
import { integrationService } from "../../../services/integration-service.js";

const GroupRoleModelingBox = ({
    roles,
    selectedRole,
    onAddChildRole,
    onRemoveChildRoles,
    handleRowClick,
    handleExpandChildrenRoles,
    isAddDisabled,
    isDeleteDisabled,
    showSingleRoles = true,
    getRoles
}) => {
    // const [selectedIds, setSelectedIds] = useState([]);
     const [addRolesDialogOpen, setAddRolesDialogOpen] = useState(false);
     const onAddClick = ()=>{
        if(selectedRole?.status != 'deleted'){
            setAddRolesDialogOpen(true)
        }else{
            onAddChildRole()
        }
     }


    // const handleRemoveChildRoles = () => {
    //     onRemoveChildRoles(selectedIds);
    //     setSelectedIds([]);
    // };

    return (


        <Box className="group-role-modeling-box">

            <GroupRoleActions
                onAddClick={onAddClick}
                onRemoveClick={onRemoveChildRoles}
                isAddDisabled={isAddDisabled}
                isDeleteDisabled={isDeleteDisabled}
            />

            <GroupRoleTable
                rows={roles}
                selectedRole={selectedRole}
                handleRowClick={handleRowClick}
                onExpandClick={handleExpandChildrenRoles}
            />
            {addRolesDialogOpen && (
                <GroupRoleAddRolesDialog
                    open={addRolesDialogOpen}
                    //systemId={role.systemId}
                    onClose={() => setAddRolesDialogOpen(false)}
                    onSubmit={onAddChildRole}
                    showSingleRoles={showSingleRoles}
                    getRoles={getRoles}
                />
            )}
        </Box>
    );
};

GroupRoleModelingBox.propTypes = {
    roles: PropTypes.array,
    selectedRole: PropTypes.object,
    onAddChildRole: PropTypes.func,
    onRemoveChildRoles: PropTypes.func,
    handleRowClick: PropTypes.func,
    handleExpandChildrenRoles: PropTypes.func,
    isAddDisabled: PropTypes.bool,
    isDeleteDisabled: PropTypes.bool,
    showSingleRoles: PropTypes.bool,
    getRoles: PropTypes.func
};

GroupRoleModelingBox.defaultProps = {
    getRoles: integrationService.getModelingRoles.bind(integrationService)
}


export default GroupRoleModelingBox;