import React, { useState } from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { reportsModelingService } from "../../../services/reports-modeling-service";
import { getManualSelectPermissionsSearchHelp } from "../../../utils/searchHelps";

import SearchHelpDialog from "../../common/search-helps";
import PermissionWithoutClassErrorDialog from "./permission-without-class-error-dialog.jsx";

import "./styles.less";

const ManualSelectPermissionsDialog = ({
    open,
    systemId,
    onClose,
    onApply,
    readRows = (params, signal, systemId) => getManualSelectPermissionsSearchHelp(params, signal, systemId), 
    readTree = (params, signal, systemId, permissionIds) => reportsModelingService.getPermissionsTree(params, signal, systemId, permissionIds),
    disabled = false,
}) => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: "manual-select-permissions-dialog.title" });

    const [error, setError] = useState(null);
    const [selectedPermissions, setSelectedPermissions] = useState([]);

    const columns = [
        { id: "key", title: intl.formatMessage({ id: "manual-select-permissions-dialog.columns.key" }) },
        { id: "text", title: intl.formatMessage({ id: "manual-select-permissions-dialog.columns.text" }) },
    ];

    const handleSubmit = async (permissionIds) => {
        if (disabled) {
            return
        }

        setSelectedPermissions(permissionIds);

        if (permissionIds.length === 0) {
            onApply([]);
            return;
        }

        try {
            const response = await readTree(null, null, systemId, permissionIds);
            onApply(response.rows);
        } catch (error) {
            if (error.code === "permission_without_classes") {
                setError(error);
            } else {
                console.error(error);
            }
        }
    };

    const handleRemoveUnvalid = () => {
        const unvalidPermissions = error.detail;
        setSelectedPermissions((permissions) => permissions.filter((permission) => !unvalidPermissions.includes(permission)));
        setError(null);
    };

    return (
        <>
            <SearchHelpDialog
                className="manual-select-permissions-dialog"
                title={title}
                columns={columns}
                open={open}
                readRows={(params, signal) => readRows(params, signal, systemId)} 
                onSubmit={handleSubmit}
                onCancel={onClose}
                selectOneRow={false}
                selectOnRowClick={true}
                currentIds={selectedPermissions}
                disabled={disabled}
            />

            {error && (
                <PermissionWithoutClassErrorDialog
                    open={Boolean(error)}
                    error={error}
                    onClose={() => setError(null)}
                    onRemoveUnvalid={handleRemoveUnvalid}
                />
            )}
        </>
    );
};

ManualSelectPermissionsDialog.propTypes = {
    open: PropTypes.bool,
    systemId: PropTypes.string,
    onClose: PropTypes.func,
    onApply: PropTypes.func,
    readRows: PropTypes.func, 
    readTree: PropTypes.func,
    disabled: PropTypes.bool
};

export default ManualSelectPermissionsDialog;
