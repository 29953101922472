var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useCallback, useState } from "react";
import useLocalTable from "../../../common/hooks/useLocalTable";
import { createFiltersFromFilterData } from "../../../../utils/formViews";
const getFilters = (searchString, filterData) => {
    const filters = [];
    // if (searchString) {
    //     filters.push(new ComplexFilter([new Filter("description", Filter.CO, searchString), new Filter("systemId", Filter.CO, searchString)], ComplexFilter.OR));
    // }
    if (filterData) {
        const createdFilters = createFiltersFromFilterData(filterData);
        filters.push(...createdFilters);
    }
    return filters;
};
const useCreateHistoryTable = (data, getFilters) => {
    const { rows, onFilter, pagination } = useLocalTable(data, true);
    const [filterData, setFilterData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const setFilterDataCallback = useCallback((filterData) => {
        setFilterData(filterData);
        onFilter(getFilters(searchString, filterData));
    }, [searchString, onFilter, getFilters]);
    const setSearchStringCallback = useCallback((searchString) => {
        setSearchString(searchString);
        onFilter(getFilters(searchString, filterData));
    }, [filterData, onFilter, getFilters]);
    return {
        rows,
        pagination,
        searchString,
        setSearchString: setSearchStringCallback,
        filterData,
        setFilterData: setFilterDataCallback
    };
};
const useRequestDetailedHistoryTable = (history) => {
    const getDialogFilters = useCallback((_a) => __awaiter(void 0, [_a], void 0, function* ({ field, search = "" }) {
        const lowerSearch = search.toLowerCase();
        let keys;
        switch (field) {
            case "operation": {
                keys = history.filter(({ operation }) => operation === null || operation === void 0 ? void 0 : operation.toLowerCase().includes(lowerSearch)).map(({ operation }) => operation);
                break;
            }
            case "author": {
                keys = history.filter(({ author }) => author === null || author === void 0 ? void 0 : author.toLowerCase().includes(lowerSearch)).map(({ author }) => author);
                break;
            }
            case "oldValue": {
                keys = history.filter(({ oldValue }) => oldValue === null || oldValue === void 0 ? void 0 : oldValue.toLowerCase().includes(lowerSearch)).map(({ oldValue }) => oldValue);
                break;
            }
            case "newValue": {
                keys = history.filter(({ newValue }) => newValue === null || newValue === void 0 ? void 0 : newValue.toLowerCase().includes(lowerSearch)).map(({ newValue }) => newValue);
                break;
            }
            case "aditionalInfo": {
                keys = history.filter(({ additionalInfo }) => additionalInfo === null || additionalInfo === void 0 ? void 0 : additionalInfo.toLowerCase().includes(lowerSearch)).map(({ additionalInfo }) => additionalInfo);
                break;
            }
        }
        const uniqueKeys = [...new Set(keys)];
        const rows = uniqueKeys.map((key) => ({ key, text: key }));
        return { rows, total: rows.length };
    }), [history]);
    // const viewTable = useEntityFormViewTable(history, getFilters);
    const viewTable = useCreateHistoryTable(history, getFilters);
    return Object.assign(Object.assign({}, viewTable), { getDialogFilters });
};
export default useRequestDetailedHistoryTable;
