var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Box } from "@mui/material";
import React, { useState } from "react";
import PreparationForTestingRequestForm from "./preparation-for-testing-request-form";
import { FormattedMessage, useIntl } from "react-intl";
import { Button } from "../../../common/buttons";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { saveTestingData, fetchRequestDetailed, setError } from "../../../../reducers/administration/requestSlice";
import { EntityFormHeaderTitle } from "../../../common/entity-form";
import "./styles.less";
import { ErrorsDialog, ProgressDialog } from "../../../common/dialogs";
import ApproveRequestWithConflictsDialog from "../request-detailed-page/approve-request-with-conflicts-dialog";
const PreparationForTestingRequestFormPage = () => {
    const navigate = useNavigate();
    const intl = useIntl();
    const { requestId } = useParams();
    const dispatch = useDispatch();
    const [validationErrors, setValidationErrors] = useState({});
    const error = useSelector((state) => state.administration.requests.error);
    const busy = useSelector((state) => state.administration.requests.busy);
    const busyType = useSelector((state) => state.administration.requests.busyType);
    const [approveWithConflictsMessage, setApproveWithConflictsMessage] = useState("");
    const [form, setForm] = React.useState({
        testingResponsible: [],
        testingUserCount: "",
        additionalRoles: [],
        testingDuration: "",
    });
    const handleCancelClick = () => {
        navigate("../../..", { relative: "path" });
    };
    const handleSubmitClick = () => __awaiter(void 0, void 0, void 0, function* () {
        // if (!requestId || !form.testingUserCount) {
        //     setValidationErrors({ testingUserCount: { value: "", message: "Поле не может быть пустым" } });
        //     return;
        // }
        if (requestId) {
            yield dispatch(saveTestingData({ form, requestId, riskCheck: true })).unwrap();
            dispatch(fetchRequestDetailed(requestId));
            navigate(`/administration/request/${requestId}`);
        }
    });
    const handleApproveWithConflicts = () => __awaiter(void 0, void 0, void 0, function* () {
        if (requestId) {
            yield dispatch(saveTestingData({ form, requestId, riskCheck: false, approveWithConflictsMessage })).unwrap();
            dispatch(setError(null));
            dispatch(fetchRequestDetailed(requestId));
            navigate(`/administration/request/${requestId}`);
        }
    });
    return (React.createElement(React.Fragment, null,
        React.createElement(Box, { sx: { backgroundColor: "#f5f5f5", padding: "15px 20px 15px" } },
            React.createElement(EntityFormHeaderTitle, { title: intl.formatMessage({ id: "preparation-for-testing-request-form-page.title" }), onBackClick: () => {
                    navigate("..", { relative: "path" });
                } }),
            React.createElement(PreparationForTestingRequestForm, { form: form, setForm: (newData) => setForm((prev) => (Object.assign(Object.assign({}, prev), newData))), validationErrors: validationErrors, setValidationErrors: setValidationErrors })),
        React.createElement(Box, { className: "preparation-for-testing-request-form-page-buttons" },
            React.createElement(Button, { onClick: handleSubmitClick, className: "preparation-for-testing-request-form-page-button-submit", variant: "contained" },
                React.createElement(FormattedMessage, { id: "preparation-for-testing-request-form-page.btn-submit" })),
            React.createElement(Button, { onClick: handleCancelClick, className: "preparation-for-testing-request-form-page-button-cancel", variant: "outlined" },
                React.createElement(FormattedMessage, { id: "preparation-for-testing-request-form-page.btn-cancel" }))),
        React.createElement(ErrorsDialog, { error: error, open: (error === null || error === void 0 ? void 0 : error.type) === "error" && error.code != "HAS RISKS", onClose: () => dispatch(setError(null)) }),
        React.createElement(ApproveRequestWithConflictsDialog, { onSave: handleApproveWithConflicts, approveMessage: approveWithConflictsMessage, setApproveMessage: setApproveWithConflictsMessage, open: (error === null || error === void 0 ? void 0 : error.code) === "HAS RISKS", onClose: () => dispatch(setError(null)) }),
        React.createElement(ProgressDialog, { title: "", open: busy, busyType: busyType, progress: "" })));
};
export default PreparationForTestingRequestFormPage;
