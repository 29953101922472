import React from "react";
import { useSelector } from "react-redux";
import { Box, Tabs, Tab, Divider } from "@mui/material";
import RequestDetailedHeaderTitle from "./request-detailed-header-title";
import "./styles.less";
const RequestDetailedHeader = ({ requestStages, onBackClick }) => {
    const requestData = useSelector((state) => state.administration.requests.currentRequest);
    const activeTabIndex = requestStages.findIndex((stage) => stage.stage === (requestData === null || requestData === void 0 ? void 0 : requestData.currentStage));
    return (React.createElement(Box, { sx: { backgroundColor: "#F2F2F2", padding: "12px 16px 20px", marginBottom: "25px" } },
        React.createElement(RequestDetailedHeaderTitle, { requestData: requestData, onBackClick: onBackClick }),
        React.createElement(Box, { sx: { backgroundColor: "white", marginTop: "25px" } },
            React.createElement(Tabs, { className: "request-detailed-header-request-tabs", value: activeTabIndex >= 0 ? activeTabIndex : false, variant: "scrollable", scrollButtons: "auto", allowScrollButtonsMobile: true, sx: {
                    minHeight: "48px",
                    overflowX: "auto",
                    "& .MuiTabs-scroller": {
                        display: "flex",
                        alignItems: "center",
                        overflow: "auto",
                    },
                    "& .MuiTabs-flexContainer": {
                        display: "flex",
                        flexWrap: "nowrap",
                        gap: "8px",
                    },
                    "& .MuiTabScrollButton-root.Mui-disabled": {
                        display: "none",
                    },
                } }, requestStages.map((item, index) => (React.createElement(Box, { key: item.stage, sx: { display: "flex", alignItems: "center" } },
                React.createElement(Tab, { label: item.text, disabled: true, className: index === activeTabIndex ? "active-tab" : "", sx: {
                        minWidth: "350px",
                        textAlign: "center",
                        flexShrink: 0,
                    } }),
                index < requestStages.length - 1 && React.createElement(Divider, { orientation: "vertical", flexItem: true, sx: { height: "48px", mx: 1 } }))))))));
};
export default RequestDetailedHeader;
