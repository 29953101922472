import React, { useMemo } from "react";
import { PageContentBox } from "../../../common/page-content-box";
import ReportResultsTable from "../../../sod-reports/report-results/report-results-table";
import ReportResultsTabs from "../../../sod-reports/report-results/report-results-tabs";
import { useIntl } from "react-intl";
import { REPORT_LEVELS } from "../../../../utils/sodReports";
import { setReportSearchString as setSearchStringAction, setReportPageNumber as setPageNumberAction, setReportLimitNumber as setLimitNumberAction, setReportSortData as setSortDataAction, setReportFilterData as setFilterDataAction, setError as setErrorAction, setReportSelectedIds as setSelectedIdsAction, fetchRequestReportResult, fetchRequestReportResultColumns, setReportLevelWithColumns, setReportTypeWithColumns, setConflictLevelWithColumns,
// calcRequestReport,
 } from "../../../../reducers/administration/requestSlice";
import useReportReduxTable from "../../../common/hooks/useReportReduxTable";
import useReduxValue from "../../../common/hooks/useReduxValue";
import { REPORT_MODELING_CONFLICT_LEVELS } from "../../../../utils/reports-modeling-utils";
import ConflictLevelsPicker from "../../../reports-modeling/conflict-levels-picker";
import { useSelector } from "react-redux";
import { ProgressDialog } from "../../../common/dialogs";
const RequestDetailedConflictsTab = () => {
    const intl = useIntl();
    const tabs = useMemo(() => [
        {
            id: REPORT_LEVELS.OPERATIONS,
            label: intl.formatMessage({ id: "report-results.tabs.operations-level" }),
        },
        {
            id: REPORT_LEVELS.PERMISSIONS,
            label: intl.formatMessage({ id: "report-results.tabs.permissions-level" }),
        },
        {
            id: REPORT_LEVELS.CRITICAL_OPERATIONS,
            label: intl.formatMessage({ id: "report-results.tabs.critical-operations-level" }),
        },
        {
            id: REPORT_LEVELS.CRITICAL_PERMISSIONS,
            label: intl.formatMessage({ id: "report-results.tabs.critical-permissions-level" }),
        },
        {
            id: REPORT_LEVELS.CRITICAL_OBJECTS,
            label: intl.formatMessage({ id: "report-results.tabs.critical-objects-level" }),
        },
    ], [intl]);
    const [conflictLevel, setConflictLevel] = useReduxValue((state) => state.administration.requests.reportState.conflictLevel, setConflictLevelWithColumns);
    // const currentRequest = useSelector((state: RootState) => state.administration.requests.currentRequest);
    // useEffect(() => {
    //     if (currentRequest?.hasRisks && !currentRequest.reportReady) {
    //         dispatch(calcRequestReport(currentRequest.requestId));
    //     }
    // }, []);
    const conflictLevels = useMemo(() => {
        //  if(isComplex){
        //     return Object.values({
        //         ROLE: "role",
        //         USERNAME: "username"
        //  })
        // }
        return Object.values(REPORT_MODELING_CONFLICT_LEVELS);
    }, []);
    const busyType = useSelector((state) => state.administration.requests.busyType);
    const { rows, columns, total, busy, selectedIds, searchString, setSearchString, pageNumber, setPageNumber, limitNumber, setLimitNumber, filterData, setFilterData, sortData, setSortData, reportLevel, setReportLevel, reportType, setReportType, } = useReportReduxTable((state) => state.administration.requests.reportState.rows, fetchRequestReportResult, (state) => state.administration.requests.reportState.columns, fetchRequestReportResultColumns, (state) => state.administration.requests.reportState.selectedIds, setSelectedIdsAction, (state) => state.administration.requests.reportState.searchString, setSearchStringAction, (state) => state.administration.requests.reportState.pageNumber, setPageNumberAction, (state) => state.administration.requests.reportState.limitNumber, setLimitNumberAction, (state) => state.administration.requests.reportState.sortData, setSortDataAction, (state) => state.administration.requests.reportState.filterData, setFilterDataAction, (state) => state.administration.requests.error, setErrorAction, (state) => state.administration.requests.reportState.total, (state) => state.administration.requests.busy, (state) => state.administration.requests.reportState.reportLevel, setReportLevelWithColumns, (state) => state.administration.requests.reportState.reportType, setReportTypeWithColumns);
    return (React.createElement(React.Fragment, null,
        React.createElement(PageContentBox, null,
            React.createElement(ConflictLevelsPicker, { value: conflictLevel, conflictLevels: conflictLevels, onChange: setConflictLevel }),
            React.createElement(ReportResultsTabs, { tabs: tabs, activeTabId: reportLevel, onActiveTabIdChange: setReportLevel }),
            React.createElement(ReportResultsTable, { reportType: reportType, reportLevel: reportLevel, setReportType: setReportType, rows: rows, columns: columns, total: total, selectedIds: selectedIds, searchString: searchString, setSearchString: setSearchString, pageNumber: pageNumber, setPageNumber: setPageNumber, limitNumber: limitNumber, setLimitNumber: setLimitNumber, filterData: filterData, setFilterData: setFilterData, sortData: sortData, setSortData: setSortData, filterId: "role-report", conflictLevel: conflictLevel, activeTabId: reportLevel })),
        React.createElement(ProgressDialog, { busyType: busyType, open: busy, title: "", progress: "" })));
};
export default RequestDetailedConflictsTab;
