import React from "react";
import CommonTable, { createRowCellContentInner } from "../../../common/table";
const columns = [
    {
        id: "operation",
        title: "Операция",
        type: "string",
        sortable: false,
        filterable: true,
    },
    {
        id: "author",
        title: "Автор изменения",
        type: "string",
        sortable: false,
        filterable: true,
    },
    {
        id: "createdAt",
        title: "Дата изменения",
        type: "datetime",
        sortable: false,
        filterable: true,
    },
    {
        id: "oldValue",
        title: "Стар. значение",
        type: "string",
        sortable: false,
        filterable: true,
    },
    {
        id: "newValue",
        title: "Новое значение",
        type: "string",
        sortable: false,
        filterable: true,
    },
    {
        id: "aditionalInfo",
        title: "Дополнительная информация",
        type: "string",
        sortable: false,
        filterable: true,
    },
];
const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
    switch (column.id) {
        default:
            return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
    }
};
const getCellValue = (row, column) => {
    switch (column.id) {
        default:
            return row[column.id];
    }
};
const getTableRowId = (row) => row.id;
const RequestDetailedHistoryTab = ({ filterData, setFilterData, getDialogFilters, requestHistory, total, pageNumber, limitNumber, setLimitNumber, setPageNumber, }) => {
    const toolbarActions = [];
    return (React.createElement(React.Fragment, null,
        React.createElement(CommonTable, { rows: requestHistory, columns: columns, toolbarActions: toolbarActions, getRowId: getTableRowId, getCellValue: getCellValue, useKeyboardNavigation: false, toolbar: [], showFiltersActions: false, createRowCellContent: createRowCellContent, setFilterData: setFilterData, getFilters: getDialogFilters, filterData: filterData, total: total, limitNumber: limitNumber, setPageNumber: setPageNumber, pageNumber: pageNumber, setLimitNumber: setLimitNumber })));
};
export default RequestDetailedHistoryTab;
