var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useMemo, useCallback, useState } from "react";
import { setSelectedRoles, removeSelectedRolesFromRequest, saveRequest, calcRequestReport } from "../../../../reducers/administration/requestSlice";
import { useIntl } from "react-intl";
import CommonTable from "../../../common/table";
import { createRowCellContentInner } from "../../../common/table";
import { Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import AddRoleToRequestDialog from "./add-role-to-request-dialog";
import "./styles.less";
const columns = [
    {
        id: "select",
        type: "select",
    },
    {
        id: "role",
        title: "Имя роли",
        type: "string",
        sortable: true,
        filterable: true,
    },
    {
        id: "groupId",
        title: "Ландшафт",
        type: "string",
        sortable: true,
        filterable: true,
    },
    {
        id: "roleType",
        title: "Тип Роли",
        type: "string",
        sortable: true,
        filterable: true,
    },
    {
        id: "updatedAt",
        title: "Последнее обнолвние",
        type: "datetime",
        sortable: true,
        filterable: true,
    },
    {
        id: "updatedBy",
        title: "Обновил",
        type: "string",
        sortable: true,
        filterable: true,
    },
];
const RequestDetailedRolesTable = () => {
    const { requestId: requestId } = useParams();
    const intl = useIntl();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const roles = useSelector((state) => state.administration.requests.rolesForView.roles);
    const selectedRoles = useSelector((state) => state.administration.requests.rolesForView.selectedRoles);
    const currentStage = useSelector((state) => { var _a; return (_a = state.administration.requests.currentRequest) === null || _a === void 0 ? void 0 : _a.currentStage; });
    const groupId = useSelector((state) => { var _a; return (_a = state.administration.requests.currentRequest) === null || _a === void 0 ? void 0 : _a.groupId; });
    const [addRoleDialogOpen, setAddRoleDialogOpen] = useState(false);
    const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
        switch (column.id) {
            case "roleType": {
                return React.createElement(Typography, null, `${row.isComplex ? "Групповая" : "Одиночная"}`);
            }
            default:
                return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
        }
    };
    const handleRemoveSelectedRoles = () => {
        dispatch(removeSelectedRolesFromRequest());
    };
    const handleCreateRole = () => {
        navigate(`create-new-role`, { relative: "path" });
    };
    const toolbarActions = useMemo(() => {
        if (currentStage !== "ROLE_DEFINITION") {
            return [];
        }
        return [
            {
                id: "create",
                title: intl.formatMessage({
                    id: "request-roles-table.toolbar.btn-create-roles",
                }),
                position: "begin",
                callback: handleCreateRole,
            },
            {
                id: "add",
                title: intl.formatMessage({
                    id: "request-roles-table.toolbar.btn-add-role",
                }),
                position: "begin",
                callback: () => {
                    setAddRoleDialogOpen(true);
                },
            },
            // {
            //     id: "import",
            //     title: intl.formatMessage({
            //         id: "request-roles-table.toolbar.btn-import-roles",
            //     }),
            //     position: "begin",
            //     callback: () => {},
            // },
            {
                id: "conflicts",
                title: intl.formatMessage({
                    id: "request-roles-table.toolbar.btn-conflicts",
                }),
                position: "begin",
                callback: () => __awaiter(void 0, void 0, void 0, function* () {
                    if (requestId) {
                        yield dispatch(saveRequest()).unwrap();
                        dispatch(calcRequestReport(requestId));
                    }
                }),
            },
            {
                id: "delete",
                title: intl.formatMessage({
                    id: "request-roles-table.toolbar.btn-delete-role",
                }),
                position: "end",
                callback: handleRemoveSelectedRoles,
            },
            // {
            //     id: "derivation",
            //     title: intl.formatMessage({
            //         id: "request-roles-table.toolbar.btn-derivation",
            //     }),
            //     position: "end",
            //     callback: () => {},
            // },
        ];
    }, [currentStage]);
    const getCellValue = (row, column) => {
        var _a;
        switch (column.id) {
            default:
                return `${(_a = row[column.id]) !== null && _a !== void 0 ? _a : ""}`;
        }
    };
    const handleSelectRow = useCallback((row, value) => {
        dispatch(setSelectedRoles(value ? [...selectedRoles, `${row.id}`] : selectedRoles.filter((id) => id !== `${row.id}`)));
    }, [selectedRoles, dispatch]);
    const handleSelectAll = useCallback((value) => {
        dispatch(setSelectedRoles(value ? roles.map((role) => String(role.id)) : []));
    }, [roles, dispatch]);
    return (React.createElement(React.Fragment, null,
        React.createElement(CommonTable, { className: "request-roles-table", rows: roles, columns: columns, toolbarActions: toolbarActions, showPagination: false, showFiltersActions: false, getCellValue: getCellValue, createRowCellContent: createRowCellContent, onRowClick: () => { }, onSelectAll: handleSelectAll, onSelectRow: handleSelectRow, selectedIds: selectedRoles, setFilterData: () => { }, useKeyboardNavigation: false, toolbar: [] }),
        React.createElement(AddRoleToRequestDialog, { open: addRoleDialogOpen, onClose: () => setAddRoleDialogOpen(false), groupId: groupId !== null && groupId !== void 0 ? groupId : "" })));
};
export default RequestDetailedRolesTable;
