// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React from "react";
import { useIntl } from "react-intl";
import { EntityFormHeaderTitle } from "../../../common/entity-form";
const CreateRoleForRequestFormTitle = ({ onBackClick }) => {
    const intl = useIntl();
    return (React.createElement(EntityFormHeaderTitle, { title: intl.formatMessage({ id: "create-new-role-for-request-page-header.title" }), onBackClick: onBackClick }));
};
export default CreateRoleForRequestFormTitle;
