var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { createSlice, createAsyncThunk, createEntityAdapter, nanoid } from "@reduxjs/toolkit";
import produce from "immer";
import { requestsService } from "../../services/requests-service";
import { BUSY_TYPES } from "../../utils/busy";
import { REQUESTS_TYPE } from "../../utils/brm-utils";
import { reportsModelingService } from "../../services/reports-modeling-service";
import { getSelectedRoleReducers } from "../reports-modeling/selectedRoleReducers";
import { getDefaultPermissionsValuesForTransactions, getDefaultPermissionsValuesForTransactionsMap, getDeletedTcodesFromMenu, getTcodesMapCountFromMenu, getTransactionsByIdsForSystemsGroup, getTransactionFromRoleMenu, } from "../../utils/role-menu";
import { findFieldInTree, findItemInTree, TreeWalker } from "../../utils/tree-table";
import { getDeletedDefaultValuesForTcodes, recalcRoleTreeTrafficLightStatus, REPORT_MODELING_CONFLICT_LEVELS } from "../../utils/reports-modeling-utils";
import { addGroupRoleChildren } from "../../utils/group-role-utils";
import { REPORT_LEVELS, REPORT_TYPES } from "../../utils/sodReports";
const modelingRoles = createEntityAdapter();
const initialState = {
    requests: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    validationErrors: null,
    requestsType: REQUESTS_TYPE.ALL,
    requestStatuses: null,
    currentRequest: null,
    requestStages: [],
    rolesForView: {
        roles: [],
        columns: [],
        total: 0,
        selectedRoles: [],
        searchString: "",
        pageNumber: 1,
        limitNumber: 10,
        sortData: [],
        filterData: [],
    },
    reportState: {
        rows: [],
        columns: [],
        total: 0,
        pageNumber: 1,
        limitNumber: 10,
        filterData: [],
        sortData: [],
        searchString: "",
        selectedIds: [],
        reportLevel: REPORT_LEVELS.OPERATIONS,
        reportType: REPORT_TYPES.FINAL,
        conflictLevel: REPORT_MODELING_CONFLICT_LEVELS.ROLE,
    },
    modeling: {
        selectedRoleId: "",
        roles: modelingRoles.getInitialState(),
    },
};
const createRoleForModeling = (role, groupId) => {
    // let profile = null;
    // if (role.isNew) {
    //     profile = "profile" in role ? role.profile || role.role : role.role
    // }
    return {
        id: nanoid(),
        role: role.role,
        groupId: groupId,
        initialized: false,
        isComplex: role.isComplex,
        menu: reportsModelingService.parseRoleMenu([]),
        children: [],
        tree: [],
        orgLevels: {},
        delta: [],
        orgLevelsDelta: [],
        manual: "manual" in role ? role.manual || false : false,
        profile: "profile" in role ? role.profile || role.role : role.role,
        changed: "changed" in role ? role.changed || false : false,
    };
};
export const fetchRequests = createAsyncThunk("requests/fetchRequests", (_a) => __awaiter(void 0, [_a], void 0, function* ({ params }) {
    const response = yield requestsService.getRequests(params);
    return response;
}));
export const fetchRequestsColumns = createAsyncThunk("requests/fetchRequestsColumns", () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield requestsService.getRequestsColumns();
    return response;
}));
export const createNewRequest = createAsyncThunk("requests/createNewRequest", (requestData_1, _a) => __awaiter(void 0, [requestData_1, _a], void 0, function* (requestData, { rejectWithValue }) {
    try {
        const response = yield requestsService.createNewRequest(requestData);
        return response.data;
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const fetchRequestStatuses = createAsyncThunk("requests/fetchRequestStatusesResponse", () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield requestsService.getRequestsStatuses();
    return response;
}));
export const fetchRequestDetailed = createAsyncThunk("requests/fetchRequestDetailed", (requestId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield requestsService.getRequestDetailed(requestId);
    return response;
}));
//@ts-expect-error add type
export const setModelingSelectedRole = createAsyncThunk("requests/setModelingSelectedRole", (role_1, _a) => __awaiter(void 0, [role_1, _a], void 0, function* (role, { getState }) {
    var _b;
    const state = getState();
    //@ts-expect-error add type
    const modelingRole = selectModelingRoleById(state, role.id);
    if (modelingRole === null || modelingRole === void 0 ? void 0 : modelingRole.initialized) {
        return modelingRole;
    }
    const requestId = (_b = state.administration.requests.currentRequest) === null || _b === void 0 ? void 0 : _b.requestId;
    //@ts-expect-error add type
    const { menu, tree, orgLevels, childrens = [], profile } = yield requestsService.getDetaliedRequestRole(requestId, role === null || role === void 0 ? void 0 : role.groupId, role === null || role === void 0 ? void 0 : role.role);
    if (tree && orgLevels) {
        recalcRoleTreeTrafficLightStatus(tree, orgLevels);
    }
    return Object.assign(Object.assign({}, role), { initialized: true, profile: profile, delta: [], orgLevelsDelta: [], tree: tree !== null && tree !== void 0 ? tree : [], orgLevels: orgLevels !== null && orgLevels !== void 0 ? orgLevels : {}, menu: menu, children: childrens });
}));
export const moveToRejectStep = createAsyncThunk("request/moveToRejectStep", (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ requestId, actionComment }, { rejectWithValue }) {
    try {
        const response = yield requestsService.moveToRejectStep(requestId, actionComment);
        return response;
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const moveToApproveStep = createAsyncThunk("request/moveToApproveStep", (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ requestId, actionComment, riskCheck = true }, { rejectWithValue }) {
    try {
        const response = yield requestsService.moveToApproveStep(requestId, actionComment, riskCheck);
        return response;
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const addRoleMenuNodeWithDefaultValues = 
//@ts-expect-error add type
({ id, activeNodePath, tcodes }) => 
//@ts-expect-error add type
(dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    let state = getState();
    let selectedRole = selectModelingRoleById(state, id);
    if (!selectedRole) {
        return;
    }
    const transactions = yield getTransactionsByIdsForSystemsGroup(selectedRole.groupId, tcodes);
    dispatch(addRoleMenuNode({
        id,
        activeNodePath,
        tcodes,
        transactions,
    }));
    state = getState();
    selectedRole = selectModelingRoleById(state, id);
    const tcodesMapMenu = getTcodesMapCountFromMenu(selectedRole === null || selectedRole === void 0 ? void 0 : selectedRole.menu);
    //@ts-expect-error add type
    const tcodesForDefaultPermissions = tcodes.filter((tcode) => tcodesMapMenu[tcode] && tcodesMapMenu[tcode] > 0);
    tcodesForDefaultPermissions.forEach((tcode) => __awaiter(void 0, void 0, void 0, function* () {
        yield dispatch(addDefaultPermissionsForMenuUpdate({
            id,
            transactionIds: [tcode],
        })).unwrap();
    }));
    // if (tcodesForDefaultPermissions.length > 0) {
    //     dispatch(
    //         addDefaultPermissionsForMenuUpdate({
    //             id,
    //             transactionIds: tcodesForDefaultPermissions,
    //         })
    //     );
    // }
});
export const addDefaultPermissionsForMenuUpdate = createAsyncThunk("requests/addDefaultPermissionsForMenuUpdate", (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ id, transactionIds }, { getState, dispatch }) {
    var _c;
    const state = getState();
    const role = selectModelingRoleById(state, id);
    if (!role || !(transactionIds === null || transactionIds === void 0 ? void 0 : transactionIds.length)) {
        return;
    }
    const groupId = (_c = state.administration.requests.currentRequest) === null || _c === void 0 ? void 0 : _c.groupId;
    const newPermissionsTree = yield requestsService.getPfcgTreeForTcodes(groupId, transactionIds);
    const defaultValues = yield getDefaultPermissionsValuesForTransactions(null, transactionIds, groupId);
    dispatch(addModelingRoleTreeItems({
        id,
        items: newPermissionsTree,
        defaultValues,
        tcodes: transactionIds,
        isStandart: true,
    }));
}));
export const removeGroupRoleChildFromModeling = createAsyncThunk("requests/removeGroupRoleChildFromModeling", (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ roleIdToRemove }, { getState, dispatch }) {
    const state = getState();
    const selectedRoleId = state.administration.requests.modeling.selectedRoleId;
    const groupRole = state.administration.requests.modeling.roles.entities[selectedRoleId];
    const updatedChildren = groupRole === null || groupRole === void 0 ? void 0 : groupRole.children.filter((child) => child.role !== roleIdToRemove);
    dispatch(updateModelingRole({
        id: selectedRoleId,
        changes: { children: updatedChildren },
    }));
    // const updatedRoles = roles
    // 	.map((role) => {
    // 		if (role.isComplex) {
    // 			return {
    // 				...role,
    // 				changed: true,
    // 				children: removeGroupRoleChildren(roleIdToRemove, role.children),
    // 			};
    // 		}
    // 		return role;
    // 	})
    // 	.filter((role) => role.id !== roleIdToRemove);
    // dispatch(removeRoleFromModeling(roleIdToRemove));
}));
export const addGroupRoleChildToModeling = createAsyncThunk("requests/addGroupRoleChildToModeling", (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ newChildRole }, { getState, dispatch }) {
    const state = getState();
    const selectedRoleId = state.administration.requests.modeling.selectedRoleId;
    const groupRole = state.administration.requests.modeling.roles.entities[selectedRoleId];
    const childRoleId = nanoid();
    const updatedChildren = addGroupRoleChildren(newChildRole, childRoleId, groupRole === null || groupRole === void 0 ? void 0 : groupRole.children).map((child) => (Object.assign(Object.assign({}, child), { isChild: false })));
    dispatch(updateModelingRole({
        id: selectedRoleId,
        changes: { children: updatedChildren },
    }));
}));
//@ts-expect-error add type
export const removeMenuItemFromTree = createAsyncThunk("requests/removeMenuItem", (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ menuItemPath, id }, { getState, dispatch }) {
    const state = getState();
    //@ts-expect-error add type
    const selectedRole = selectModelingRoleById(state, id);
    if (!selectedRole) {
        return;
    }
    const removedMenuItem = findItemInTree(menuItemPath, selectedRole.menu);
    const tcodesMapItem = getTcodesMapCountFromMenu([removedMenuItem]);
    dispatch(removeRoleMenuNode({
        id: selectedRole.id,
        activeNodePath: menuItemPath,
    }));
    const tcodesMapMenu = getTcodesMapCountFromMenu(selectedRole.menu);
    const tcodeToValue = yield getDefaultPermissionsValuesForTransactionsMap(null, Object.keys(tcodesMapMenu), selectedRole.groupId);
    const { deletedTcodes, remainedTcodes, deletedAtBranchTcodes } = getDeletedTcodesFromMenu(tcodesMapItem, tcodesMapMenu);
    try {
        //@ts-expect-error add type
        const { orgLevelsForDelete, profilesForDelete } = getDeletedDefaultValuesForTcodes(deletedTcodes, remainedTcodes, tcodeToValue, deletedAtBranchTcodes);
        dispatch(substractPfcgTreeFromRole({
            id,
            orgLevelsForDelete,
            profilesForDelete,
            removedTcodes: deletedAtBranchTcodes,
        }));
    }
    catch (error) {
        console.log(error);
    }
}));
export const copyRoleMenuWithDefaultValues = 
//@ts-expect-error add type
({ id, menuToCopy }) => 
//@ts-expect-error add type
(dispatch) => {
    if (!(menuToCopy === null || menuToCopy === void 0 ? void 0 : menuToCopy.length)) {
        return;
    }
    dispatch(copyRoleMenu({
        id,
        menuToCopy,
    }));
    const transactionIds = getTransactionFromRoleMenu(menuToCopy[0].children);
    dispatch(addDefaultPermissionsForMenuUpdate({
        id,
        transactionIds,
    }));
};
export const findPermissionInTree = createAsyncThunk("requests/findPermissionInTree", (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ searchString }, { getState, dispatch }) {
    const state = getState();
    const roleId = state.administration.requests.modeling.selectedRoleId;
    const role = state.administration.requests.modeling.roles.entities[roleId];
    if (role && role.tree && role.tree.length) {
        const updatedTree = produce(role.tree, (tree) => {
            findFieldInTree(tree, searchString);
        });
        dispatch(updateModelingRole({
            id: roleId,
            changes: { tree: updatedTree },
        }));
    }
}));
export const saveRequest = createAsyncThunk("requests/saveRequest", (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { getState, dispatch, rejectWithValue }) {
    var _b;
    const state = getState();
    const calcRoles = [...Object.values(state.administration.requests.modeling.roles.entities)];
    // @ts-expect-error add type
    const rolesData = [];
    calcRoles.forEach((role) => {
        var _a, _b, _c, _d;
        const parsedRole = reportsModelingService.parseRoleToTreeVariant(role);
        rolesData.push(Object.assign({ request_id: (_a = state.administration.requests.currentRequest) === null || _a === void 0 ? void 0 : _a.requestId, role: role === null || role === void 0 ? void 0 : role.role, group_id: (_b = state.administration.requests.currentRequest) === null || _b === void 0 ? void 0 : _b.groupId, description: (role === null || role === void 0 ? void 0 : role.description) || "", is_changed: role === null || role === void 0 ? void 0 : role.initialized, is_complex: role === null || role === void 0 ? void 0 : role.isComplex, org_level_delta: parsedRole.org_levels, delta: parsedRole.delta, menu_tree: parsedRole.menu_tree, children: parsedRole.children, is_new: (role === null || role === void 0 ? void 0 : role.isNew) ? true : false }, ((role === null || role === void 0 ? void 0 : role.isNew) && {
            owner: {
                username: (_c = role.roleOwner) !== null && _c !== void 0 ? _c : "",
                email: "",
            },
            deputy_owner: {
                username: (_d = role.deputyRoleOwner) !== null && _d !== void 0 ? _d : "",
                email: "",
            },
        })));
    });
    const form = {
        request_id: (_b = state.administration.requests.currentRequest) === null || _b === void 0 ? void 0 : _b.requestId,
        roles: rolesData,
    };
    try {
        yield requestsService.saveRequestRoles(form.roles, form.request_id);
    }
    catch (error) {
        return rejectWithValue(error);
    }
    yield dispatch(resetRoles());
    const currentRole = selectModelingSelectedRole(state);
    if (currentRole) {
        yield dispatch(setModelingSelectedRole(currentRole));
    }
}));
export const saveRequestComment = createAsyncThunk("request/saveRequestComment", (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ comment }, { getState }) {
    var _c, _d;
    const state = getState();
    const currentUser = (_c = state === null || state === void 0 ? void 0 : state.auth) === null || _c === void 0 ? void 0 : _c.user.username;
    const requestId = (_d = state.administration.requests.currentRequest) === null || _d === void 0 ? void 0 : _d.requestId;
    const currentDate = new Date();
    const requestCommentData = {
        author: currentUser,
        comment: comment,
        created_at: currentDate,
    };
    // const response = await requestsService.saveRequestComment(requestCommentData, requestId )
    yield requestsService.saveRequestComment(requestCommentData, requestId);
    return {
        author: currentUser,
        comment: comment,
        createdAt: currentDate,
    };
}));
export const saveTestingData = createAsyncThunk("requests/saveTestingData", (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ form, requestId, riskCheck, approveWithConflictsMessage = "" }, { rejectWithValue }) {
    try {
        const response = yield requestsService.saveTestingData(form, requestId, riskCheck, approveWithConflictsMessage);
        return response;
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const getRequestStages = createAsyncThunk("requests/getRequestStage", () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield requestsService.getRequestStages();
    return response;
}));
const selectedRoleReducers = getSelectedRoleReducers((state) => state.modeling.roles, modelingRoles, (state, role) => {
    const requestRoles = state.rolesForView.roles;
    const changedRole = requestRoles.find((item) => item.id === role.id);
    if (changedRole) {
        changedRole.changed = true;
    }
    // requestRoles.forEach((r) => {
    //   if (r.isComplex) {
    //     r.children.forEach((child) => {
    //       if (child.id == role.id) {
    //         child.changed = true;
    //       }
    //     });
    //   }
    // });
    role.changed = true;
}, false);
export const calcRequestReport = createAsyncThunk("requests/calcRequestReport", (requestId_1, _a) => __awaiter(void 0, [requestId_1, _a], void 0, function* (requestId, { rejectWithValue }) {
    try {
        const response = yield requestsService.calcRequestReport(requestId);
        return response;
    }
    catch (error) {
        return rejectWithValue(error);
    }
}));
export const fetchRequestReportResult = createAsyncThunk("requests/fetchRequestReportResult", (_a, _b) => __awaiter(void 0, [_a, _b], void 0, function* ({ params }, { getState }) {
    var _c;
    const state = getState();
    // if (!state.reportsModeling.roleLevel.canFetchResults) {
    //     return;
    // }
    const conflictLevel = state.administration.requests.reportState.conflictLevel;
    const requestId = (_c = state.administration.requests.currentRequest) === null || _c === void 0 ? void 0 : _c.requestId;
    const newParams = Object.assign(Object.assign({}, params), { report_level: state.administration.requests.reportState.reportLevel, report_type: state.administration.requests.reportState.reportType, conflict_level: conflictLevel });
    // const response = conflictLevel === REPORT_MODELING_CONFLICT_LEVELS.USERNAME
    //     ? await reportsModelingService.getUserLevelReportResults(newParams)
    //     : await reportsModelingService.getRoleLevelReportResults(newParams);
    const response = yield requestsService.fetchRequestReportResult(requestId, newParams);
    return response;
}));
export const fetchRequestReportResultColumns = createAsyncThunk("requests/fetchRequestReportResultColumns", (_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { getState }) {
    const state = getState();
    const conflictLevel = state.administration.requests.reportState.conflictLevel;
    const columns = conflictLevel === REPORT_MODELING_CONFLICT_LEVELS.USERNAME
        ? yield reportsModelingService.getUserLevelModelingColumns(state.administration.requests.reportState.reportLevel, state.administration.requests.reportState.reportType)
        : yield reportsModelingService.getRoleLevelModelingColumns(state.administration.requests.reportState.reportLevel, state.administration.requests.reportState.reportType);
    // const columns =
    return columns;
}));
export const setReportLevelWithColumns = (reportLevel) => (dispatch) => {
    dispatch(setReportLevel(reportLevel));
    dispatch(fetchRequestReportResultColumns());
};
export const setReportTypeWithColumns = (reportType) => (dispatch) => {
    dispatch(setReportType(reportType));
    dispatch(fetchRequestReportResultColumns());
};
export const setConflictLevelWithColumns = (conflictLevel) => (dispatch) => {
    dispatch(setReportConflictLevel(conflictLevel));
    dispatch(fetchRequestReportResultColumns());
};
const requestsSlice = createSlice({
    name: "requests",
    initialState,
    reducers: Object.assign({ resetRoles: (state) => {
            const roles = Object.values(state.modeling.roles.entities);
            roles.forEach((role) => {
                role === null || role === void 0 ? void 0 : role.initialized = false;
                role === null || role === void 0 ? void 0 : role.changed = false;
                role === null || role === void 0 ? void 0 : role.orgLevelsDelta = [];
                role === null || role === void 0 ? void 0 : role.delta = [];
            });
            modelingRoles.setAll(state.modeling.roles, roles);
        }, updateModelingRole: (state, action) => {
            modelingRoles.updateOne(state.modeling.roles, {
                id: action.payload.id,
                changes: action.payload.changes,
            });
        }, removeSelectedRolesFromRequest: (state) => {
            const selectedRoleIds = state.rolesForView.selectedRoles;
            //@ts-expect-error add type
            state.rolesForView.roles = state.rolesForView.roles.filter((role) => !selectedRoleIds.includes(role.id));
            modelingRoles.removeMany(state.modeling.roles, selectedRoleIds);
            const remainingRoleNames = new Set(state.rolesForView.roles.map((r) => r.role));
            state.currentRequest.roles = state.currentRequest.roles.filter((r) => remainingRoleNames.has(r.role));
            if (!state.rolesForView.roles.length) {
                state.currentRequest.groupId = "";
            }
            state.rolesForView.selectedRoles = [];
        }, setValidationErrors(state, action) {
            state.validationErrors = action.payload;
        },
        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },
        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },
        setSelectedRoles(state, action) {
            state.rolesForView.selectedRoles = action.payload;
        },
        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },
        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },
        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },
        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setRequestsType(state, action) {
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
            state.requestsType = action.payload;
        },
        addRolesToRequest(state, action) {
            var _a, _b, _c;
            const { roles, groupId } = action.payload;
            const parsedModelingRoles = {};
            const existingRoleNames = new Set(state.rolesForView.roles.map((role) => role.role));
            (((_a = state.currentRequest) === null || _a === void 0 ? void 0 : _a.roles) || []).forEach((role) => {
                if (role.groupId !== groupId) {
                    throw Error();
                }
            });
            const uniqueRoles = roles
                .filter((role) => !existingRoleNames.has(role.role))
                .map((role) => {
                const roleExtended = createRoleForModeling(role, groupId);
                parsedModelingRoles[roleExtended.id] = roleExtended;
                parsedModelingRoles[roleExtended.id].isNew = (role === null || role === void 0 ? void 0 : role.isNew) ? true : false;
                if (role === null || role === void 0 ? void 0 : role.isNew) {
                    parsedModelingRoles[roleExtended.id].roleOwner = role.roleOwner;
                    parsedModelingRoles[roleExtended.id].deputyRoleOwner = role.deputyRoleOwner;
                    parsedModelingRoles[roleExtended.id].initialized = true;
                }
                return roleExtended;
            });
            modelingRoles.addMany(state.modeling.roles, parsedModelingRoles);
            state.rolesForView.roles = [...state.rolesForView.roles, ...uniqueRoles];
            state.currentRequest.groupId = groupId;
            (_b = state.currentRequest) === null || _b === void 0 ? void 0 : _b.roles = [...(((_c = state.currentRequest) === null || _c === void 0 ? void 0 : _c.roles) || []), ...uniqueRoles];
        },
        expandItemTree(state, action) {
            const roleId = state.modeling.selectedRoleId;
            const role = state.modeling.roles.entities[roleId];
            const item = findItemInTree(action.payload.path, role === null || role === void 0 ? void 0 : role.tree);
            item.open = !item.open;
            const treeWalker = new TreeWalker(item.children);
            treeWalker.walk((node) => {
                node.open = item.open;
            }, () => {
                return {
                    keyField: "",
                    keyValue: "",
                    childField: "children",
                };
            }, () => { });
        },
        setReportLevel(state, action) {
            state.reportState.reportLevel = action.payload;
        },
        setReportType(state, action) {
            state.reportState.reportType = action.payload;
        },
        setReportConflictLevel(state, action) {
            state.reportState.conflictLevel = action.payload;
        },
        setReportPageNumber(state, action) {
            state.reportState.pageNumber = action.payload;
        },
        setReportLimitNumber(state, action) {
            state.reportState.limitNumber = action.payload;
            state.reportState.pageNumber = 1;
        },
        setReportSortData(state, action) {
            state.reportState.sortData = action.payload;
            state.reportState.pageNumber = 1;
        },
        setReportFilterData(state, action) {
            state.reportState.filterData = action.payload;
            state.reportState.pageNumber = 1;
        },
        setReportSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.reportState.pageNumber = 1;
                state.reportState.searchString = action.payload;
            }
        },
        setReportSelectedIds(state, action) {
            state.reportState.selectedIds = action.payload;
        } }, selectedRoleReducers),
    extraReducers: (builder) => {
        builder
            .addCase(fetchRequests.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        })
            .addCase(fetchRequests.rejected, (state, action) => {
            state.busy = false;
            //@ts-expect-error: add type to error
            state.error = action.error.message || null;
        })
            .addCase(fetchRequests.fulfilled, (state, action) => {
            state.busy = false;
            state.requests = action.payload.rows;
            state.total = action.payload.total;
            state.error = null;
        })
            .addCase(fetchRequestsColumns.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        })
            .addCase(fetchRequestsColumns.fulfilled, (state, action) => {
            state.busy = false;
            state.columns = action.payload;
        })
            .addCase(fetchRequestsColumns.rejected, (state, action) => {
            state.busy = false;
            //@ts-expect-error: add type to error
            state.error = action.error.message || null;
        })
            .addCase(fetchRequestStatuses.fulfilled, (state, action) => {
            state.requestStatuses = action.payload;
        })
            .addCase(fetchRequestDetailed.fulfilled, (state, action) => {
            const roles = action.payload.roles;
            state.currentRequest = action.payload;
            const parsedModelingRoles = {};
            roles.forEach((role) => {
                role.id = nanoid();
                const roleExtended = createRoleForModeling(role, role.groupId);
                parsedModelingRoles[roleExtended.id] = roleExtended;
                role.id = roleExtended.id;
                state.currentRequest.groupId = role.groupId;
            });
            modelingRoles.setAll(state.modeling.roles, parsedModelingRoles);
            state.rolesForView.roles = roles;
            state.busy = false;
        })
            .addCase(fetchRequestDetailed.rejected, (state, action) => {
            //@ts-expect-error: add type to error
            state.busy = false;
            state.error = action.error.message || null;
        })
            .addCase(fetchRequestDetailed.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        })
            .addCase(getRequestStages.fulfilled, (state, action) => {
            state.requestStages = action.payload;
        })
            .addCase(getRequestStages.rejected, (state, action) => {
            state.error = action.error;
        })
            .addCase(setModelingSelectedRole.fulfilled, (state, action) => {
            const role = Object.assign({}, action.payload);
            state.modeling.selectedRoleId = role.id;
            if (role.isComplex && !action.payload.initialized) {
                role.children = role === null || role === void 0 ? void 0 : role.children.map((child) => (Object.assign(Object.assign({}, child), { id: nanoid() })));
            }
            role.initialized = true;
            modelingRoles.setOne(state.modeling.roles, role);
            state.busy = false;
        })
            .addCase(setModelingSelectedRole.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        })
            .addCase(setModelingSelectedRole.rejected, (state, action) => {
            //@ts-expect-error: add type to error
            state.error = action.error.message || null;
            state.busy = false;
        })
            .addCase(saveRequest.rejected, (state, action) => {
            var _a;
            const error = action.payload;
            if (error.code === "validation") {
                const duplicates = (_a = error.errors) === null || _a === void 0 ? void 0 : _a.filter((e) => e.code === "duplicate" && e.field === "roles").map((e) => e.value);
                if (duplicates === null || duplicates === void 0 ? void 0 : duplicates.length) {
                    state.error = Object.assign(Object.assign({}, error), { detail: `${duplicates.join(", ")} уже находятся в другом запросе` });
                }
                else {
                    state.error = error;
                }
            }
            state.busy = false;
        })
            .addCase(saveRequest.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        })
            .addCase(saveRequest.fulfilled, (state) => {
            state.busy = false;
        })
            .addCase(createNewRequest.rejected, (state, action) => {
            state.busy = false;
            const error = action.payload;
            if (!error)
                return;
            const validationCodes = new Set(["no_integr_objects", "validation"]);
            if (error.code && validationCodes.has(error.code)) {
                state.validationErrors = error.errors;
            }
            state.error = error;
        })
            .addCase(createNewRequest.fulfilled, (state) => {
            state.pageNumber =
                state.total == 0
                    ? 1
                    : Number.isInteger(state.total / state.limitNumber)
                        ? Math.floor(state.total / state.limitNumber)
                        : Math.ceil(state.total / state.limitNumber);
            state.filterData = [];
            state.sortData = [];
            state.searchString = "";
        })
            .addCase(moveToRejectStep.fulfilled, (state) => {
            state.busy = false;
        })
            .addCase(moveToRejectStep.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        })
            .addCase(moveToRejectStep.rejected, (state, action) => {
            state.busy = false;
            state.error = action.payload;
        })
            .addCase(moveToApproveStep.fulfilled, (state) => {
            state.busy = false;
        })
            .addCase(moveToApproveStep.rejected, (state, action) => {
            state.busy = false;
            state.error = action.payload;
        })
            .addCase(moveToApproveStep.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        })
            .addCase(saveTestingData.fulfilled, (state) => {
            state.busy = false;
        })
            .addCase(saveTestingData.rejected, (state, action) => {
            state.busy = false;
            state.error = action.payload;
        })
            .addCase(saveTestingData.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        })
            .addCase(calcRequestReport.fulfilled, (state) => {
            var _a;
            (_a = state.currentRequest) === null || _a === void 0 ? void 0 : _a.reportReady = true;
            state.busy = false;
        })
            .addCase(calcRequestReport.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        })
            .addCase(calcRequestReport.rejected, (state, action) => {
            var _a;
            state.error = action.error;
            (_a = state.currentRequest) === null || _a === void 0 ? void 0 : _a.reportReady = false;
            state.busy = false;
        })
            .addCase(fetchRequestReportResult.fulfilled, (state, action) => {
            state.busy = false;
            state.reportState.total = action.payload.total;
            state.reportState.rows = action.payload.rows;
        })
            .addCase(fetchRequestReportResult.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        })
            .addCase(fetchRequestReportResult.rejected, (state, action) => {
            state.busy = false;
            state.error = action.payload;
        })
            .addCase(fetchRequestReportResultColumns.fulfilled, (state, action) => {
            state.reportState.columns = action.payload;
            state.reportState.pageNumber = 1;
            state.reportState.filterData = [];
            state.reportState.sortData = [];
        })
            .addCase(saveRequestComment.fulfilled, (state, action) => {
            var _a;
            (_a = state.currentRequest) === null || _a === void 0 ? void 0 : _a.comments.push(action.payload);
            state.busy = false;
        })
            .addCase(saveRequestComment.rejected, (state, action) => {
            state.busy = false;
            state.error = action.payload;
        })
            .addCase(saveRequestComment.pending, (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        });
    },
});
export const { setSearchString, setPageNumber, setLimitNumber, setSortData, setFilterData, setError, setRequestsType, setSelectedIds, setSelectedRoles, openRoleMenuNode, addRoleMenuFolder, addRoleMenuNode, addModelingRoleTreeItems, removeRoleMenuNode, substractPfcgTreeFromRole, setModelingRoleTreeField, setModelingRoleOrgLevelValues, copyRoleMenu, expandItemTree, removeModelingRoleTreeItem, updateModelingRole, removeSelectedRolesFromRequest, addRolesToRequest, setValidationErrors, resetRoles, setReportLevel, setReportPageNumber, setReportLimitNumber, setReportSearchString, setReportFilterData, setReportSortData, setReportType, setReportSelectedIds, setReportConflictLevel, } = requestsSlice.actions;
export default requestsSlice.reducer;
export const selectModelingSelectedRole = (state) => {
    const selectedRoleId = selectModelingSelectedRoleId(state);
    if (!selectedRoleId)
        return null;
    return selectModelingRoleById(state, selectedRoleId);
};
export const selectModelingSelectedRoleId = (state) => state.administration.requests.modeling.selectedRoleId;
export const selectChangeRequestGroupId = (state) => { var _a; return (_a = state.administration.requests.currentRequest) === null || _a === void 0 ? void 0 : _a.groupId; };
export const selectChangeRequestStage = (state) => { var _a; return (_a = state.administration.requests.currentRequest) === null || _a === void 0 ? void 0 : _a.currentStage; };
export const { selectIds: selectModelingRoleIds, selectAll: selectModelingRoles, selectById: selectModelingRoleById, } = modelingRoles.getSelectors((state) => state.administration.requests.modeling.roles);
