import React, { useEffect, useMemo, useState } from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchRequests, fetchRequestsColumns, setSelectedIds as setSelectedIdsAction, setSearchString as setSearchStringAction, setPageNumber as setPageNumberAction, setLimitNumber as setLimitNumberAction, setSortData as setSortDataAction, setFilterData as setFilterDataAction, setError as setErrorAction, setRequestsType as setRequestsTypeAction, fetchRequestStatuses, setValidationErrors, } from "../../../../reducers/administration/requestSlice";
import CreateRequestDialog from "./create-request-dialog";
import useReduxValue from "../../../common/hooks/useReduxValue";
import useFilters from "../../../common/hooks/useFilters";
import CommonTable from "../../../common/table";
import useReduxTable from "../../../common/hooks/useReduxTable";
import { ProgressDialog, ErrorsDialog } from "../../../common/dialogs";
import { createRowCellContentInner } from "../../../common/table";
import { REQUESTS_TYPE } from "../../../../utils/brm-utils";
import { SuccessIcon, TimeIcon, ErrorIcon } from "../../../common/icons";
import { Box, Typography, IconButton, Tooltip, MenuItem, Popover } from "@mui/material";
//TODO: переделать иконки
import LanguageIcon from "@mui/icons-material/Language";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch } from "react-redux";
import ThreeDotsButton from "../../../common/buttons/three-dots-button";
const statusMapping = {
    ROLE_DEFINITION: {
        icon: React.createElement(TimeIcon, { color: "primary" }),
        text: "На согласовании",
    },
    rejected: {
        icon: React.createElement(ErrorIcon, { color: "error" }),
        text: "Отклонено",
    },
    approved: {
        icon: React.createElement(SuccessIcon, { color: "success" }),
        text: "Утверждено",
    },
    completed: {
        icon: React.createElement(CheckIcon, { color: "success" }),
        text: "Завершено",
    },
    TEST_APPROVEMENT: {
        icon: React.createElement(TimeIcon, { color: "primary" }),
        text: "На согласовании",
    },
    total: {
        text: "Всего",
    },
};
const FilterStatusPopover = ({ setAnchorEl, anchorEl, statuses }) => {
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    return (React.createElement(Box, { position: "relative" },
        React.createElement(IconButton, { onClick: handleClick },
            React.createElement(MoreVertIcon, null)),
        React.createElement(Popover, { open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
            } },
            React.createElement(MenuItem, { disabled: true },
                React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" },
                    React.createElement(Typography, { noWrap: true }, statusMapping["total"].text),
                    React.createElement(Typography, { ml: 2 }, statuses === null || statuses === void 0 ? void 0 : statuses.total))),
            ["inProgress", "rejected", "approved", "completed"].map((key) => (React.createElement(MenuItem, { key: key },
                React.createElement(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" },
                    React.createElement(Typography, { noWrap: true }, statusMapping[key].text),
                    React.createElement(Typography, { ml: 2 }, statuses === null || statuses === void 0 ? void 0 : statuses[key]))))))));
};
const createRowCellContent = (row, column, selected, onSelect, getCellValue) => {
    switch (column.id) {
        case "hasRisks": {
            return React.createElement(Typography, null, row[column.id] ? "Да" : "Нет");
        }
        case "status": {
            const status = row[column.id];
            const statusInfo = statusMapping[status];
            const menuItems = [
                {
                    label: React.createElement(FormattedMessage, { id: "requests-list-page.toolbar.popover.open" }),
                    onClick: () => alert("Открыть"),
                },
                {
                    label: React.createElement(FormattedMessage, { id: "requests-list-page.toolbar.popover.cancel" }),
                    onClick: () => alert("Отменить"),
                },
            ];
            return (React.createElement(Box, { display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" },
                React.createElement(Box, { display: "flex", alignItems: "center", minWidth: 0 },
                    statusInfo && statusInfo.icon,
                    React.createElement(Typography, { ml: 1, noWrap: true, overflow: "hidden", textOverflow: "ellipsis" }, statusInfo && statusInfo.text)),
                React.createElement(ThreeDotsButton, { menuItems: menuItems })));
        }
        case "createdBy":
        case "updatedBy": {
            const text = row[column.id];
            const truncatedText = text.length > 20 ? `${text.slice(0, 20)}...` : text;
            return (React.createElement(Tooltip, { title: text },
                React.createElement(Typography, { className: "table-row-cell-text", noWrap: true }, truncatedText)));
        }
        case "url":
            return (React.createElement(IconButton, null,
                React.createElement(LanguageIcon, { onClick: () => {
                        alert("Icon Click");
                    } })));
        default:
            return createRowCellContentInner(row, column, selected, onSelect, getCellValue);
    }
};
const getCellValue = (row, column) => {
    switch (column.id) {
        case "creationDate":
            return row.createdAt;
        default:
            return row[column.id];
    }
};
const RequestsList = () => {
    const intl = useIntl();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const createFilterStatusPopover = (anchor, columnId) => {
        if (columnId !== "status")
            return false;
        setAnchorEl(anchor);
        return true;
    };
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchRequestStatuses());
    }, []);
    const statuses = useSelector((state) => state.administration.requests.requestStatuses);
    const { rows, columns, total, busy, selectedIds, searchString, setSearchString, pageNumber, setPageNumber, limitNumber, setLimitNumber, filterData, setFilterData, sortData, setSortData, error, setError, } = useReduxTable((state) => state.administration.requests.requests, fetchRequests, (state) => state.administration.requests.columns, fetchRequestsColumns, (state) => state.administration.requests.selectedIds, setSelectedIdsAction, (state) => state.administration.requests.searchString, setSearchStringAction, (state) => state.administration.requests.pageNumber, setPageNumberAction, (state) => state.administration.requests.limitNumber, setLimitNumberAction, (state) => state.administration.requests.sortData, setSortDataAction, (state) => state.administration.requests.filterData, setFilterDataAction, (state) => state.administration.requests.error, setErrorAction, (state) => state.administration.requests.total, (state) => state.administration.requests.busy);
    const [requestsType, setRequestsType] = useReduxValue((state) => state.administration.requests.requestsType, setRequestsTypeAction);
    const [createRequestDialogOpen, setCreateRequestDialogOpen] = useState(false);
    const busyType = useSelector((state) => state.administration.requests.busyType);
    const getTableRowId = (row) => row.requestId;
    const getFilters = useFilters("");
    const toolbarActions = useMemo(() => [
        {
            id: "title",
            title: intl.formatMessage({
                id: "requests-list-page.toolbar.text.title",
            }),
            type: "text",
            position: "begin",
        },
        {
            id: "select",
            type: "select",
            position: "end",
            options: [
                { value: REQUESTS_TYPE.ALL, title: "Все ЗиР" },
                { value: REQUESTS_TYPE.USERS, title: "Мой ЗиР" },
            ],
            value: requestsType,
            callback: setRequestsType,
        },
        {
            id: "add",
            title: intl.formatMessage({
                id: "requests-list-page.toolbar.btn-create-request",
            }),
            position: "end",
            callback: () => {
                setCreateRequestDialogOpen(true);
            },
        },
    ], [requestsType, setRequestsType]);
    const handleRowClick = (row) => {
        navigate(`request/${row.requestId}`);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(CommonTable, { rows: rows, columns: columns, toolbarActions: toolbarActions, selectedIds: selectedIds, getRowId: getTableRowId, pageNumber: pageNumber, setPageNumber: setPageNumber, limitNumber: limitNumber, setLimitNumber: setLimitNumber, total: total, onRowClick: handleRowClick, filterData: filterData, setFilterData: setFilterData, sortData: sortData, setSortData: setSortData, searchString: searchString, setSearchString: setSearchString, getCellValue: getCellValue, getFilters: getFilters, createRowCellContent: createRowCellContent, useKeyboardNavigation: false, toolbar: [], filtersCallback: createFilterStatusPopover }),
        React.createElement(ProgressDialog, { title: "", open: busy, busyType: busyType, progress: "" }),
        React.createElement(ErrorsDialog, { error: error, 
            //@ts-expect-error add type to error
            open: (error === null || error === void 0 ? void 0 : error.type) === "error", onClose: () => setError(null) }),
        anchorEl && React.createElement(FilterStatusPopover, { setAnchorEl: setAnchorEl, anchorEl: anchorEl, statuses: statuses }),
        React.createElement(CreateRequestDialog, { open: createRequestDialogOpen, onClose: () => {
                setCreateRequestDialogOpen(false);
                dispatch(setValidationErrors(null));
            } })));
};
export default RequestsList;
