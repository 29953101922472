import React from "react";
import { Box } from "@mui/material";
import { DropdownSearchHelpInput, TextInput } from "../../../common/form-controls";
import { reportsService } from "../../../../services/reports-service";
import { useIntl } from "react-intl";
const getEmployees = (params, signal) => {
    return reportsService.getEmployeesForSimulationSearchHelp(params, signal);
};
const UsersInput = ({ ids, setIds, error, getUsers, disabledMultiple = false }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "preparation-for-testing-request-form.input-manager" });
    return (React.createElement(DropdownSearchHelpInput, { ids: ids, setIds: setIds, getData: getUsers, error: Boolean(error), errorMessage: error === null || error === void 0 ? void 0 : error.message, label: label, disableMultiple: disabledMultiple }));
};
const TestingUserCountInput = ({ value, setValue, error }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "preparation-for-testing-request-form.input-test-users-count" });
    const handleChange = (event) => {
        const newValue = event.target.value.replace(/\D/g, "");
        setValue(newValue);
    };
    return React.createElement(TextInput, { label: label, value: value, onChange: handleChange, error: (error === null || error === void 0 ? void 0 : error.value) === value, errorMessage: error === null || error === void 0 ? void 0 : error.message });
};
const getRoles = (params, signal) => {
    return reportsService.getRolesSearchHelp(params, signal);
};
const RolesInput = ({ ids, setIds }) => {
    const intl = useIntl();
    const label = intl.formatMessage({ id: "preparation-for-testing-request-form.input-additional-roles" });
    return React.createElement(DropdownSearchHelpInput, { ids: ids, setIds: setIds, getData: getRoles, label: label });
};
const PreparationForTestingRequestForm = ({ form, setForm, validationErrors }) => {
    return (React.createElement(Box, { className: "preparation-for-testing-request-form" },
        React.createElement(UsersInput, { ids: form.testingResponsible, setIds: (value) => setForm({ testingResponsible: value }), error: validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.users, disabledMultiple: false, getUsers: getEmployees }),
        React.createElement(TestingUserCountInput, { value: form.testingUserCount, setValue: (value) => setForm({ testingUserCount: value }), error: validationErrors === null || validationErrors === void 0 ? void 0 : validationErrors.testingUserCount }),
        React.createElement(RolesInput, { ids: form.additionalRoles, setIds: (value) => setForm({ additionalRoles: value }) })));
};
export default PreparationForTestingRequestForm;
