import { combineReducers } from "redux";
import appReducer from "./appSlice.js";
import adminReducer from "./admin";
import authReducer from "./authSlice.js";
import functionReducer from "./functionSlice.js"
import riskReducer from "./riskSlice.js"
import matrixHeadersReducer from "./matrixHeadersSlice.js";
import roleSlice from "./roleSlice.js";
import profileSlice from "./profileSlice.js";
import reportReducer from "./reports";
import reportModelingReducer from "./reports-modeling";
import administrationReducer from "./administration"

const reducer = combineReducers({
    app: appReducer,
    admin: adminReducer,
    auth: authReducer,
    functions: functionReducer,
    risks: riskReducer,
    matrixHeaders: matrixHeadersReducer,
    roles: roleSlice,
    profiles: profileSlice,
    reports: reportReducer,
    reportsModeling: reportModelingReducer,
    administration: administrationReducer
});

export default reducer;
