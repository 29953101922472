import React, { useCallback } from "react";
import { Box } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";

import { systemsService } from "../../../../../services/systems-service";

import { PageContentBox } from "../../../../common/page-content-box";
import { TextInput } from "../../../../common/form-controls";
import { READ_MODE, ADD_MODE, EntityFormSubtitle } from "../../../../common/entity-form";
import GroupFormSystemsTable from "./group-form-systems-table";
import { nanoid } from "@reduxjs/toolkit";
import { CHANGE_STATUS } from "../../../../common/const";
import { getUpdatedFormForDeleteEntity, getUpdatedFormForMassDeleteEntity, getVisibleEntities } from "../../../../../utils/formViews";
import useSystemsTable from "./useSystemsTable";

const GroupIdInput = ({ id, setId, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            label={intl.formatMessage({ id: "group-form-page-group.input-group-id" })}
            value={id}
            onChange={(event) => setId(event.target.value)}
            disabled={disabled}
            error={error?.value === id}
            errorMessage={error?.message}
        />
    );
};

const GroupDescriptionInput = ({ description, setDescription, disabled, error }) => {
    const intl = useIntl();

    return (
        <TextInput
            multiline
            rows={3}
            label={intl.formatMessage({ id: "group-form-page-group.input-description" })}
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            disabled={disabled}
            error={error?.value === description}
            errorMessage={error?.message}
        />
    );
};

const GroupFormPageGroup = ({ form, setForm, mode, validationErrors, errorScroll, setErrorScroll }) => {
    const entitySystemKeyFields = ["systemId"];
    const checkItemsForDuplicates = (items, entityKeyFields) => {
        const keysMap = {};

        const getItemKey = (i) => entityKeyFields.map((field) => i[field]).join("-");

        items.forEach((item) => {
            if (item.changeStatus === CHANGE_STATUS.DELETE) return;

            const key = getItemKey(item);

            if (!(key in keysMap)) {
                keysMap[key] = 0;
            }

            keysMap[key] += 1;
        });

        items.forEach((item) => {
            if (item.changeStatus === CHANGE_STATUS.DELETE) return;

            const key = getItemKey(item);

            if (keysMap[key] === 1) {
                entityKeyFields.forEach((field) => {
                    const fieldHasError = item[`error_${field}`] && item[`error_${field}`]["code"] === "duplicate";

                    if (fieldHasError) {
                        delete item[`error_${field}`];
                    }
                });
            }
        });
    };

    const setId = (value) => setForm({ ...form, id: value });
    const setDescription = (value) => setForm({ ...form, description: value });

    const formDisabled = mode === READ_MODE;
    const idDisabled = mode !== ADD_MODE;

    const visibleSystems = getVisibleEntities(form.systems);

    const systemsTable = useSystemsTable(visibleSystems);
    const addSystem = async () => {
        const system = {
            id: nanoid(),
            systemId: "",
            description: "",
            landscape: "",
            isDefault: false,
            changeStatus: CHANGE_STATUS.ADD,
        };

        setForm((form) => {
            const newForm = {
                ...form,
                systems: [...form.systems, system],
            };

            return newForm;
        });
    };

    const deleteSystem = (id) => {
        setForm((form) => {
            const { newForm } = getUpdatedFormForDeleteEntity(form, id, "systems");
            checkItemsForDuplicates(newForm.systems, entitySystemKeyFields);
            return newForm;
        });
    };

    const deleteSystemMass = useCallback(
        (ids) => {
            setForm((form) => {
                const newForm = getUpdatedFormForMassDeleteEntity(form, ids, "systems");
                return newForm;
            });
        },
        [setForm]
    );

    const getSystem = async (systemId) => {
        if (!systemId) {
            return;
        }

        try {
            const system = await systemsService.getSystemDetailed(systemId);

            return system;
        } catch (e) {
            return;
        }
    };

    const editSystem = async (system, field, value) => {
        let description = system.description;
        let newValue = value;
        let systemDetailed;

        const fieldIsKey = entitySystemKeyFields.includes(field);
        const isChangingDefault = field === "isDefault" && value === true;
        const isChangingLandscape = field === "landscape";

        if (fieldIsKey) {
            systemDetailed = await getSystem(value);
            description = systemDetailed?.description ?? "";
        }

        setForm((form) => {
            let updatedSystems = form.systems.map((s) => {
                if (s.id === system.id) {
                    return { ...s, [field]: newValue, description };
                }
                return s;
            });

            if (isChangingDefault) {
                updatedSystems = updatedSystems.map((s) => {
                    if (s.id !== system.id && s.landscape === system.landscape) {
                        return { ...s, isDefault: false };
                    }
                    return s;
                });
            }

            if (isChangingLandscape) {
                updatedSystems = updatedSystems.map((s) => {
                    if (s.id !== system.id && s.isDefault && s.landscape === value) {
                        return { ...s, isDefault: false };
                    }
                    return s;
                });
            }

            checkItemsForDuplicates(updatedSystems, entitySystemKeyFields);

            return { ...form, systems: updatedSystems };
        });
    };

    return (
        <PageContentBox className="group-form-page-group">
            <EntityFormSubtitle>
                <FormattedMessage id="group-form-page-group.title" />
            </EntityFormSubtitle>

            <Box className="group-form-page-group-inputs">
                <GroupIdInput id={form.id} setId={setId} disabled={idDisabled} error={validationErrors && validationErrors["group_id"]} />
                <GroupDescriptionInput
                    description={form.description}
                    setDescription={setDescription}
                    disabled={formDisabled}
                    error={validationErrors && validationErrors["description"]}
                />
            </Box>
            <GroupFormSystemsTable
                systems={systemsTable.rows}
                addSystem={addSystem}
                deleteSystem={deleteSystem}
                deleteSystemMass={deleteSystemMass}
                editSystem={editSystem}
                disabled={false}
                filterData={systemsTable.filterData}
                setFilterData={systemsTable.setFilterData}
                searchString={systemsTable.searchString}
                setSearchString={systemsTable.setSearchString}
                getDialogFilters={systemsTable.getDialogFilters}
                validationErrors={validationErrors}
                errorIndex={errorScroll?.errorIndex}
                setErrorScroll={setErrorScroll}
            />
        </PageContentBox>
    );
};

GroupIdInput.propTypes = {
    id: PropTypes.string,
    setId: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object,
};

GroupDescriptionInput.propTypes = {
    description: PropTypes.string,
    setDescription: PropTypes.func,
    disabled: PropTypes.bool,
    error: PropTypes.object,
};

GroupFormPageGroup.propTypes = {
    form: PropTypes.object,
    setForm: PropTypes.func,
    mode: PropTypes.string,
    validationErrors: PropTypes.object,
    errorScroll: PropTypes.object,
    setErrorScroll: PropTypes.func,
};

export default GroupFormPageGroup;
