import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { reportsService } from "../../services/reports-service.js";
import { REPORT_LEVELS, REPORT_TYPES, REPORT_VARIANT_TYPES, REPORT_LEVEL_MODE } from "../../utils/sodReports.js";
import { BUSY_TYPES } from "../../utils/busy.js";

const initialState = {
    users: [],
    integrIds: [],
    systems: [],
    extendedSystems: [],
    matrixHeaders: [],
    groups: [],
    risks: [],
    riskLevels: [],
    onlineSync: false,
    reportLevelMode: REPORT_LEVEL_MODE.ACTION_PERMISSION,
    parametersOpen: true,

    canFetchResults: false,
    resultsOpen: false,
    reportLevel: REPORT_LEVELS.OPERATIONS,
    reportType: REPORT_TYPES.FINAL,
    isReportCalculated: false,
    rows: [],
    columns: [],
    total: 0,
    selectedIds: [],
    searchString: "",
    pageNumber: 1,
    limitNumber: 10,
    sortData: [],
    filterData: [],
    busy: false,
    busyType: null,
    error: null,
    validationErrors: null,

    variantCreated: false,
    variants: [],
    currentVariant: null,
    standardParameters: {
        users: [],
        integrIds: [],
        systems: [],
        matrixHeaders: [],
        risks: [],
        riskLevels: [],
        extendedSystems: [],
        reportLevelMode: REPORT_LEVEL_MODE.ACTION_PERMISSION,
    }
};

export const fetchReportColumns = createAsyncThunk(
    "reports/userLevel/fetchReportColumns",
    async (_, { getState }) => {
        const state = getState();
        const columns = await reportsService.getUserLevelReportColumns(
            state.reports.userLevel.reportLevel,
            state.reports.userLevel.reportType
        );
        return columns;
    }
);

export const fetchReportResults = createAsyncThunk(
    "reports/userLevel/fetchReportResults",
    async ({ params }, { getState }) => {
        const state = getState();
        if (!state.reports.userLevel.canFetchResults) {
            return;
        }

        const newParams = {
            ...params,
            "report_level": state.reports.userLevel.reportLevel,
            "report_type": state.reports.userLevel.reportType,
        };

        const response = await reportsService.getUserLevelReportResults(newParams);
        return response;
    }
);

export const calcReport = createAsyncThunk(
    "reports/userLevel//calcReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const systemsForCalc = state.reports.userLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.key)
        const groupForCalc = state.reports.userLevel.extendedSystems.filter(item => item.isGroup).map(item => item.key)
        const reportLevelMode = state.reports.userLevel.reportLevelMode;
        const newParams = {
            "report_level_mode": reportLevelMode
        }

        const form = {
            usernames: state.reports.userLevel.users,
            integration_id: state.reports.userLevel.integrIds,
            systems: systemsForCalc,
            matrix_headers: state.reports.userLevel.matrixHeaders.map(item => item.key),
            risks: state.reports.userLevel.risks.map(item => item.key),
            risk_levels: state.reports.userLevel.riskLevels.map(item => item.key),
            groups: groupForCalc,
            online_sync: state.reports.userLevel.onlineSync

        };

        try {
            await reportsService.calUserLevelReport(form, newParams);

        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const compareReport = createAsyncThunk(
    "reports/userLevel//compareReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const systemsForCalc = state.reports.userLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.key)
        const groupForCalc = state.reports.userLevel.extendedSystems.filter(item => item.isGroup).map(item => item.key)

        const form = {
            usernames: state.reports.userLevel.users,
            systems: systemsForCalc,
            matrix_headers: state.reports.userLevel.matrixHeaders.map(item => item.key),
            groups: groupForCalc,
            risks: state.reports.userLevel.risks.map(item => item.key),
            risk_levels: state.reports.userLevel.riskLevels.map(item => item.key)
        };

        try {
            await reportsService.compareUserLevelReport(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const saveVariant = createAsyncThunk(
    "reports/userLevel/saveVariant",
    async (variantName, { getState, rejectWithValue }) => {
        const state = getState();

        const isVariantNew = !state.reports.userLevel.variants.some(
            variant => variant.variantName === variantName
        );

        const newParams = {
            "variant_name": variantName,
            "variant_type": REPORT_VARIANT_TYPES.USERS,
        };

        const systemsForCalc = state.reports.userLevel.extendedSystems.filter(item => !item.isGroup).map(item => item.key)
        const groupForCalc = state.reports.userLevel.extendedSystems.filter(item => item.isGroup).map(item => item.key)

        const variantData = {
            "usernames": state.reports.userLevel.users,
            "integration_id": state.reports.userLevel.integrIds,
            "systems": systemsForCalc,
            "matrix_headers": state.reports.userLevel.matrixHeaders.map(item => item.key),
            "risks": state.reports.userLevel.risks.map(item => item.key),
            "risk_levels": state.reports.userLevel.riskLevels.map(item => item.key),
            "groups": groupForCalc,
            "report_level_mode": state.reports.userLevel.reportLevelMode
        }

        const form = {
            variant_name: variantName,
            variant_data: variantData,
            variant_type: REPORT_VARIANT_TYPES.USERS
        }

        try {
            return await reportsService.saveVariant(form, newParams, isVariantNew);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const deleteVariant = createAsyncThunk(
    "reports/userLevel/deleteVariant",
    async (variantName, { rejectWithValue }) => {
        const newParams = {
            "variant_name": variantName,
            "variant_type": REPORT_VARIANT_TYPES.USERS,
        };

        try {
            return await reportsService.deleteVariant(newParams);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const setFavoritesForVariant = createAsyncThunk(
    "reports/userLevel/setFavoritesForVariant",
    async ({ variantName, inFavorites }, { rejectWithValue }) => {
        const form = {
            variant_name: variantName,
            variant_type: REPORT_VARIANT_TYPES.USERS,
            in_favorites: inFavorites
        };

        try {
            return await reportsService.setFavoritesForVariant(form);
        } catch (error) {
            return rejectWithValue(error)
        }
    }
);

export const fetchVariantsByUser = createAsyncThunk(
    "reports/userLevel/fetchVariantsByUser",
    async (searchString) => {
        const params = {
            variant_type: REPORT_VARIANT_TYPES.USERS,
        };

        if (searchString) {
            params.search = searchString;
        }

        const response = await reportsService.getVariantsByUser(params);

        return response;
    }
);

export const exportReport = createAsyncThunk(
    "reports/userLevel/exportReport",
    async (_, { getState, rejectWithValue }) => {
        const state = getState();

        const params = {
            "report_level": state.reports.userLevel.reportLevel,
            "report_type": state.reports.userLevel.reportType,
        };

        try {
            await reportsService.exportUserLevelReport(params);
        } catch (error) {
            rejectWithValue(error);
        }
    }
);

export const setReportLevelWithColumns = (reportLevel) => (dispatch) => {
    dispatch(setReportLevel(reportLevel));
    dispatch(fetchReportColumns());
};

export const setReportTypeWithColumns = (reportType) => (dispatch) => {
    dispatch(setReportType(reportType));
    dispatch(fetchReportColumns());
};

export const saveReportColumns = createAsyncThunk(
    "reports/userLevel/saveReportColumns",
    async ({ columns, reportLevel, reportType }) => {
        await reportsService.saveUserLevelReportColumns(columns, reportLevel, reportType);
        return { columns, reportLevel, reportType };
    }
);

const userLevelSlice = createSlice({
    name: "userLevel",
    initialState: initialState,
    reducers: {
        setUsers(state, action) {
            state.users = action.payload;
        },

        setIntegrIds(state, action) {
            state.integrIds = action.payload;
        },

        setSystems(state, action) {
            state.systems = action.payload;
        },

        setSystemsExtended(state, action) {
            state.extendedSystems = action.payload
        },

        setMatrixHeaders(state, action) {
            state.matrixHeaders = action.payload;
        },

        setRisks(state, action) {
            state.risks = action.payload;
        },

        setRiskLevels(state, action) {
            state.riskLevels = action.payload;
        },

        setParametersOpen(state, action) {
            state.parametersOpen = action.payload
        },

        setOnlineSync(state, action) {
            state.onlineSync = action.payload;
        },
        setReportLevelMode(state, action) {
            state.reportLevelMode = action.payload;
        },

        setCanFetchResults(state, action) {
            state.canFetchResults = action.payload
        },

        setEmptyResultTable(state) {
            state.total = 0;
            state.rows = [];

        },

        setResultsOpen(state, action) {
            state.resultsOpen = action.payload;
        },

        setReportLevel(state, action) {
            state.reportLevel = action.payload;
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
        },

        setReportType(state, action) {
            state.reportType = action.payload;
            state.pageNumber = 1;
            state.searchString = "";
            state.filterData = [];
            state.sortData = [];
        },

        setError(state, action) {
            state.error = action.payload;
        },

        setSelectedIds(state, action) {
            state.selectedIds = action.payload;
        },

        setSearchString(state, action) {
            if (state.searchString !== action.payload) {
                state.pageNumber = 1;
                state.searchString = action.payload;
            }
        },

        setPageNumber(state, action) {
            state.pageNumber = action.payload;
        },

        setLimitNumber(state, action) {
            state.pageNumber = 1;
            state.limitNumber = action.payload;
        },

        setSortData(state, action) {
            state.pageNumber = 1;
            state.sortData = action.payload;
        },

        setFilterData(state, action) {
            state.pageNumber = 1;
            state.filterData = action.payload;
        },


        generateObjects(state, action) {
            const { template, maxCount } = action.payload

            const MAX_SAP_USERNAME_COUNT = 12

            const numCount = MAX_SAP_USERNAME_COUNT - template.length

            state.users = []

            for (let i = 0; i < maxCount; i++) {
                const itemIndex = i.toString()

                const userLogin = `${template}${itemIndex.padStart(numCount, "0")}`

                state.users.push(userLogin)
            }
        },

        setCurrentVariant(state, action) {
            if (!state.currentVariant) {
                state.standardParameters.users = state.users;
                state.standardParameters.integrIds = state.integrIds;
                state.standardParameters.systems = state.systems;
                state.standardParameters.matrixHeaders = state.matrixHeaders;
                state.standardParameters.risks = state.risks;
                state.standardParameters.riskLevels = state.riskLevels;
                state.standardParameters.extendedSystems = state.extendedSystems;
                state.standardParameters.reportLevelMode = state.reportLevelMode;
            }

            const variant = action.payload;

            const systemsCurrent = variant ? variant.variantData.systems : state.standardParameters.extendedSystems.filter(item => !item.isGroup)
            const groupsCurrent = variant ? variant.variantData.groups : state.standardParameters.extendedSystems.filter(item => item.isGroup)

            const systemPart = systemsCurrent.map(systemInfo => ({
                key: systemInfo.key,
                text: systemInfo.text,
                isGroup: false
            }))

            const groupPart = groupsCurrent.map(groupInfo => ({
                key: groupInfo.key,
                text: groupInfo.text,
                isGroup: true
            }))

            state.currentVariant = variant;

            state.users = variant
                ? variant.variantData.usernames
                : state.standardParameters.users;

            state.integrIds = variant
                ? variant.variantData.integration_id
                : state.standardParameters.integrIds;

            state.systems = variant
                ? variant.variantData.systems
                : state.standardParameters.systems;

            state.extendedSystems = [...systemPart, ...groupPart]

            state.matrixHeaders = variant
                ? variant.variantData.matrixHeaders
                : state.standardParameters.matrixHeaders;

            state.risks = variant
                ? variant.variantData.risks
                : state.standardParameters.risks;

            state.riskLevels = variant
                ? variant.variantData.riskLevels
                : state.standardParameters.riskLevels;

            state.reportLevelMode = variant
                ? variant.variantData.reportLevelMode
                : state.standardParameters.reportLevelMode;

            variant?.variantData.reportLevelMode == REPORT_LEVEL_MODE.CRITICAL_OBJECTS
                ? state.reportLevel = REPORT_LEVELS.CRITICAL_OBJECTS
                : state.reportLevel = REPORT_LEVELS.OPERATIONS
        },

        clearFilters(state) {
            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
        }
    },
    extraReducers: {
        [fetchReportResults.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.rows = [];
        },

        [fetchReportResults.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [fetchReportResults.fulfilled]: (state, action) => {
            state.busy = false;
            state.error = null;
            state.validationErrors = null;

            if (action.payload) {
                state.resultsOpen = true;
                state.rows = action.payload.rows;
                state.total = action.payload.total;
            }
        },

        [compareReport.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
        },

        [compareReport.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [compareReport.fulfilled]: (state) => {
            state.busy = false;
            state.error = null

            state.selectedIds = [];
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
        },

        [calcReport.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
            state.error = null;
            state.validationErrors = null;
            state.isReportCalculated = false;
        },

        [calcReport.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },

        [calcReport.fulfilled]: (state) => {
            state.selectedIds = [];
            state.isReportCalculated = true;
            state.searchString = "";
            state.pageNumber = 1;
            state.sortData = [];
            state.filterData = [];
            state.canFetchResults = true;
        },

        [fetchReportColumns.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.LOAD;
        },

        [fetchReportColumns.fulfilled]: (state, action) => {
            state.busy = false;
            state.columns = action.payload?.filter(column => column.active);
        },

        [fetchReportColumns.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.error;
        },


        [saveVariant.pending]: (state) => {
            state.variantCreated = false;
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [saveVariant.fulfilled]: (state, action) => {
            state.busy = false;
            state.validationErrors = [];
            state.variantCreated = true;

            const savedVariant = action.payload;
            const index = state.variants.findIndex(variant => variant.variantName === savedVariant.variantName);

            if (index === -1) {
                state.variants.push(savedVariant);
            } else {
                state.variants[index] = savedVariant;
            }

            if (state.currentVariant?.variantName === savedVariant.variantName) {
                state.currentVariant = savedVariant;
            }
        },

        [saveVariant.rejected]: (state, action) => {
            const error = action.payload;

            state.busy = false;
            state.error = error;

            if (error && error.code === "validation") {
                state.validationErrors = error.errors;
            }
        },


        [deleteVariant.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [deleteVariant.fulfilled]: (state, action) => {
            const variantName = action.meta.arg;

            state.busy = false;
            state.variants = state.variants.filter(variant => variant.variantName !== variantName);

            if (state.currentVariant?.variantName === variantName) {
                state.currentVariant = null;
                state.users = [];
                state.integrIds = [];
                state.systems = [];
                state.extendedSystems = []
                state.matrixHeaders = [];
                state.risks = [];
                state.riskLevels = [];
            }
        },

        [deleteVariant.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [setFavoritesForVariant.pending]: (state) => {
            state.busy = true;
            state.busyType = BUSY_TYPES.SAVE;
        },

        [setFavoritesForVariant.fulfilled]: (state, action) => {
            const { variantName, inFavorites } = action.meta.arg;

            state.busy = false;

            const variant = state.variants.find(variant => variant.variantName === variantName);

            if (variant) {
                variant.inFavorites = inFavorites;
            }

            if (state.currentVariant?.variantName === variantName) {
                state.currentVariant.inFavorites = inFavorites;
            }
        },

        [setFavoritesForVariant.rejected]: (state, action) => {
            state.busy = false;
            state.error = action.payload;
        },

        [fetchVariantsByUser.fulfilled]: (state, action) => {
            state.variants = action.payload;
        },

        [fetchVariantsByUser.rejected]: (state, action) => {
            state.error = action.error;
        },

        [exportReport.rejected]: (state, action) => {
            state.error = action.error;
        },

        [saveReportColumns.fulfilled]: (state, action) => {
            const { columns, reportLevel, reportType } = action.payload;

            if (state.reportLevel === reportLevel &&
                state.reportType === reportType) {
                state.columns = columns.filter(column => column.active);
            }
        }
    }
});

export const {
    setUsers, setSystems, setMatrixHeaders, setRisks, setSystemsExtended,
    setRiskLevels, setParametersOpen, setResultsOpen,
    setReportLevel, setReportType, setError, setSelectedIds,
    setSearchString, setPageNumber, setLimitNumber, setSortData,
    setFilterData, setCurrentVariant, clearFilters, setOnlineSync, setReportLevelMode, generateObjects, setCanFetchResults, 
    setEmptyResultTable, setIntegrIds
} = userLevelSlice.actions;

export default userLevelSlice.reducer;
