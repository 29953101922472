import React, { useCallback, useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography, Box } from "@mui/material";
import PropTypes from 'prop-types';

import { CloseIcon } from "../icons";
import { Button, ResetFiltersButton } from "../buttons";
import SearchHelpTable from "./search-help-table.jsx"
import { SearchInput, Tag } from "../form-controls";

import "./styles.less"
import useScrollPagination from "../hooks/useScrollPagination";

const defaultColumns = [
    {
        id: "key",
        title: "key",
        width: "200px"
    },
    {
        id: "text",
        title: "text",
        width: "400px"
    }
];

const SearchHelpDialogContent = ({
    onSelect,
    onRowClick,
    columns,
    onReset,
    showTableHeader,
    selectOneRow,
    searchString,
    setSearchString,
    items,
    busy,
    handleScroll,
    disabled
}) => {
    const tableRef = useRef();


    const handleSearchClick = useCallback((event) => {
        setSearchString(event.target.value);
    }, [setSearchString]);

    return (
        <DialogContent className="search-help-dialog-content">
            <Box className="search-help-dialog-content-head">
                <SearchInput
                    fullWidth={!onReset}
                    value={searchString}
                    onSearchClick={handleSearchClick}
                />

                {onReset && <ResetFiltersButton onClick={onReset} />}
            </Box>

            <SearchHelpTable
                rows={items}
                columns={columns}
                onSelect={onSelect}
                onRowClick={onRowClick}
                onScroll={handleScroll}
                listContainerRef={tableRef}
                showTableHeader={showTableHeader}
                busy={busy}
                selectOneRow={selectOneRow}
                disabled={disabled}
            />
        </DialogContent>
    )
}

const TagView = ({ tags, onDeleteTag, getTagText }) => {
    return (
        <Box className="search-help-dialog-actions-tag-view">
            {tags?.map(({ key, text }) => (
                <Tag
                    key={key}
                    tag={getTagText(key, text)}
                    onDelete={() => onDeleteTag(key)}
                    size="small"
                />
            ))}
        </Box>
    );
};

const SearchHelpDialogActions = ({ selectedItems, onDeleteTag, onCancel, onApply, getTagText, disabled }) => {
    const intl = useIntl();

    const tagsTitle = intl.formatMessage({ id: "search-help.tag-view.title" });
    const cancelTitle = intl.formatMessage({ id: "common.btn-cancel" });
    const applyTitle = intl.formatMessage({ id: "common.btn-apply" });

    return (
        <DialogActions className="search-help-dialog-actions">
            {selectedItems?.length > 0 && (
                <Box className="search-help-dialog-actions-tags-container">
                    <Typography className="search-help-tag-view-title">
                        {tagsTitle}
                    </Typography>

                    <TagView
                        containerSx={{ marginRight: "auto", gap: "10px" }}
                        tags={selectedItems}
                        onDeleteTag={onDeleteTag}
                        getTagText={getTagText}
                    />
                </Box>
            )}

            <Box className="search-help-dialog-actions-buttons">
                <Button
                    variant="outlined"
                    onClick={onCancel}
                    sx={{
                        color: 'var(--font-4)',
                        borderColor: 'var(--font-4)',
                    }}
                >
                    {cancelTitle}
                </Button>

                <Button
                    variant="contained"
                    onClick={onApply}
                    disabled={disabled}
                    sx={{
                        color: 'var(--bg-2)',
                        backgroundColor: 'var(--font-2)',
                        '&:hover': {
                            backgroundColor: 'var(--font-2)',
                        }
                    }}
                >
                    {applyTitle}
                </Button>
            </Box>
        </DialogActions>
    );
};

const SearchHelpDialogTitle = ({ title, onCancel }) => {
    const intl = useIntl();
    const dialogTitle = title || intl.formatMessage({ id: "search-help.choose-resource.title" });

    return (
        <DialogTitle className="search-help-dialog-title">
            {dialogTitle}

            <IconButton
                className="search-help-dialog-cancel"
                onClick={onCancel}
            >
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
};

const SearchHelpDialog = ({
    title,
    onSubmit,
    selectOneRow = false,
    selectOnRowClick = false,
    onCancel,
    open,
    readRows,
    currentIds,
    columns = defaultColumns,
    showTableHeader = true,
    showResetButton = false,
    defaultSearchString = "",
    className = "",
    getTagText = (key) => key,
    disabled=false,
    ...props
}) => {
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        if (currentIds) {
            setSelectedItems(currentIds.map(item => ({ key: item })))
        }
    }, [currentIds])

    const handleSelect = (row) => {
        const existedId = selectedItems.find(item => item.key === row.key)

        if (!existedId) {
            setSelectedItems([...selectedItems, row])
        } else {
            setSelectedItems([...selectedItems.filter(item => item.key !== row.key)])
        }
    };

    const handleRowClick = (row) => {
        if (disabled) {
            return
        }

        if (selectOnRowClick) {
            handleSelect(row);
        } else {
            onSubmit(selectOneRow ? row : [row.key]);
        }
    };

    const handleDeleteTag = (id) => {
        setSelectedItems([...selectedItems.filter(item => item.key !== id)])
    };

    const handleResetClick = () => {
        setSelectedItems([]);
    };

    const [searchString, setSearchString] = useState("");

    useEffect(() => {
        setSearchString(defaultSearchString);
    }, [defaultSearchString]);

    const readRowsInner = useCallback((page, signal) => {
        return readRows({
            page: page,
            limit: 100,
            filters: [],
            search: searchString
        }, signal);
    }, [readRows, searchString]);

    const {
        items,
        busy,
        handleScroll
    } = useScrollPagination({
        getData: readRowsInner
    });

    return (
        <Dialog
            open={open}
            className={`search-help-dialog ${className}`}
            {...props}
        >
            <SearchHelpDialogTitle
                title={title}
                onCancel={onCancel}
            />

            <SearchHelpDialogContent
                onSelect={handleSelect}
                onRowClick={handleRowClick}
                columns={columns}
                showTableHeader={showTableHeader}
                onReset={showResetButton ? handleResetClick : null}
                selectOneRow={selectOneRow}
                searchString={searchString}
                setSearchString={setSearchString}
                items={items}
                busy={busy}
                handleScroll={handleScroll}
                disabled={disabled}
            />

            <SearchHelpDialogActions
                selectedItems={selectedItems}
                onDeleteTag={handleDeleteTag}
                onCancel={onCancel}
                // onApply={() => onSubmit(selectedIds)}
                onApply={() => onSubmit(selectedItems.map(item => item.key))}
                getTagText={getTagText}
                disabled={disabled}
            />
        </Dialog>
    )
};

SearchHelpDialogContent.propTypes = {
    onSelect: PropTypes.func,
    onRowClick: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.object),
    onReset: PropTypes.func,
    showTableHeader: PropTypes.bool,
    selectOneRow: PropTypes.bool,
    searchString: PropTypes.string,
    setSearchString: PropTypes.func,
    items: PropTypes.arrayOf(PropTypes.object),
    busy: PropTypes.bool,
    handleScroll: PropTypes.func,
    disabled: PropTypes.bool
};

TagView.propTypes = {
    tags: PropTypes.arrayOf(PropTypes.string),
    onDeleteTag: PropTypes.func,
    getTagText: PropTypes.func,
};

SearchHelpDialogActions.propTypes = {
    selectedItems: PropTypes.arrayOf(PropTypes.object),
    onDeleteTag: PropTypes.func,
    onCancel: PropTypes.func,
    onApply: PropTypes.func,
    getTagText: PropTypes.func,
    disabled: PropTypes.bool
};

SearchHelpDialogTitle.propTypes = {
    title: PropTypes.string,
    onCancel: PropTypes.func
};

SearchHelpDialog.propTypes = {
    title: PropTypes.string,
    onSubmit: PropTypes.func,
    selectOneRow: PropTypes.bool,
    selectOnRowClick: PropTypes.bool,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    readRows: PropTypes.func,
    currentIds: PropTypes.arrayOf(PropTypes.string),
    columns: PropTypes.arrayOf(PropTypes.object),
    showTableHeader: PropTypes.bool,
    showResetButton: PropTypes.bool,
    defaultSearchString: PropTypes.string,
    className: PropTypes.string,
    getTagText: PropTypes.func,
    disabled: PropTypes.bool
};

export default SearchHelpDialog;
