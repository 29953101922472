import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { nanoid } from "@reduxjs/toolkit";

import { getPermissionFieldValuesSearchHelp, getPermissionActvtSearchHelp } from "../../../utils/searchHelps";

import { permissionObjectPropTypes, fieldPropTypes } from "./prop-types";
import ReportModelingEditDialogTableSelect from "./report-modeling-edit-dialog-table-select.jsx";
import ReportModelingEditDialog from "./report-modeling-edit-dialog.jsx";

import "./styles.less";
import { ProgressDialog } from "../../common/dialogs";
import { BUSY_TYPES } from "../../../utils/busy";

const checkSelectedAllValues = (field) => Boolean(
    field?.values?.length &&
    field.values[0].from === "*"
);

const ReportModelingEditDialogSelect = ({
    open,
    systemId,
    permissionObject,
    field,
    onClose,
    onApply,
    disabled=false
}) => {
    const [busy, setBusy] = useState(false);
    const [selectedIds, setSelectedIds] = useState([]);
    const [rows, setRows] = useState([]);

    useEffect(() => {
        const fetchValues = async () => {
            setBusy(true);

            let values = []

            if (field.id === "ACTVT") {
                values = await getPermissionActvtSearchHelp(
                    {}, null, systemId, permissionObject.id
                )
            } else {
                const response = await getPermissionFieldValuesSearchHelp(
                    null, null, systemId, field.id
                );

                values = response.rows
            }
            setRows(values.rows);
            
            if (checkSelectedAllValues(field)) {
                setSelectedIds(values.rows.map(value => value.key));
            } else {
                const uniqueKeys = [...new Set(field.values.map(value => value.from))];
                setSelectedIds(uniqueKeys);
            }
            setBusy(false);
        };

        fetchValues();
    }, [field, systemId]);

    const handleApply = () => {
        if (disabled) {
            return
        }
        
        const applyRows = selectedIds.length === rows.length
            ? [{
                rowId: nanoid(),
                from: "*",
                to: ""
            }]
            : selectedIds.map(id => ({
                rowId: nanoid(),
                from: id,
                to: ""
            }));

        onApply(field, applyRows);
    };

    const handleSelectRow = (row, value) => {
        if (disabled) {
            return
        }

        if (value) {
            setSelectedIds([...selectedIds, row.key]);
            return;
        }

        setSelectedIds(selectedIds.filter(id => id !== row.key));
    };

    const handleSelectAll = (value) => {
        if (disabled) {
            return
        }

        if (!value) {
            setSelectedIds([]);
            return;
        }

        setSelectedIds(rows.map(row => row.key));
    };

    return (
        <>
            <ReportModelingEditDialog
                open={open}
                field={field}
                permissionObject={permissionObject}
                onClose={onClose}
                onApply={handleApply}
                disabled={disabled}
            >
                <ReportModelingEditDialogTableSelect
                    rows={rows}
                    selectedIds={selectedIds}
                    onSelectRow={handleSelectRow}
                    onSelectAll={handleSelectAll}
                />
            </ReportModelingEditDialog>

            <ProgressDialog
                open={busy}
                busyType={BUSY_TYPES.LOAD}
            />
        </>
    );
};

ReportModelingEditDialogSelect.propTypes = {
    open: PropTypes.bool,
    systemId: PropTypes.string,
    permissionObject: permissionObjectPropTypes,
    field: fieldPropTypes,
    onClose: PropTypes.func,
    onApply: PropTypes.func,
    disabled: PropTypes.bool
};

export default ReportModelingEditDialogSelect;